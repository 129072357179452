import moment from 'moment'
import PropTypes from 'prop-types'

const dateFormats = {
  sameDay: '[Aujourd\'hui]',
  nextDay: '[Demain]',
  nextWeek: 'dddd',
  lastDay: '[Hier]',
  lastWeek: 'dddd [dernier]',
  sameElse: 'dddd D MMM'
}

const NO_START_TEST = /^\d{1,2}:{1}32$/
const DEFAULT_FORMAT = ':start - :end'
const NO_START_FORMAT = 'Avant :end'

export const Timeslot = props => {
  const {
    start,
    end,
    fallback = '',
    withDate = false
  } = props

  if (!start && !end && !fallback) {
    return null
  }

  const timeslotStart = start && moment(start).isValid() ? moment(start) : null
  const timeslotEnd = end && moment(end).isValid() ? moment(end) : null

  if (!start || !end) {
    return fallback
  }

  const format = props.format || DEFAULT_FORMAT

  const startTime = timeslotStart.format('HH:mm')

  let timeslotString = NO_START_TEST.test(startTime)
    ? NO_START_FORMAT.replace(':end', timeslotEnd.format('H:mm'))
    : format.replace(':start', startTime).replace(':end', timeslotEnd.format('HH:mm'))

  timeslotString = withDate
    ? `${
            timeslotEnd.calendar(null, dateFormats)
        }, ${
            timeslotString.toLowerCase()
        }`
    : timeslotString

  return timeslotString
}
Timeslot.propTypes = {
  start: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  end: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  fallback: PropTypes.node,
  format: PropTypes.string
}
export default Timeslot
