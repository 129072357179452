import { connect, useSelector } from 'react-redux'
import React, { useCallback } from 'react'

import {
  getCheckoutAmounts,
  getCheckoutCompanySetting,
  getHasMonthlyBilling,
  getPayableAmountWithoutCompanyDiscount,
  getPayableAmount,
  getTotalAmount,
  getMe,
  getActiveCoupon
} from 'reducers'

import PanelContent from 'components/Common/Panel/PanelContent'

import { OrderTotal } from 'components/Order/OrderTotal'

import BasketEmpty from './BasketEmpty'
import BasketItemList from './BasketItemList'
import BasketCutlery from './BasketCutlery'
import Loyalty from './Loyalty'

import { pushFunnelEvent } from 'utils/googletagmanager'
import { block } from 'utils/classnames'

import './BasketContent.sass'
import SoHappyWarning from 'components/user/SoHappyWarning'

const b = block.with('basket-content')

const ConnectedOrderTotal = connect(
  (state, props) => ({
    ...getCheckoutAmounts(state, props),
    companySetting: getCheckoutCompanySetting(state, props),
    hasMonthlyBilling: getHasMonthlyBilling(state, props),
    totalDueWithoutCompanyDiscount: getPayableAmountWithoutCompanyDiscount(state, props, false),
    totalDue: getPayableAmount(state, props, false),
    total: getTotalAmount(state, props),
    activeCoupon: getActiveCoupon(state, props)
  })
)(OrderTotal)

const BasketContent = (props) => {
  const {
    cartProductsWithoutCutlery,
    cutlery,
    setCutleryQuantity,
    onDismissCutleryWarning,
    onNextPanel,
    onBack,
    basketQuantity = 0,
    totalDue = 0
  } = props

  const { isBadgeActive } = useSelector(getMe) || {}

  const handleNext = useCallback(() => {
    onNextPanel && onNextPanel()
    pushFunnelEvent('ConfirmedCart', {
      items: cartProductsWithoutCutlery,
      cutlery: cutlery?.quantity,
      quantity: basketQuantity,
      totalDue
    })
  }, [cartProductsWithoutCutlery, basketQuantity, totalDue, cutlery])

  if (basketQuantity === 0) {
    return (
      <PanelContent title='Mon panier' onBack={onBack}>
        <div className={b('container')}>
          <BasketEmpty />
        </div>
      </PanelContent>
    )
  }

  return (
    <PanelContent title='Mon panier' onBack={onBack}>
      <div className={b('container')}>
        <BasketCutlery
          dismissed={cutlery.dismissed}
          onDismiss={onDismissCutleryWarning}
          quantity={cutlery.quantity}
          onChange={setCutleryQuantity}
        />
        <div className={b('items')}>
          {
            cartProductsWithoutCutlery.map((cartProduct, i) => (
              <BasketItemList
                key={cartProduct?.offer?.id || 'no-offer-' + i}
                {...cartProduct}
              />
            ))
          }
          <Loyalty total={totalDue} />
        </div>
        <SoHappyWarning isCheckingOut isActive={isBadgeActive === 1} />
        <footer className={b('footer')}>
          <ConnectedOrderTotal />
          <button
            className={b('submit')}
            onClick={handleNext}
            disabled={!onNextPanel}
          >
            Valider le panier
          </button>
        </footer>
      </div>
    </PanelContent>
  )
}

export default BasketContent
