import React, { useMemo } from 'react'

import { Tooltip } from 'components/Common/Tooltip'
import { PrepTag } from './PrepTag'
import { SpicyTag } from './SpicyTag'

import { block } from 'utils/classnames'
import {
  TAGS_ICONS,
  getTagIdKey,
  parseTags
} from 'utils/publications'

import './Tags.sass'

export const Tags = props => {
  const {
    tags,
    spiciness = null,
    hasPrepTag = true,
    hasTooltip = true
  } = props

  const tagItems = useMemo(() => parseTags(tags), [tags])

  return (
    <ul className={block('tag-list')}>
      {
        tagItems.map(tag => {
          return (
            <li key={tag.id} className={block('tag-list', 'item')}>
              {
                hasTooltip
                  ? (
                    <Tooltip value={tag.name} Variant='span'>
                      <i className={TAGS_ICONS[getTagIdKey(tag.id)]} />
                    </Tooltip>
                  )
                  : (
                    <span>
                      <i className={TAGS_ICONS[getTagIdKey(tag.id)]} />
                      {tag.name}
                    </span>
                  )
              }
            </li>
          )
        })
      }
      {
        spiciness
          ? (
            <li key='spiciness-tag' className={block('tag-list', 'item')}>
              <SpicyTag spiciness={spiciness} />
            </li>
          )
          : null
      }
      {
        hasPrepTag ? (
          <li className={block('tag-list', 'lead')}><PrepTag tags={tags} /></li>
        ) : null
      }
    </ul>
  )
}

export default Tags
