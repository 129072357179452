import moment from 'moment'
import React, { useEffect, useMemo, useState } from 'react'

import { block } from 'utils/classnames'

import './ProductControls.sass'

const b = block.with('product-controls')

export const ProductControls = props => {
  const {
    publicationId,
    quantity,
    onChange,
    activeDay = moment().format('L'),
    timeframe = null,
    isLast = false,
    noAddButton = false,
    hasTextAddButton = false,
    disabled = false,
    onDisplayInvalidDeliveryModal = null,
    showModalInvalidDeliveryInfo = false
  } = props
  const [quantityInput, setQuantityInput] = useState(quantity)
  const [maxQuantity, setMaxQuantity] = useState(-1)
  const [fromControls, setFromControls] = useState(false)

  useEffect(() => {
    if (isLast) {
      if (maxQuantity === -1) setMaxQuantity(quantity)
      setQuantityInput(quantity)
    }
    if (fromControls) setFromControls(false)
    else setQuantityInput(quantity)
  }, [quantity])

  // updateQuantity is used to potentially modify the newQuantity if we have already know the max one
  // Also this function is doing the "onChange call" and defining the fromComtrols value
  const updateQuantity = (newQuantity) => {
    if (showModalInvalidDeliveryInfo) onDisplayInvalidDeliveryModal(showModalInvalidDeliveryInfo)
    if (newQuantity > maxQuantity && maxQuantity !== -1) newQuantity = maxQuantity
    setQuantityInput(newQuantity)
    onChange(publicationId, newQuantity)
    setFromControls(true)
  }

  const handleAdd = () => updateQuantity(quantity + 1)
  const handleRemove = () => updateQuantity(quantity - 1)
  const handleChange = (e) => {
    const { value } = e.target
    const quantityValue = value || 0
    if (!isNaN(parseInt(quantityValue)) && Math.max(0, parseInt(quantityValue)) !== quantityInput) {
      updateQuantity(Math.max(0, parseInt(quantityValue)))
    }
  }

  const parsedTimeframe = useMemo(() => {
    const result = {
      delta: 0
    }
    if (!timeframe || !timeframe.startTime || !timeframe.endTime) {
      return result
    }
    const publicationDate = activeDay && moment(activeDay).isValid()
      ? moment(activeDay)
      : moment()
    result.startBoundary = publicationDate.set({
      hour: timeframe.startTime.split(':')[0],
      minute: timeframe.startTime.split(':')[1]
    })
    result.endBoundary = publicationDate.set({
      hour: timeframe.endTime.split(':')[0],
      minute: timeframe.endTime.split(':')[1]
    })
    result.delta = moment().isAfter(result.endBoundary)
      ? 1
      : moment().isBefore(result.startBoundary)
        ? -1
        : 0

    return result
  }, [timeframe, activeDay])

  if (noAddButton) {
    return null
  }

  if (parsedTimeframe.delta > 0) {
    return (
      <div className={b('', { 'out-of-stock': true })}>
        <span>Victime de <br />son succès</span>
      </div>
    )
  }

  if (parsedTimeframe.delta < 0) {
    return (
      <div className={b('', { 'come-back': true })}>
        <span>
          Revenez <br /> à {parsedTimeframe.startBoundary.format('HH[h]mm')}
        </span>
      </div>
    )
  }

  return (
    <div className={b('', { field: true })}>
      <button
        className={b('delete', { quantity })}
        onClick={handleRemove}
        disabled={disabled || !quantity}
        aria-label='Supprimer une unité'
      >
        <svg
          width='30'
          height='30'
          viewBox='0 0 34 34'
          fill='white'
          xmlns='http://www.w3.org/2000/svg'
        >
          <rect x='0' y='0' width='100%' height='100%' fill='none' stroke='black' />
          <rect x='10' y='16' width='14' height='2' fill='inherit' />
        </svg>
      </button>
      <input
        className={b('input', { quantity })}
        value={quantityInput}
        onChange={handleChange}
        disabled={disabled}
      />
      <button
        className={b('add', { text: hasTextAddButton })}
        onClick={handleAdd}
        disabled={disabled || isLast}
        aria-label='Ajouter une unité'
      >
        <svg
          width={hasTextAddButton && quantity === 0 ? '107' : '30'}
          height='30'
          viewBox={`0 0 ${hasTextAddButton && quantity === 0 ? '121' : '34'} 34`}
          fill='white'
          xmlns='http://www.w3.org/2000/svg'
        >
          <rect x='0' y='0' width='100%' height='100%' fill='none' stroke='black' />
          <rect x='16' y='10' width='2' height='14' fill='inherit' />
          <rect x='10' y='16' width='14' height='2' fill='inherit' />
          <path d='M44.2735 22H47.1385L43.3285 12.13H40.2685L36.4585 22H38.9785L39.6535 19.99H43.5985L44.2735 22ZM40.1635 18.46L41.6035 14.14H41.6335L43.0885 18.46H40.1635ZM51.3978 22.15C53.9179 22.15 55.3578 20.665 55.3578 17.65V12.13H52.8978V17.89C52.8978 19.465 52.2378 20.215 51.3229 20.215C50.2428 20.215 49.7778 19.42 49.7778 18.175C49.7778 17.725 49.7928 17.14 49.7928 16.825H47.6628C47.6478 17.155 47.6178 17.785 47.6178 18.28C47.6178 20.755 48.8478 22.15 51.3978 22.15ZM62.1137 22.15C65.0987 22.15 67.4087 20.245 67.4087 17.02C67.4087 13.855 65.0987 11.98 62.1137 11.98C59.1287 11.98 56.8187 13.855 56.8187 17.02C56.8187 20.245 59.1287 22.15 62.1137 22.15ZM62.1137 20.215C60.5537 20.215 59.3687 19.03 59.3687 17.035C59.3687 15.07 60.5537 13.915 62.1137 13.915C63.6737 13.915 64.8587 15.07 64.8587 17.035C64.8587 19.03 63.6737 20.215 62.1137 20.215ZM73.4131 22.15C76.1731 22.15 77.9431 20.695 77.9431 18.175V12.13H75.7681V18.085C75.7681 19.435 74.8531 20.11 73.5481 20.11C72.2431 20.11 71.3281 19.435 71.3281 18.085V12.13H68.8681V18.175C68.8681 20.695 70.6531 22.15 73.4131 22.15ZM82.026 22H84.486V14.065H87.276V12.13H79.221V14.065H82.026V22ZM88.6246 22H95.7496V20.065H91.0846V17.785H94.5946V16.285H91.0846V14.065H95.6146V12.13H88.6246V22ZM97.1691 22H99.6291V18.325H102.014C102.944 18.325 103.274 18.715 103.274 19.615V22H105.734V19.54C105.734 18.43 105.299 17.65 104.324 17.425V17.395C105.254 17.125 105.794 16.33 105.794 15.07C105.794 13.21 104.384 12.13 101.774 12.13H97.1691V22ZM99.6291 16.84V14.065H101.759C102.899 14.065 103.439 14.59 103.439 15.46C103.439 16.375 102.914 16.84 101.879 16.84H99.6291Z' fill='inherit' />
        </svg>
      </button>
    </div>
  )
}

export default ProductControls
