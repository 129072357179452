import React from 'react'

import BasketItem from './BasketItem'

import { block } from 'utils/classnames'
import { eur, percentage } from 'utils/numeral'

import './BasketItemList.sass'

const b = block.with('basket-item-list')

const BasketItemList = props => {
  const {
    offer,
    items
  } = props

  if (!Array.isArray(items) || items.length === 0) {
    return null
  }

  const offerTitle = React.useMemo(() => (
    offer
      ? `${
        offer?.title || ''
      } - ${
        offer.discountType === 'PERCENTAGE'
          ? percentage(offer.value / 100)
          : eur(parseFloat(offer.value))
      }`
      : ''
  ), [offer])

  return (
    <div className={b('', { offer: !!offerTitle })}>
      {
        offerTitle
          ? <h3 className={b('title')}>{offerTitle}</h3>
          : null
      }
      {
        items
          .map((item, index) => (
            <BasketItem
              key={item.idPublication}
              publicationId={item.idPublication}
              quantity={item.quantity}
              isLast={item.isLastProduct}
              offer={offer}
            />
          ))
      }
    </div>
  )
}

export default BasketItemList
