import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { FormField } from 'components/form'
import { block } from 'utils/classnames'
import { PreventButton } from 'utils/preventButton'
import { getErrorMessageFromConfig } from 'utils/formValidation/user'
import formConfig from 'config/form/user/lostPassword'

import { clearUserErrors, lostPassword } from 'actions/user'
import { getUserErrors, getSecurityIsLoading, getLostPasswordMessage } from 'reducers'

import './ResetPasswordForm.sass'

const getErrorMessage = getErrorMessageFromConfig(formConfig)

export const ResetPasswordForm = ({ handleLogin, className = 'password-form' }) => {
  const initialState = { ...formConfig.defaults }
  const [data, setData] = useState(initialState)
  const [isLostPasswordEmailSent, setIsLostPasswordEmailSent] = useState(false)
  const dispatch = useDispatch()
  const userErrors = useSelector(getUserErrors)
  const isLoading = useSelector(getSecurityIsLoading)
  const lostPasswordMessage = useSelector(getLostPasswordMessage)

  const handleChange = name => value => {
    setData(data => ({ ...data, [name]: value }))
  }

  const handleSubmit = e => {
    e.preventDefault()
    dispatch(lostPassword(data.email))
  }

  useEffect(() => {
    dispatch(clearUserErrors())
    return () => dispatch(clearUserErrors())
  }, [])

  useEffect(() => {
    if (!userErrors && lostPasswordMessage) {
      setIsLostPasswordEmailSent(true)
    }
  }, [lostPasswordMessage])

  return (
    <div className={block(className)}>
      <h4 className={block(className, 'title')}>
        Réinitialisez votre mot de passe
      </h4>
      <p className={block(className, 'text')}>
        Entrez l'adresse e-mail associée à votre compte et nous vous enverrons un lien pour réinitialiser votre mot de passe.
      </p>
      <form id='lost-password' className={block(className, 'form')} onSubmit={handleSubmit}>
        {
          Object
            .entries(formConfig.fields)
            .map(([name, props]) => (
              <FormField
                disabled={isLoading}
                key={name}
                name={name}
                error={getErrorMessage(name, userErrors)}
                value={data[name]}
                onChange={handleChange(name)}
                type={props.type}
                placeholder={props.label}
              />
            ))
        }
        {
          handleLogin
            ? (
              <span className={block(className, 'login')} onClick={handleLogin}>
                Connectez-vous
              </span>
            )
            : null
        }
        {
          lostPasswordMessage && !userErrors
            ? (
              <span className={block(className, 'success-message', { disabled: isLoading || isLostPasswordEmailSent })}>
                {lostPasswordMessage}.
              </span>
            )
            : null
        }
        {
          userErrors && userErrors.global
            ? (
              <p className={block(className, 'error-message')}>
                {getErrorMessage('global', userErrors)}.
              </p>
            )
            : null
        }
        <div className={block(className, 'container-submit')}>
          <PreventButton
            isForm
            className={block(className, 'button-submit', { loading: isLoading })}
            disabled={isLoading || isLostPasswordEmailSent}
          >
          Envoyer
          </PreventButton>
        </div>
      </form>
    </div>
  )
}

export default ResetPasswordForm
