import React, { useEffect, useState } from 'react'

import { block } from 'utils/classnames'
import { getCurrencyLiteral } from 'utils/currency'

import './BasketCutlery.sass'

const b = block.with('basket-cutlery')

const BasketCutlery = (props) => {
  const {
    quantity,
    onChange
  } = props

  const [quantityInput, setQuantityInput] = useState(quantity)

  useEffect(() => {
    onChange(quantityInput)
  }, [quantityInput])

  const handleAdd = () => setQuantityInput(quantity + 1)
  const handleRemove = () => setQuantityInput(quantity - 1)

  return (
    <div className={b('')}>
      <div className={b('container')}>
        <div className={b('section')}>
          <img src='/images/basket/cutlery.svg' alt='cutlery' className={b('cultleryIcon')} />
          <h3>Couverts</h3>
          <div className={b('counter')}>
            <button className={b('remove-button')} disabled={quantity <= 0} onClick={handleRemove} />
            <p className={b('quantityLabel', { quantity })}>{quantity}</p>
            <button className={b('add-button')} disabled={quantity >= 100} onClick={handleAdd} />
          </div>
        </div>
        <div className={b('amount')}>{quantity === 0 ? '0' : getCurrencyLiteral(quantity * 0.15)} € </div>
      </div>
      <div className={b('subTitle')}>0.15€ l’unité</div>
    </div>
  )
}

export default BasketCutlery
