import PropTypes from 'prop-types'
import React, { useCallback, useState } from 'react'

import DropOffLabel from './DropOffLabel'
import TextInput from 'components/form/TextInput'
import PanelContent from 'components/Common/Panel/PanelContent'

import { block } from 'utils/classnames'
import {
  DEFAULT_DROPOFF_DETAILS, FIELD_DROPOFF, FIELD_DROPOFF_COMMENT,
  OPTION_BUILDING, OPTION_RECEPTION_DESK
} from 'utils/delivery'

import href from 'config/hrefs'

import './BasketDeliveryComment.sass'

export const MEAL_SETTING_LUNCH = 'LUNCH'
export const MEAL_SETTING_DINNER = 'DINNER'

export const OPTIONS = [
  OPTION_BUILDING,
  OPTION_RECEPTION_DESK
]

export const AREA_DROPOFF_ID = 417

const b = block.with('delivery-comment')

export const BasketDeliveryComment = (props) => {
  const {
    areaId,
    address,
    dropOff = DEFAULT_DROPOFF_DETAILS[FIELD_DROPOFF],
    dropOffForced = false,
    dropOffCommentRequired = false,
    dropOffComment = '',
    mealSetting,
    onChange,
    onBack
  } = props

  const [dropOffDetails, setDropOffDetails] = useState({
    [FIELD_DROPOFF]: dropOff,
    [FIELD_DROPOFF_COMMENT]: dropOffComment
  })

  const handleChangeComment = useCallback(
    (comment) => {
      setDropOffDetails(previousDropOffDetails => ({ ...previousDropOffDetails, [FIELD_DROPOFF_COMMENT]: comment }))
    },
    []
  )

  const handleChange = useCallback(
    (e) => {
      const { name, value } = e.target
      setDropOffDetails(previousDropOffDetails => ({
        ...previousDropOffDetails,
        [name]: value
      }))
    },
    []
  )

  const handleSubmit = (e) => {
    e.preventDefault()
    onChange && onChange(dropOffDetails, address)
    onBack && onBack()
  }

  return (
    <PanelContent
      onBack={onBack}
      title='Instructions au coursier'
    >
      <form className={b('')} onSubmit={handleSubmit}>
        <div className={b('safety')}>
          <p>Votre coursier vous remettra votre commande en toute sécurité. <a href={href.healthSafetyPage} target='_blank' rel='noopener noreferrer'>En savoir plus.</a></p>
        </div>
        {
          OPTIONS.map(option => (
            <label
              key={option}
              className={b('button-select-drop-off', { active: dropOffDetails.dropOff === option })}
              htmlFor={'radio_' + option}
            >
              <input
                id={'radio_' + option}
                type='radio'
                name='dropOff'
                value={option}
                onChange={handleChange}
                checked={dropOffDetails.dropOff === option}
                disabled={dropOffForced && dropOff !== option}
              />
              <span className={b('input-radio-check')} />
              <span>
                <DropOffLabel
                  mealSetting={mealSetting}
                  dropOff={option}
                  areaId={areaId}
                />
              </span>
              {
                dropOffForced && dropOff !== option
                  ? (
                    <p className={b('input-option-warn')}>
                      Ce choix a été désactivé pour cette adresse par le gestionnaire de votre entreprise
                    </p>
                  )
                  : null
              }
            </label>
          ))
        }

        <TextInput
          className={b('comment-input')}
          value={dropOffDetails[FIELD_DROPOFF_COMMENT]}
          onChange={handleChangeComment}
          required={dropOffCommentRequired}
          maxLength='250'
          placeholder={dropOffDetails[FIELD_DROPOFF] === OPTION_RECEPTION_DESK && mealSetting === MEAL_SETTING_DINNER
            ? 'Code, interphone, étage...'
            : areaId === AREA_DROPOFF_ID
              ? 'Numéro du stand'
              : 'Entreprise, code, interphone...'}
        />

        <button type='submit' className={b('btn-submit')}>
          Valider
        </button>
      </form>
    </PanelContent>
  )
}
BasketDeliveryComment.propTypes = {
  areaId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  dropOff: PropTypes.oneOf([
    OPTION_BUILDING,
    OPTION_RECEPTION_DESK
  ]),
  dropOffForced: PropTypes.bool,
  dropOffCommentRequired: PropTypes.bool,
  dropOffComment: PropTypes.string,
  mealSetting: PropTypes.oneOf([
    MEAL_SETTING_LUNCH,
    MEAL_SETTING_DINNER
  ]),
  onChange: PropTypes.func,
  onBack: PropTypes.func
}
export default BasketDeliveryComment
