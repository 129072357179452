import PropTypes from 'prop-types'
import React, { useEffect } from 'react'

import { block } from 'utils/classnames'
import { eur } from 'utils/numeral'
import { pushFunnelEvent } from 'utils/googletagmanager'

import './GooglePaySelector.sass'

const b = block.with('googlepay-selector')

export const GooglePaySelector = props => {
  const {
    isActive = true,
    isLoading = false,
    isDisabled = false,
    isSelected = false,
    payableAmount = 0,
    onClick
  } = props

  useEffect(() => {
    isDisabled && isSelected && onClick && onClick()
  }, [isDisabled, isSelected, onClick])

  useEffect(() => {
    isActive && isSelected && pushFunnelEvent('PaymentMethodSelected', {
      paymentMethod: 'googlepay',
      provider: 'stripe',
      amount: payableAmount
    })
  }, [isActive, isSelected, payableAmount])

  if (!isActive) {
    isSelected && onClick && onClick()

    return null
  }

  return (
    <button
      type='button'
      onClick={onClick}
      disabled={isDisabled}
      className={
        b('', {
          active: isSelected,
          loading: isLoading
        })
      }
    >
      <i className={b('icon')} />
      <span className={b('text')}>
        <span className={b('title')}>
          Google Pay&ensp;
        </span>
        {
          isDisabled
            ? (
              <small className={b('info')}>
                Google Pay n\'est pas disponible pour votre navigateur.
              </small>
            )
            : null
        }
      </span>
      {
        isSelected
          ? (
            <span className={b('amount')}>
              {eur(payableAmount)}
            </span>
          )
          : null
      }
    </button>
  )
}

GooglePaySelector.propTypes = {
  isActive: PropTypes.bool,
  isLoading: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isSelected: PropTypes.bool,
  payableAmount: PropTypes.number,
  onClick: PropTypes.func
}

export default GooglePaySelector
