import { connect } from 'react-redux'

import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'

import AnimatedModal from 'components/Common/AnimatedModal'

import { block } from 'utils/classnames'

import {
  getCheckoutError, getUnavailableProducts
} from 'reducers'

import './PopinErrorModal.sass'
import { POPIN_ERROR_MESSAGE, SET_UNAVAILABLE_PRODUCTS, SET_CHECKOUT_ERROR_ACTION } from 'actions/home/checkout'
import { isEmpty } from 'lodash'

const b = block.with('popin-error-modal')

export const PopinErrorModal = props => {
  const {
    error = null,
    onClose,
    productsRemoved = []
  } = props

  const [shouldShow, setShouldShow] = useState(false)
  useEffect(() => { setShouldShow((error === POPIN_ERROR_MESSAGE || error === 'conflict') && !isEmpty(productsRemoved)) }, [error, productsRemoved])

  const _onClose = () => {
    setShouldShow(false)
    setTimeout(() => onClose && onClose(), 500)
  }

  return (
    <AnimatedModal
      className={b()}
      shouldShow={shouldShow}
      onClick={_onClose}
      isFullsSreenOnMobile
    >
      <div className={b('content')}>
        <button
          className={b('close')}
          onClick={_onClose}
          aria-label='Fermer la modale'
        />
        <h4 className={b('title')}>
          Oups, certains produits sont indisponibles.
        </h4>
        <p className={b('body')}>
          Ces produits ont été victimes de leur succès, nous avons pris le soin de les enlever de votre panier :
        </p>
        <div className={b('body')}>
          {productsRemoved.map(product => <p key={product.id}>- {product.quantityRemoved} x {product.title} </p>)}
        </div>
        <p className={b('body')}>
          Laissez-vous tenter par un autre produit du jour.
        </p>
        <button
          className={b('confirm')}
          onClick={_onClose}
        >
            J'ai compris
        </button>
      </div>
    </AnimatedModal>
  )
}
PopinErrorModal.propTypes = {
  onClose: PropTypes.func,
  error: PropTypes.string,
  productsRemoved: PropTypes.array
}

export const ConnectedPopinErrorModal = connect(
  (state, props) => {
    const error = getCheckoutError(state, props)
    const productsRemoved = getUnavailableProducts(state, props)

    return { error, productsRemoved }
  },
  dispatch => ({
    onClose: () => {
      dispatch({ type: SET_UNAVAILABLE_PRODUCTS, products: [] })
      dispatch({ type: SET_CHECKOUT_ERROR_ACTION, checkoutError: '' })
    }
  })
)(PopinErrorModal)

export default PopinErrorModal
