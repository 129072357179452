import PropTypes from 'prop-types'
import React from 'react'
import { useTransition, animated } from 'react-spring'

import { block } from 'utils/classnames'
import { absoluteImageUrl } from 'utils/productPicture'

import { ProductPrice } from 'components/product/ProductPrice'
import { Ribbon } from 'components/product/tags/Ribbon'

import './SlideshowItem.sass'

const b = block.with('product-slideshow-item')

const transitionConfig = {
  item: {
    from: { postion: 'absolute', opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    config: { duration: 700 }
  },
  image: {
    from: { opacity: 0, transform: 'translate3d(10px,0,0)' },
    enter: { opacity: 1, transform: 'translate3d(0px,0,0)' },
    leave: { opacity: 0, transform: 'translate3d(10px,0,0)' },
    config: { duration: 1500 }
  },
  title: {
    from: { opacity: 0, transform: 'translate3d(20px,0,0)' },
    enter: { opacity: 1, transform: 'translate3d(0px,0,0)' },
    leave: { opacity: 0, transform: 'translate3d(20px,0,0)' },
    config: { duration: 2000 }
  },
  price: {
    from: { opacity: 0, transform: 'translate3d(50px,0,0)' },
    enter: { opacity: 1, transform: 'translate3d(0px,0,0)' },
    leave: { opacity: 0, transform: 'translate3d(50px,0,0)' },
    config: { duration: 1700 }
  }
}

export const SlideshowItem = props => {
  const {
    shouldShow = false,
    product
  } = props

  const itemTransitions = useTransition(shouldShow, null, transitionConfig.item)
  const imageTransitions = useTransition(shouldShow, null, transitionConfig.image)
  const titleTransitions = useTransition(shouldShow, null, transitionConfig.title)
  const priceTransitions = useTransition(shouldShow, null, transitionConfig.price)

  const imageUrl = React.useMemo(() => absoluteImageUrl(product.picture, product.idProduct), [product])

  return itemTransitions.map(({ item, key, props }) => (
    item && (
      <animated.div key={key} className={b('', { active: item })} style={props}>
        {
          imageTransitions.map(({ item, key, props }) => (
            item && (
              <animated.div
                key={key}
                className={b('picture')}
              >
                <div
                  className={b('image')}
                  style={{ backgroundImage: `url(${imageUrl})` }}
                />
                <Ribbon
                  tags={product.tags}
                />
              </animated.div>
            )
          ))
        }
        {
          priceTransitions.map(({ item, key, props }) => (
            item && (
              <animated.div
                key={key}
                style={props}
                className={b('price')}
              >
                <ProductPrice
                  price={product.price}
                  originalPrice={product.originalPrice}
                />
              </animated.div>
            )
          ))
        }
        <div className={b('logo')} aria-label='FoodChéri logo' />
        {
          titleTransitions.map(({ item, key, props }) => (
            item && (
              <animated.h4
                key={key}
                style={props}
                className={b('title')}
              >
                {product.title}
              </animated.h4>
            )
          ))
        }
      </animated.div>
    )
  ))
}
SlideshowItem.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    title: PropTypes.string,
    price: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    image: PropTypes.string
  }),
  shouldShow: PropTypes.bool
}
export default SlideshowItem
