import PropTypes from 'prop-types'
import React, { useState, useCallback, useEffect } from 'react'

import { BodyClassName } from 'components/Common/BodyClassName'

import { block } from 'utils/classnames'

import './SoHappyWarning.sass'

const b = block.with('so-happy-warning')

export const SoHappyWarning = props => {
  const {
    isActive = false,
    isCheckingOut = false.valueOf,
    noShift = false
  } = props

  const [shouldShow, setShouldShow] = useState(isActive)
  const onClose = useCallback(() => {
    setShouldShow(false)
  }, [])

  useEffect(() => {
    setShouldShow(isActive)
  }, [isActive])

  if (!shouldShow) {
    return null
  }

  return (
    <div className={b('', { checkout: isCheckingOut, noShift })}>
      {
        isCheckingOut
          ? <BodyClassName className='is-so-happy' />
          : null
      }
      {
        !isCheckingOut ? (
          <p className={b('body')}>
            Votre participation employeur sera appliquée lors du passage de votre
            commande avec le badge entreprise si vous y êtes éligible et&nbsp;
            <b>pour une livraison sur votre lieu de travail</b>
            .
          </p>
        ) : (
          <p className={b('body')}>
          Votre participation employeur sera appliquée lors du passage de votre
          commande avec le badge entreprise si vous y êtes éligible.
          </p>
        )
      }
      {
        !isCheckingOut
          ? (
            <button className={b('close')} onClick={onClose}>
              Fermer
            </button>
          )
          : null
      }
    </div>
  )
}
SoHappyWarning.propTypes = {
  isActive: PropTypes.bool,
  isCheckingOut: PropTypes.bool
}

export default SoHappyWarning
