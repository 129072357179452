import { roundCurrency } from './numeral'

/**
 * Return currency with two decimals
 * @param {Number | String} amount
 * @returns {String}
 */
export const getCurrencyLiteral = amount => {
  return roundCurrency(amount).toFixed(2)
}
