import { connect } from 'react-redux'
import React from 'react'
import PropTypes from 'prop-types'

import {
  getPayableAmount,
  getActiveCouponCode,
  getHasSodexo
} from 'reducers'

export const Coupon = props => {
  const {
    code = '',
    isActive
  } = props

  return isActive && code
    ? <input type='hidden' name='discountCode' value={code} />
    : null
}

Coupon.propTypes = {
  code: PropTypes.string,
  isActive: PropTypes.bool
}

export const ConnectedCoupon = connect(
  (state, props) => ({
    code: getActiveCouponCode(state, props),
    isActive: getPayableAmount(state, props) > 0 && !getHasSodexo(state, props)
  })
)(Coupon)

export default Coupon
