import PropTypes from 'prop-types'
import React from 'react'

import { getLabelFromAddress, addressTypes } from 'utils/address'
import { block } from 'utils/classnames'

import './AddressSelect.sass'

const b = block.with('address-select')

export const AddressSelect = props => {
  const {
    className = '',
    isOpen = false,
    isLoading = false,
    suggestions = [],
    onClick
  } = props

  if (!isOpen) { return null }

  const addresses = Array.isArray(suggestions) && suggestions.length > 0
    ? suggestions
    : []

  return (
    <ul className={`${className} ${b('', { loading: isLoading })}`.trim()}>
      {
        isLoading
          ? (
            <li key='loader' className={b('item', { status: true })}>
              Chargement en cours...
            </li>
          )
          : addresses.map((address, i) => (
            <li key={address.address + i}>
              <button
                onClick={address.type === addressTypes.LEGAL ? undefined : onClick(address)}
                className={b('item', { [address.type]: true })}
                disabled={address.type === addressTypes.LEGAL}
              >
                {getLabelFromAddress(address)}
              </button>
            </li>
          ))
      }
      {
        !isLoading && addresses.some(address => address.type === addressTypes.GOOGLE)
          ? <li className={b('item', { legal: true })} />
          : null
      }
    </ul>
  )
}

AddressSelect.propTypes = {
  className: PropTypes.string,
  isOpen: PropTypes.bool,
  isLoading: PropTypes.bool,
  suggestions: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string,
      address: PropTypes.string
    })
  ),
  onClick: PropTypes.func
}

export default AddressSelect
