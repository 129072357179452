import PropTypes from 'prop-types'
import React, { useCallback, useEffect, useMemo } from 'react'

import { confirm } from 'components/Common/Confirm'
import {
  NothingToPay,
  Coupon,
  CreditCard,
  SodexoBadge,
  Edenred,
  Trd,
  TrdPass,
  CBTrd,
  Restoflash,
  Wedoofood,
  Limonetik,
  MonthlyBilling,
  CustomerCredit,
  MealVoucher,
  PayPal,
  GooglePay,
  ApplePay
} from './index'

import {
  EXCLUSIVE_METHODS,
  TOTAL_METHODS,
  CUSTOMER_CREDIT_ID,
  BADGE_ID,
  MEAL_VOUCHER_ID,
  MEAL_VOUCHER_ONLY_ID,
  CREDIT_CARD_ID,
  LIMONETIK_ID,
  PAYPAL_ID,
  EDENRED_ID,
  GOOGLE_PAY_ID,
  APPLE_PAY_ID,
  MONTHLY_BILLING_ID,
  TRD_ID,
  TRD_PASS_ID,
  CBTRD_ID,
  RESTOFLASH_ID,
  WEDOOFOOD_ID
} from 'config/paymentMethods'

import { block } from 'utils/classnames'

import './PaymentMethodSelector.sass'

const b = block.with('payment-method-selector')

export const PaymentMethodSelector = props => {
  const {
    updateSelection,
    appendPaymentMethods,
    onSubmit,
    onAddCreditCard,
    creditCardPaymentMethod,
    selected = [],
    defaults = [],
    hasCompanyOffer = false,
    hasCoupon = false,
    payableAmount = 0,
    mealVoucherAmount = 0,
    paymentRequest,
    previousCardLength
  } = props

  const handleUpdate = (methodId, hasWarnings = true) => {
    if (!methodId || !updateSelection) {
      return
    }

    // If payment method is already selected, unselect it
    if (selected.includes(methodId)) {
      updateSelection(
        selected.filter(
          method => methodId !== method
        )
      )
      return
    }
    const hadMealVoucher = selected.includes(MEAL_VOUCHER_ONLY_ID)
    const isMealVoucher = methodId === MEAL_VOUCHER_ID || methodId === MEAL_VOUCHER_ONLY_ID
    const confirmMealVoucher = EXCLUSIVE_METHODS.includes(methodId) && !isMealVoucher && hadMealVoucher

    if (hasWarnings && selected.length > 0 && confirmMealVoucher) {
      confirm('Vos titres restaurant papier seront désactivés\u00A0: ils sont incompatibles avec le moyen de paiement sélectionné.')
        .then((shouldUpdate) => {
          shouldUpdate && updateSelection([methodId])
        })
        .catch(console.log)

      return
    }

    if (hasWarnings && methodId === BADGE_ID && hasCompanyOffer) {
      confirm('Votre remise entreprise n\'est pas cumulable avec le paiement par Badge Entreprise.')
        .then((shouldUpdate) => {
          shouldUpdate && updateSelection([methodId])
        })
        .catch(console.log)

      return
    }

    if (hasWarnings && methodId === BADGE_ID && hasCoupon) {
      confirm('Les codes promo ne sont pas cumulables avec le paiement par Badge Entreprise.')
        .then((shouldUpdate) => {
          shouldUpdate && updateSelection([methodId])
        })
        .catch(console.log)

      return
    }

    const hasConflict = EXCLUSIVE_METHODS.includes(methodId) ||
      selected.some(
        method => EXCLUSIVE_METHODS.includes(method)
      )

    selected.length > 0 && hasConflict
      ? updateSelection([methodId])
      : appendPaymentMethods([methodId])
  }

  const hasTotalMethod = useMemo(() => (
    selected.length > 0 &&
      selected.some(
        method => TOTAL_METHODS.includes(method)
      )
  ), [selected])

  useEffect(() => {
    const { cards = [] } = creditCardPaymentMethod || {}
    if (defaults.length > 0 && !(Array.isArray(cards) && cards[0] && Number.isFinite(previousCardLength) && cards.length > previousCardLength)) {
      for (const { type } of defaults) {
        handleUpdate(type, false)
      }
    }
    return () => {
      updateSelection([])
    }
  }, [])

  const handleClick = useCallback(
    paymentMethodId => () => handleUpdate(paymentMethodId),
    [handleUpdate]
  )

  const mealVoucherId = useMemo(() => {
    return payableAmount > 0 && mealVoucherAmount > 0 && mealVoucherAmount >= payableAmount
      ? MEAL_VOUCHER_ONLY_ID
      : MEAL_VOUCHER_ID
  }, [payableAmount, mealVoucherAmount])

  useEffect(() => {
    if (
      selected.includes(MEAL_VOUCHER_ID) &&
      mealVoucherId !== MEAL_VOUCHER_ID
    ) {
      handleUpdate(mealVoucherId)
    }
    if (
      selected.includes(MEAL_VOUCHER_ONLY_ID) &&
      mealVoucherId !== MEAL_VOUCHER_ONLY_ID
    ) {
      handleUpdate(mealVoucherId)
    }
  }, [selected, mealVoucherId])

  return (
    <div className={b()}>
      <Coupon />
      <CustomerCredit
        paymentMethodId={CUSTOMER_CREDIT_ID}
        isDisabled={hasTotalMethod}
      />
      <MonthlyBilling
        paymentMethodId={MONTHLY_BILLING_ID}
        isSelected={selected.includes(MONTHLY_BILLING_ID)}
        onClick={handleClick(MONTHLY_BILLING_ID)}
        onSubmit={onSubmit}
      />
      <SodexoBadge
        paymentMethodId={BADGE_ID}
        isSelected={selected.includes(BADGE_ID)}
        onClick={handleClick(BADGE_ID)}
        onSubmit={onSubmit}
      />
      <MealVoucher
        paymentMethodId={MEAL_VOUCHER_ID}
        isSelected={selected.includes(MEAL_VOUCHER_ID) || selected.includes(MEAL_VOUCHER_ONLY_ID)}
        onClick={handleClick(mealVoucherId)}
        onSubmit={onSubmit}
      />
      <CreditCard
        isSelected={selected.includes(CREDIT_CARD_ID)}
        onAddCreditCard={onAddCreditCard}
        onClick={handleClick(CREDIT_CARD_ID)}
        onSubmit={onSubmit}
        defaults={defaults}
        previousCardLength={previousCardLength}
      />
      <Limonetik
        paymentMethodId={LIMONETIK_ID}
        isSelected={selected.includes(LIMONETIK_ID)}
        onClick={handleClick(LIMONETIK_ID)}
        onSubmit={onSubmit}
      />
      <Edenred
        paymentMethodId={EDENRED_ID}
        isSelected={selected.includes(EDENRED_ID)}
        onClick={handleClick(EDENRED_ID)}
        onSubmit={onSubmit}
      />
      <TrdPass
        paymentMethodId={TRD_PASS_ID}
        isSelected={selected.includes(TRD_PASS_ID)}
        onClick={handleClick(TRD_PASS_ID)}
        onSubmit={onSubmit}
      />
      <CBTrd
        paymentMethodId={CBTRD_ID}
        isSelected={selected.includes(CBTRD_ID)}
        onClick={handleClick(CBTRD_ID)}
        onSubmit={onSubmit}
      />
      <Trd
        paymentMethodId={TRD_ID}
        isSelected={selected.includes(TRD_ID)}
        onClick={handleClick(TRD_ID)}
        onSubmit={onSubmit}
      />
      <Restoflash
        paymentMethodId={RESTOFLASH_ID}
        isSelected={selected.includes(RESTOFLASH_ID)}
        onClick={handleClick(RESTOFLASH_ID)}
        onSubmit={onSubmit}
      />
      <Wedoofood
        paymentMethodId={WEDOOFOOD_ID}
        isSelected={selected.includes(WEDOOFOOD_ID)}
        onClick={handleClick(WEDOOFOOD_ID)}
        onSubmit={onSubmit}
      />
      <PayPal
        paymentMethodId={PAYPAL_ID}
        isSelected={selected.includes(PAYPAL_ID)}
        onClick={handleClick(PAYPAL_ID)}
        onSubmit={onSubmit}
      />
      <GooglePay
        paymentMethodId={GOOGLE_PAY_ID}
        isSelected={selected.includes(GOOGLE_PAY_ID)}
        onClick={handleClick(GOOGLE_PAY_ID)}
        onSubmit={onSubmit}
      />
      <ApplePay
        paymentMethodId={APPLE_PAY_ID}
        isSelected={selected.includes(APPLE_PAY_ID)}
        onClick={handleClick(APPLE_PAY_ID)}
        onSubmit={onSubmit}
        paymentRequest={paymentRequest}
      />
      <NothingToPay togglePaymentMethod={handleUpdate} />
      {
        payableAmount > 0 && onAddCreditCard
          ? (
            <div className={b('add-credit-card')}>
              <button type='button' onClick={onAddCreditCard}>
                Ajouter une carte
              </button>
            </div>
          )
          : null
      }
    </div>
  )
}
PaymentMethodSelector.propTypes = {
  defaults: PropTypes.arrayOf(PropTypes.object),
  selected: PropTypes.arrayOf(PropTypes.string),
  updateSelection: PropTypes.func,
  onSubmit: PropTypes.func,
  onAddCreditCard: PropTypes.func,
  hasCompanyOffer: PropTypes.bool,
  hasCoupon: PropTypes.bool,
  paymentRequest: PropTypes.object
}
export default PaymentMethodSelector
