import PropTypes from 'prop-types'
import React from 'react'

import { block } from 'utils/classnames'

import './SlideshowTrigger.sass'

const b = block.with('product-slideshow-trigger')

export const SlideshowTrigger = props => {
  const {
    onClick
  } = props

  return (
    <button type='button' className={b()} onClick={onClick}>
      <svg className={b('icon')} width='26' height='26' viewBox='0 0 26 26' fill='none' stroke='none' xmlns='http://www.w3.org/2000/svg'>
        <circle cx='13' cy='13' r='12.5' stroke='inherit' fill='inherit' />
        <path d='M10 7.80385L19 13L10 18.1962L10 7.80385Z' stroke='inherit' fill='inherit' />
      </svg>

      <span className={b('label')}>
        Diaporama des plats à la carte aujourd'hui
      </span>
    </button>
  )
}

SlideshowTrigger.propTypes = {
  onClick: PropTypes.func.isRequired
}
export default SlideshowTrigger
