import { useSelector, useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import React, { useEffect } from 'react'

import { getMe, getPendingOrder } from 'reducers'
import { getPendingOrder as onUpdate } from 'actions/user'

import RouteButton from 'components/Router/RouteButton'
import DeliveryTrackingButton from './DeliveryTrackingButton'

const ORDER_TRACKING_UPDATE_DELAY = 60 * 1000

export const RouteDeliveryTrackingButton = props => {
  const {
    route,
    orderHash,
    productId = null,
    ...rest
  } = props

  if (!route) {
    return null
  }

  return (
    <RouteButton
      productId={productId}
      Button={DeliveryTrackingButton}
      route={route}
      params={{ orderHash }}
      buttonProps={rest}
    />
  )
}
RouteDeliveryTrackingButton.propTypes = {
  route: PropTypes.object,
  orderHash: PropTypes.string
}
export const ConnectedRouteDeliveryTrackingButton = props => {
  const user = useSelector(getMe)
  const pendingOrder = useSelector(getPendingOrder)
  const dispatch = useDispatch()

  const customerId = user?.id || null

  useEffect(() => {
    if (typeof window === 'undefined' || !customerId) {
      return
    }

    const interval = window.setInterval(
      () => {
        dispatch(onUpdate(user.id))
      },
      ORDER_TRACKING_UPDATE_DELAY
    )

    dispatch(onUpdate(user.id))

    return () => {
      interval && window.clearInterval(interval)
    }
  }, [dispatch, customerId])

  if (!customerId || !pendingOrder?.orderHash) {
    return null
  }

  return (
    <RouteDeliveryTrackingButton
      {...props}
      isActive={!!customerId}
      orderHash={pendingOrder?.orderHash || null}
      etaLive={pendingOrder?.etaLive || null}
    />
  )
}

export default DeliveryTrackingButton
