import PropTypes from 'prop-types'
import React from 'react'

import { block } from 'utils/classnames'

import './EventBanner.sass'

const b = block.with('event-banner')

export const EventBanner = props => {
  const {
    idEvent = null,
    messageCtaMessage = null,
    messageTitle = null,
    messageContentPart1 = null,
    messageContentPart2 = null
  } = props

  if (!idEvent) {
    return null
  }

  return (
    <div className={b()}>
      <p>
        <span className={b('title')}>
          {messageTitle}
        </span>
        &nbsp;
        <span>
          {messageContentPart1}
        </span>
      </p>
      <p>
        <span>
          {messageContentPart2}
        </span>
      </p>
      {
        messageCtaMessage
          ? (
            <a
              className={b('cta')}
              rel='noopener noreferrer'
              target='_blank'
              href={messageCtaMessage}
            />
          )
          : null
      }
    </div>
  )
}

EventBanner.propTypes = {
  idEvent: PropTypes.number,
  messageCtaMessage: PropTypes.string,
  messageTitle: PropTypes.string,
  messageContentPart1: PropTypes.string,
  messageContentPart2: PropTypes.string
}
export default EventBanner
