import PropTypes from 'prop-types'
import React, {
  useCallback,
  useEffect,
  useRef,
  useState
} from 'react'

import { useFullscreen } from 'hooks/dom'

import SlideshowTrigger from './SlideshowTrigger'
import SlideshowContent from './SlideshowContent'

export const SLIDESHOW_PRODUCT_TYPE_NAME = 'MAIN_COURSE'

export const Slideshow = props => {
  const {
    isActive = false,
    products
  } = props

  const [shouldShow, setShouldShow] = useState(false)
  const [mayClose, setMayClose] = useState(false)

  const handleOpen = useCallback(
    () => {
      setShouldShow(true)
      setMayClose(false)
    },
    []
  )
  const handleClose = useCallback(
    () => {
      setShouldShow(false)
      setMayClose(false)
    },
    []
  )

  const closeTriggerHideTimeout = useRef(null)

  const showCloseTrigger = useCallback(() => {
    closeTriggerHideTimeout.current && clearTimeout(closeTriggerHideTimeout.current)
    closeTriggerHideTimeout.current = setTimeout(() => { setMayClose(false) }, 500)
    !mayClose && setMayClose(true)
  }, [mayClose])

  useEffect(() => {
    return () => {
      closeTriggerHideTimeout.current && clearTimeout(closeTriggerHideTimeout.current)
      closeTriggerHideTimeout.current = null
    }
  }, [])

  const {
    isAvailable,
    fullscreenElement
  } = useFullscreen(shouldShow, handleClose)

  const items = React.useMemo(() => (
    Array.isArray(products)
      ? products.filter(p => p.productTypeName.toUpperCase().includes(SLIDESHOW_PRODUCT_TYPE_NAME))
      : []
  ), [products])

  if (!isActive || !isAvailable || items.length === 0) {
    return null
  }

  return (
    <div
      ref={fullscreenElement}
    >
      {
        !shouldShow
          ? (
            <SlideshowTrigger
              onClick={handleOpen}
            />
          )
          : (
            <SlideshowContent
              mayClose={mayClose}
              onMouseMove={showCloseTrigger}
              onClose={handleClose}
              items={items}
            />
          )
      }
    </div>
  )
}
Slideshow.propTypes = {
  isActive: PropTypes.bool,
  products: PropTypes.array.isRequired
}
export default Slideshow
