import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import React, { useEffect } from 'react'

import {
  getPaymentMethod,
  getPayableAmount
} from 'reducers'

import { block } from 'utils/classnames'
import { eur } from 'utils/numeral'
import { pushFunnelEvent } from 'utils/googletagmanager'

import './Limonetik.sass'

const b = block.with('limonetik-selector')

export const Limonetik = props => {
  const {
    isActive = true,
    isSelected = false,
    payableAmount = 0,
    onClick
  } = props

  useEffect(() => {
    isActive && isSelected && pushFunnelEvent('PaymentMethodSelected', {
      paymentMethod: 'limonetik',
      provider: 'limonetik',
      amount: payableAmount
    })
  }, [isActive, isSelected, payableAmount])

  if (!isActive) {
    isSelected && onClick && onClick()

    return null
  }

  return (
    <button
      type='button'
      onClick={onClick}
      className={b('', { active: isSelected })}
    >
      <i className={b('icon')} />
      <span className={b('text')}>
        Pass Restaurant Sodexo
      </span>
      {
        isSelected
          ? (
            <span className={b('amount')}>
              {eur(payableAmount)}
            </span>
          )
          : null
      }
    </button>
  )
}

Limonetik.propTypes = {
  onClick: PropTypes.func.isRequired,
  isActive: PropTypes.bool,
  isSelected: PropTypes.bool,
  payableAmount: PropTypes.number
}

export const ConnectedLimonetik = connect(
  (state, props) => {
    const paymentMethod = getPaymentMethod(state, props) || {}
    const payableAmount = getPayableAmount(state, props)

    return {
      ...paymentMethod,
      payableAmount,
      isActive: !!paymentMethod.isActive && payableAmount > 0
    }
  }
)(Limonetik)

export default Limonetik
