import { block } from 'utils/classnames'

import './PaymentError.sass'

const b = block.with('basket-payment-error')

const PaymentError = ({ checkoutErrorMessage, setCheckoutError }) => {
  return (
    <div className={b()}>
      <button className={b('close-btn')} onClick={setCheckoutError} />
      <div className={b('message')}>
        {checkoutErrorMessage}
      </div>
    </div>
  )
}
export default PaymentError
