import React, { useCallback } from 'react'
import { useSelector } from 'react-redux'

import { block } from 'utils/classnames'
import { getMenuItemImage } from 'utils/productPicture'
import { trimString } from 'utils/strings'
import ImageCloudinary from 'components/ImageCloudinary'
import { getCartProductQuantity } from 'reducers'

import './OrderItem.sass'

const b = block.with('basket-item')

const OrderItem = ({
  publicationId,
  canEditQuantity = true,
  quantity,
  name,
  image,
  price,
  onChange,
  menu,
  isLast = false,
  offer
}) => {
  const totalProductQuantity = useSelector(state => getCartProductQuantity(state, { publicationId }))

  const photo = Array.isArray(menu) && menu.length > 0
    ? getMenuItemImage(menu, image)
    : image

  const handleAdd = useCallback(
    () => {
      onChange && onChange(publicationId, totalProductQuantity + 1)
    },
    [publicationId, totalProductQuantity]
  )
  const handleRemove = useCallback(
    () => {
      onChange && onChange(publicationId, totalProductQuantity - 1)
    },
    [publicationId, totalProductQuantity]
  )

  return (
    <div className={b()}>
      <ImageCloudinary className={b('image')} publicId={photo} height={120} />
      <div className={b('infos')}>
        <p className={b('title')}>{trimString(name, 62)}</p>
        <div className={b('values')}>
          {
            canEditQuantity
              ? (
                <div className={b('counter')}>
                  <button
                    className={b('remove-button')}
                    onClick={handleRemove}
                  />
                  <p>{quantity}</p>
                  <button
                    className={b('add-button')}
                    onClick={handleAdd}
                    disabled={isLast || (offer?.usageLimitByQuantity && quantity >= offer.usageLimitByQuantity)}
                  />
                </div>
              )
              : null
          }
          <div className={b('price')}>
            {price}
          </div>
        </div>
      </div>
    </div>
  )
}

export default OrderItem
