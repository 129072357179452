import PropTypes from 'prop-types'
import React, { useCallback, useEffect, useState } from 'react'

import { TextInput } from './TextInput'
import { PhoneInput } from './PhoneInput'
import { PasswordInput } from './PasswordInput'
import { Checkbox } from './Checkbox'

export const FormField = props => {
  const {
    required = false,
    type = 'text',
    name,
    label = '',
    error = '',
    onChange,
    value = ''
  } = props

  const [showError, setShowError] = useState(!!error)

  let Input = TextInput

  if (type === 'checkbox') {
    return (
      <Checkbox
        {...props}
      />
    )
  } else if (type === 'tel') {
    Input = PhoneInput
  } else if (type === 'password') {
    Input = PasswordInput
  }

  const handleChange = useCallback(
    (value) => {
      setShowError(false)
      onChange && onChange(value)
    },
    [onChange]
  )

  useEffect(() => {
    setShowError(!!error)
  }, [error])

  return (
    <label
      htmlFor={name}
      className={
        `${
          error && showError ? 'input-error' : ''
        } ${
          value === '' ? 'empty' : ''
        }`.trim()
      }
    >
      {label}
      {required ? '*' : null}
      {
        <Input {...props} onChange={handleChange} />
      }
      {
        error && showError
          ? (
            <span className='form-error'>
              {error}
            </span>
          )
          : null
      }
    </label>
  )
}
FormField.propTypes = {
  required: PropTypes.bool,
  type: PropTypes.string,
  name: PropTypes.string,
  error: PropTypes.string,
  label: PropTypes.string
}

export default FormField
