import PropTypes from 'prop-types'
import React, { useMemo, useCallback } from 'react'

import { block } from 'utils/classnames'
import { eur } from 'utils/numeral'
import { couponValueTypes } from 'config/coupon'

import './OrderTotal.sass'

const b = block.with('order-total')
const bb = block.with('order-total-row')

export const OrderTotalRow = props => {
  const {
    id,
    label,
    amount,
    isNegative = false,
    allowZero = false,
    hasDecimalZeros = false,
    zeroString,
    handleClear
  } = props

  if (!id || !label) {
    return null
  }

  if (allowZero === false && !amount) {
    return null
  }

  const amountString = zeroString && !amount
    ? zeroString
    : eur(amount, hasDecimalZeros)

  const onClear = useCallback(e => {
    e.preventDefault()
    e.stopPropagation()
    handleClear && handleClear()
  })

  return (
    <div className={bb('', { [id]: true })}>
      <p className={bb('label')}>
        {label}
        {
          handleClear
            ? (
              <button
                onClick={onClear}
                data-label='Supprimer le code promo'
              >
                ×
              </button>
            )
            : null
        }
      </p>
      <p className={bb('amount')}>
        {isNegative ? '-\u00A0' : null}
        {amountString}
      </p>
    </div>
  )
}
OrderTotalRow.propTypes = {
  id: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  label: PropTypes.string,
  amount: PropTypes.number,
  isNegative: PropTypes.bool,
  allowZero: PropTypes.bool,
  zeroString: PropTypes.string,
  handleClear: PropTypes.func,
  type: PropTypes.oneOf(Object.values(couponValueTypes))
}

export const OrderTotal = props => {
  const {
    className = '',
    companySetting,
    deliveryFee = 0,
    discount = 0,
    companyDiscount = 0,
    totalDue = 0,
    total = 0,
    totalDueWithoutCompanyDiscount = 0,
    activeCoupon = null,
    showCouponInOrderDetails = false,
    clearCoupon,
    hasSodexo = false,
    hasMonthlyBilling = false
  } = props

  let couponAmount = 0
  if (showCouponInOrderDetails && activeCoupon) {
    couponAmount = activeCoupon.amount
  } else {
    if (!hasSodexo && activeCoupon?.discountAmount) {
      couponAmount = activeCoupon.discountAmount
    } else couponAmount = 0
  }

  const totalAmount = useMemo(() => {
    if (hasSodexo && total) {
      return total
    }

    if (hasMonthlyBilling && totalDueWithoutCompanyDiscount) {
      return totalDueWithoutCompanyDiscount
    }

    return totalDue
  })

  return (
    <div className={`${b()} ${className || ''}`.trim()}>
      <OrderTotalRow
        label={'Réduction' + (companySetting ? ' offre entreprise' : '')}
        amount={!hasSodexo ? discount : 0}
        id='company-offer'
        isNegative
      />
      <OrderTotalRow
        label={companySetting || 'Participation employeur'}
        amount={!hasSodexo && !hasMonthlyBilling ? companyDiscount : 0}
        id='company'
        isNegative
      />
      <OrderTotalRow
        label={
          activeCoupon?.code ? `Code : ${activeCoupon.code}` : ''
        }
        amount={couponAmount}
        id='coupon'
        isNegative
        handleClear={clearCoupon}
        type={activeCoupon?.discountValueType}
      />
      <OrderTotalRow
        label='Frais de livraison'
        amount={deliveryFee}
        id='delivery-fee'
        zeroString='Offerts'
        allowZero
      />
      <OrderTotalRow
        label='Votre addition'
        amount={totalAmount}
        id='total'
        allowZero
        hasDecimalZeros
      />
    </div>
  )
}
OrderTotal.propTypes = {
  className: PropTypes.string,
  companySetting: PropTypes.string,
  deliveryFee: PropTypes.number,
  discount: PropTypes.number,
  companyDiscount: PropTypes.number,
  totalDue: PropTypes.number,
  totalAmount: PropTypes.number,
  totalDueWithoutCompanyDiscount: PropTypes.number,
  activeCoupon: PropTypes.shape({
    code: PropTypes.string
  }),
  clearCoupon: PropTypes.func,
  hasSodexo: PropTypes.bool,
  hasMonthlyBilling: PropTypes.bool
}
export default OrderTotal
