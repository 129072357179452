import { useState, useCallback, useEffect } from 'react'
import { useDispatch } from 'react-redux'

import { block } from 'utils/classnames'

import './PaymentAuthorizationFrame.sass'

const b = block.with('payment-authorization-frame')

const PaymentAuthorizationFrame = ({ src, onConfirm, onSuccess }) => {
  const [spinnerOn, showSpinner] = useState(true)

  const dispatch = useDispatch()

  const removeSpinner = useCallback(() => {
    showSpinner(false)
  }, [src])
  const handleIframeMessage = useCallback((event) => {
    const parentLocation = window.location
    if (event.isTrusted && event.origin === parentLocation.origin && event.data?.provider) {
      onConfirm && dispatch(onConfirm(event.data, onSuccess))
    }
  }, [])

  useEffect(() => {
    window.addEventListener('message', handleIframeMessage)
    return () => {
      window.removeEventListener('message', handleIframeMessage)
    }
  }, [])

  return (
    <div className={b('')}>
      {spinnerOn &&
        <div className={b('loaderContainer')}>
          <div className={b('loader')} />
        </div>}
      <iframe
        title='paymentAuthFrame'
        src={src}
        className={b('iframe')}
        onLoad={removeSpinner}
      />
    </div>
  )
}

export default PaymentAuthorizationFrame
