import PropTypes from 'prop-types'
import React from 'react'

import TimeslotLabel from 'components/Common/Delivery/Timeslot'

import { block } from 'utils/classnames'

import './Timeslot.sass'

const b = block.with('timeslot')

export const Timeslot = (props = {}) => {
  const {
    timeslot = null,
    isActive = false,
    onClick
  } = props

  const handleClick = React.useCallback(
    onClick(timeslot),
    [onClick, timeslot]
  )

  if (!timeslot) { return null }

  return (
    <button
      className={b('', { active: isActive, open: timeslot.isOpen, closed: !timeslot.isOpen })}
      onClick={handleClick}
      disabled={!timeslot.isOpen}
    >
      <TimeslotLabel
        start={timeslot.deliveryStartDate}
        end={timeslot.deliveryEndDate}
      />
    </button>
  )
}
Timeslot.propTypes = {
  timeslot: PropTypes.shape({
    isOpen: PropTypes.bool,
    deliveryStartDate: PropTypes.string,
    deliveryEndDate: PropTypes.string
  }),
  isActive: PropTypes.bool,
  onClick: PropTypes.func.isRequired
}

export default Timeslot
