import React from 'react'
import { block } from 'utils/classnames'
import PropTypes from 'prop-types'

import { getMarketingTag } from 'utils/publications'

import './Ribbon.sass'

export const MARKETING_PREFIX = 'marketing_'

/**
 * RibbonTag component - Search for one marketing tag, eventually rendering a span with the tag name & optional icon
 * @param { string } className - the base classname
 * @param { object[] } tags - The array of tag objects
 * @returns { Component|null } A span element with an Icon & the tag name or null
 */
export const Ribbon = ({ className = 'ribbon', tags }) => {
  const ribbonTag = getMarketingTag(tags)

  if (!ribbonTag) return null

  const ribbonTagStyle = {
    backgroundColor: ribbonTag.backgroundColor,
    color: ribbonTag.textColor,
    borderColor: ribbonTag.borderColor,
    borderRadius: ribbonTag.borderRadius + 'px'
  }

  return (
    <span
      style={ribbonTagStyle}
      className={`${block('ribbon')} ${className}`.trim()}
    >
      {ribbonTag.name}
    </span>
  )
}

Ribbon.propTypes = {
  className: PropTypes.string,
  tags: PropTypes.array
}

export default Ribbon
