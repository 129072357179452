import { useEffect } from 'react'
import { block } from 'utils/classnames'

import './LoadingPage.sass'

const b = block.with('loading-page')

const LoadingPage = ({ withIcon = false, withSpinner = false }) => {
  useEffect(() => {
    document.body.style.overflow = 'hidden'
    return () => {
      document.body.style.overflow = 'scroll'
    }
  }, [])
  return (
    <div className={b()}>
      {withIcon && <i className={b('logo')} />}
      {withSpinner && <i className={b('loading-spinner')} />}
    </div>
  )
}
export default LoadingPage
