import PropTypes from 'prop-types'
import React from 'react'

import { block } from 'utils/classnames'

import './AddressTooltip.sass'

import * as device from 'utils/device'

const b = block.with('address-tooltip')

export const AddressTooltip = (props = {}) => {
  const {
    show = false,
    className = ''
  } = props

  if (!show) { return null }

  if (show && (device.default.isMobile() || device.default.isTablet())) { window.scroll(0, 0) }

  return (
    <div tabIndex={-1} className={`${className} ${b()}`.trim()}>
      <h4 className={b('title')}>On vous livre où ?</h4>
      <p className={b('body')}>
        Indiquez votre adresse pour vérifier la disponibilité des produits.
      </p>
    </div>
  )
}

AddressTooltip.propTypes = {
  className: PropTypes.string,
  show: PropTypes.bool
}

export default AddressTooltip
