import moment from 'moment'
import PropTypes from 'prop-types'
import React from 'react'

import { block } from 'utils/classnames'

import './DeliveryTrackingButton.sass'

const b = block.with('delivery-tracking-button')

export const DeliveryTrackingButton = props => {
  const {
    isActive = false,
    etaLive = null,
    onClick
  } = props

  if (!isActive) {
    return null
  }

  let eta = null
  if (etaLive && moment(etaLive).isValid()) {
    const now = moment()
    eta = moment(etaLive).diff(now, 'minutes', true)
    eta = Math.round(eta)
    eta = eta < 60 ? eta >= 0 ? eta : null : null
  }

  return (
    <button
      className={b('', { eta: eta !== null })}
      onClick={onClick}
    >
      {
        eta !== null
          ? (
            <strong className={b('eta')}>
              {eta}
            </strong>
          )
          : null
      }
      {
        eta !== null
          ? 'MIN'
          : 'Suivre mon livreur'
      }
    </button>
  )
}
DeliveryTrackingButton.propTypes = {
  isActive: PropTypes.bool,
  etaLive: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  onClick: PropTypes.func
}
export default DeliveryTrackingButton
