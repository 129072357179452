import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import React from 'react'

import { animated, useSpring } from 'react-spring'

import { getCartProductsWithoutCutlery, getTotalCartQuantity, getTotalDueAmount } from 'reducers'

import { block } from 'utils/classnames'
import { pushFunnelEvent } from 'utils/googletagmanager'

import './CartButton.sass'

const b = block.with('cart-button')

export const CartButton = props => {
  const {
    quantity = 0,
    cartProductsWithoutCutlery,
    totalDue,
    onClick
  } = props

  const style = useSpring({
    to: async (next, cancel) => {
      await next({
        opacity: 0,
        filter: 'blur(3px)',
        transform: 'scale(0, 0)'
      })
      await next({
        opacity: Math.min(quantity, 1),
        filter: 'blur(0px)',
        transform: 'scale(1, 1)'
      })
    },
    from: {
      opacity: Math.min(quantity, 1),
      filter: 'blur(0px)',
      transform: 'scale(1, 1)'
    },
    config: {
      duration: 150
    }
  })

  const onClickOpenCart = () => {
    pushFunnelEvent('OpenCart', {
      items: cartProductsWithoutCutlery,
      quantity,
      totalDue
    })
    onClick()
  }

  return (
    <button
      className={b('', { quantity })}
      aria-label='Ouvrir le panier'
      onClick={onClickOpenCart}
    >
      <animated.span
        className={
          b(
            'quantity',
            { [String(quantity).length + 'd']: true })
        }
        style={style}
      >
        {quantity}
      </animated.span>
    </button>
  )
}

CartButton.propTypes = {
  quantity: PropTypes.number,
  cartProductsWithoutCutlery: PropTypes.array,
  totalDue: PropTypes.number,
  onClick: PropTypes.func.isRequired
}

export const ConnectedCartButton = props => {
  const quantity = useSelector(getTotalCartQuantity)
  const cartProductsWithoutCutlery = useSelector(getCartProductsWithoutCutlery)
  const totalDue = useSelector(getTotalDueAmount)

  return (
    <CartButton
      {...props}
      quantity={quantity}
      cartProductsWithoutCutlery={cartProductsWithoutCutlery}
      totalDue={totalDue}
    />
  )
}

export default CartButton
