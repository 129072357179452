import React, { useCallback, useMemo } from 'react'
import { shallowEqual, useSelector } from 'react-redux'

import PanelContent from 'components/Common/Panel/PanelContent'
import Timeslot from 'components/Common/Delivery/Timeslot'

import { block } from 'utils/classnames'
import { pushFunnelEvent } from 'utils/googletagmanager'
import { getFormattedCartAddress } from 'reducers'

import './BasketShiftEdit.sass'

const b = block.with('basket-shift')

export const BasketShiftEdit = (props) => {
  const {
    shifts,
    selectedTimeslotId,
    selectedShiftId,
    onSelectTimeslot,
    onBack
  } = props
  const selectedAddress = useSelector(getFormattedCartAddress, shallowEqual)

  const activeShift = useMemo(() => (
    shifts.find(shift => shift.idShift === selectedShiftId)
  ), [shifts, selectedShiftId])

  const openTimeslots = useMemo(() => (
    activeShift && Array.isArray(activeShift.timeslots)
      ? activeShift.timeslots.filter(timeslot => timeslot.isOpen)
      : []
  ), [activeShift])

  const handleClick = useCallback(
    (timeslot) => {
      onSelectTimeslot(activeShift.idShift, timeslot?.idShiftTimeslot, selectedAddress)
      pushFunnelEvent('SelectedTimeslot', timeslot)
      onBack && onBack()
    },
    [activeShift]
  )

  return (
    <PanelContent
      onBack={onBack}
      title='Mon créneau horaire'
    >
      <div className={b('')}>
        <h3 className={b('title')}>Choisissez votre créneau de livraison</h3>
        {
          openTimeslots
            .map(timeslot => (
              <button
                key={timeslot.idShiftTimeslot}
                className={b('button', { isActive: timeslot.idShiftTimeslot === selectedTimeslotId })}
                onClick={() => handleClick(timeslot)}
                disabled={!timeslot.isOpen}
              >
                <Timeslot
                  start={timeslot.deliveryStartDate}
                  end={timeslot.deliveryEndDate}
                  withDate
                />
              </button>
            ))
        }
      </div>
    </PanelContent>
  )
}

export default BasketShiftEdit
