import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import React, { useEffect } from 'react'

import {
  getPaymentMethod,
  getPayableAmount,
  getTotalAmount
} from 'reducers'

import { block } from 'utils/classnames'
import { eur } from 'utils/numeral'
import { pushFunnelEvent } from 'utils/googletagmanager'

import './SodexoBadge.sass'

const b = block.with('sodexo-badge-selector')

export const SodexoBadge = props => {
  const {
    companyName,
    isActive = true,
    isSelected = false,
    payableAmount = 0,
    onClick
  } = props

  useEffect(() => {
    isActive && isSelected && pushFunnelEvent('PaymentMethodSelected', {
      paymentMethod: 'sodexobadge',
      provider: 'sodexo',
      amount: payableAmount,
      companyName
    })
  }, [isActive, isSelected, payableAmount])

  if (!isActive) {
    isSelected && onClick && onClick()

    return null
  }

  return (
    <button
      type='button'
      onClick={onClick}
      className={b('', { active: isSelected })}
    >
      <i className={b('icon')} />
      <span className={b('text')}>
        Badge {companyName || 'Entreprise'}
        <br />
        <small>
          Votre <strong>participation employeur</strong> sera appliquée lors du paiement et détaillée sur <strong>SoHappy</strong>.
          Vous serez facturé par Sodexo.
        </small>
      </span>
      {
        isSelected
          ? (
            <span className={b('amount')}>
              {eur(payableAmount)}
            </span>
          )
          : null
      }
    </button>
  )
}

SodexoBadge.propTypes = {
  companyName: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  isActive: PropTypes.bool,
  isSelected: PropTypes.bool,
  payableAmount: PropTypes.number
}

export const ConnectedSodexoBadge = connect(
  (state, props) => {
    const paymentMethod = getPaymentMethod(state, props) || {}
    return {
      ...paymentMethod,
      payableAmount: getTotalAmount(state, props),
      companyName: 'FoodChéri',
      isActive: paymentMethod.isActive && getPayableAmount(state, props) > 0
    }
  }
)(SodexoBadge)

export default SodexoBadge
