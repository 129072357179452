import PropTypes from 'prop-types'
import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import CreditCardForm from './CreditCardForm'
import CreditCardSelector from './CreditCardSelector'
import NewCreditCardSelector from './NewCreditCardSelector'
import CreditCardAuthorization from './CreditCardAuthorization'

import { setCheckoutError, setPaymentWaitingAuthorization } from 'actions/home/checkout'
import { getCheckoutError, getCheckoutPaymentWaitingAuthorization } from 'reducers'
import { CREDIT_CARD_ID } from 'config/paymentMethods'

const compareSameCard = (a, b) => {
  if (a === null && b !== null) {
    return false
  }
  if (b === null && a !== null) {
    return false
  }
  return a?.paymentMethodId === b?.paymentMethodId
}

export const CreditCard = props => {
  const {
    stripeClientSecret = '',
    stripePaymentIntentId = '',
    totalDue = 0,
    payableAmount,
    shouldPush = false,
    isSelected = false,
    isActive = true,
    cards = [],
    onAddCreditCard,
    onClick,
    onSubmit,
    defaults = [],
    previousCardLength
  } = props

  const [activeCard, setActiveCard] = useState(() => {
    const defaultCard = defaults.find(({ type }) => type === CREDIT_CARD_ID)?.card
    const activeCard = cards.find(({ paymentMethodId }) => paymentMethodId === defaultCard?.paymentMethodId) || null
    return activeCard
  })

  const checkoutError = useSelector(getCheckoutError)
  const waitingPayment = useSelector(getCheckoutPaymentWaitingAuthorization)
  const dispatch = useDispatch()
  const [paymentIntentId, setPaymentIntentId] = useState('')
  const [authorizationError, setAuthorizationError] = useState(null)
  const [isSubmittedId, setIsSubmittedId] = useState('')
  const [shouldAuthorize, setShouldAuthorize] = useState(waitingPayment)

  const handleClick = (card = null) => {
    paymentIntentId && setPaymentIntentId('')
    authorizationError && setAuthorizationError('')

    // Unselect
    if (!card?.paymentMethodId || compareSameCard(card, activeCard)) {
      isSelected && onClick && onClick()
      activeCard && setActiveCard(null)

      return
    }

    !isSelected && onClick && onClick()
    setActiveCard(card)
  }

  useEffect(() => {
    setShouldAuthorize(waitingPayment)
  }, [waitingPayment])

  const handleAuthorizationSuccess = (id = '') => {
    if (id && id !== paymentIntentId) {
      setPaymentIntentId(id)
      setAuthorizationError(null)
      dispatch(setPaymentWaitingAuthorization(false))
    }
  }

  const handleAuthorizationError = (error) => {
    if (error && error !== authorizationError) {
      setPaymentIntentId('')
      setAuthorizationError(error)
      dispatch(setCheckoutError('Impossible d\'authentifier votre paiement, veuillez rééssayer.'))
      dispatch(setPaymentWaitingAuthorization(false))
    }
  }

  useEffect(() => {
    if (isSubmittedId !== paymentIntentId && paymentIntentId && !authorizationError && !checkoutError) {
      onSubmit && onSubmit()
      setIsSubmittedId(paymentIntentId)
    }
  }, [paymentIntentId, authorizationError])

  useEffect(() => {
    if (Number.isFinite(previousCardLength)) {
      cards[0] &&
        cards.length > previousCardLength &&
        !compareSameCard(cards[0], activeCard) &&
        handleClick(cards[0])
    }
  }, [cards])

  // Automatically unselect if not active or no card is available
  useEffect(() => {
    if (isSelected && !isActive) {
      onClick && onClick()
      return
    }
    if (isSelected && (!Array.isArray(cards) || cards.length === 0)) {
      onClick && onClick()
    }
  }, [isSelected, isActive, cards, onClick])

  const selectedPaymentMethodId = useMemo(() => (
    activeCard?.paymentMethodId
      ? activeCard.paymentMethodId
      : null
  ), [activeCard])

  const Selector = useMemo(() => {
    if (totalDue <= 0) {
      return null
    }
    if (!Array.isArray(cards) || cards.length === 0) {
      return (
        <NewCreditCardSelector
          shouldPush={shouldPush}
          onClick={onAddCreditCard}
        />
      )
    }

    return cards
      .filter(card => !!card.paymentMethodId)
      .map(card => (
        <CreditCardSelector
          key={card.paymentMethodId}
          onClick={handleClick}
          shouldPush={shouldPush}
          card={card}
          isSelected={isSelected && selectedPaymentMethodId === card.paymentMethodId}
          totalDue={payableAmount}
        />
      ))
  })

  return isActive
    ? (
      <>
        {Selector}
        <CreditCardForm
          paymentMethodId={selectedPaymentMethodId}
          shouldSave={activeCard?.shouldSave}
          paymentIntentId={stripePaymentIntentId}
          authorizationError={authorizationError}
        />
        {shouldAuthorize && (
          <CreditCardAuthorization
            stripeClientSecret={stripeClientSecret}
            paymentMethodId={selectedPaymentMethodId}
            onAuthorizationSuccess={handleAuthorizationSuccess}
            onAuthorizationError={handleAuthorizationError}
          />
        )}
      </>
    )
    : null
}
CreditCard.propTypes = {
  cards: PropTypes.arrayOf(
    PropTypes.shape({
      paymentMethodId: PropTypes.string
    })
  ),
  stripeClientSecret: PropTypes.string,
  stripePaymentIntentId: PropTypes.string,
  orderHash: PropTypes.string,
  totalDue: PropTypes.number,
  payableAmount: PropTypes.number,
  shouldPush: PropTypes.bool,
  isSelected: PropTypes.bool,
  isActive: PropTypes.bool,
  onAddCreditCard: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
  onSubmit: PropTypes.func
}
export default CreditCard
