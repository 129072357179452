import { connect } from 'react-redux'
import { signIn, signUp, lostPassword } from 'actions/user'
import { getUserErrors, getSecurityIsLoading } from 'reducers'

import { BasketAuth } from './BasketAuth'

export const ConnectedBasketAuth = connect(
  (state, props) => ({
    errors: getUserErrors(state, props),
    isLoading: getSecurityIsLoading(state, props)
  }),
  dispatch => ({
    signIn: (data) => dispatch(signIn(data, 'Funnel')),
    signUp: (data) => dispatch(signUp(data, 'Funnel')),
    lostPassword: ({ username }) => dispatch(lostPassword(username, true))
  })
)(BasketAuth)

export default ConnectedBasketAuth
