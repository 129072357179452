import { connect } from 'react-redux'

import Event from './Event'

import {
  getActiveEvents,
  getCartDelivery
} from 'reducers'

import {
  loadActiveEvents as onUpdate
} from 'actions/event'

export const ConnectedEvent = connect(
  (state, props) => ({
    areaId: getCartDelivery(state, props)?.idArea,
    activeEvents: getActiveEvents(state, props)
  }),
  { onUpdate }
)(Event)

export default Event
