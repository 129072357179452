import React, { useState, useEffect, useRef } from 'react'
import { connect, useDispatch } from 'react-redux'
import { FormField } from 'components/form'
import { block } from 'utils/classnames'
import { getErrorMessageFromConfig } from 'utils/formValidation/user'
import formConfig from 'config/form/user/signIn'

import { clearUserErrors, signIn } from 'actions/user'
import { getUserErrors, getSecurityIsLoading } from 'reducers'

import './LoginForm.sass'
import { PreventButton } from 'utils/preventButton'

const getErrorMessage = getErrorMessageFromConfig(formConfig)

export const LoginForm = (props) => {
  const {
    onSubmit,
    userData,
    error,
    isLoading = false,
    onClose,
    handlePassword,
    infoText
  } = props

  const className = props.className || 'login-form'
  const initialState = { ...formConfig.defaults, ...userData }
  const dispatch = useDispatch()
  const [data, setData] = useState(initialState)
  const [hasSubmitted, setHasSubmitted] = useState(false)
  const clickedRef = useRef(false)

  useEffect(() => {
    if (hasSubmitted && isLoading === false) {
      setHasSubmitted(false)
      if (!error || Object.keys(error).length === 0) {
        onClose && onClose()
      }
    }
  }, [hasSubmitted, setHasSubmitted, error, onClose, isLoading])

  useEffect(() => {
    dispatch(clearUserErrors())
  }, [])

  const handleChange = name => value => {
    clickedRef.current = false
    setData(data => ({ ...data, [name]: value }))
  }

  const handleSubmit = e => {
    e.preventDefault()
    if (clickedRef.current) return
    clickedRef.current = true
    onSubmit && onSubmit(data)
    setHasSubmitted(true)
  }

  return (
    <div className={block(className)}>
      <h4 className={block(className, 'title')}>
        {
          infoText ||
          (
            <>
              Déjà inscrit ?&nbsp;
              <br />
              Connectez-vous
            </>
          )
        }
      </h4>
      <form id='sign-in' className={block(className, 'form')} onSubmit={handleSubmit}>
        {
          Object
            .entries(formConfig.fields)
            .map(([name, props]) => (
              <FormField
                disabled={isLoading}
                key={name}
                name={name}
                error={getErrorMessage(name, error)}
                value={data[name]}
                onChange={handleChange(name)}
                type={props.type}
                placeholder={props.label}
              />
            ))
        }
        {
          handlePassword
            ? (
              <button
                type='button'
                className={block(className, 'lost-password')}
                onClick={handlePassword}
              >
                Mot de passe oublié ?
              </button>
            )
            : null
        }
        {
          error && getErrorMessage('global', error)
            ? (
              <span className={block(className, 'error')}>
                {getErrorMessage('global', error)}
              </span>
            )
            : null
        }
        <div className={block(className, 'container-submit', { loading: isLoading })}>
          <PreventButton
            isForm
            className={block(className, 'button-submit', { loading: isLoading })}
            disabled={isLoading || clickedRef.current}
          >
            Se connecter
          </PreventButton>
        </div>
      </form>
    </div>
  )
}

export const ConnectedLoginForm = connect(
  (state, props) => ({
    error: getUserErrors(state, props),
    isLoading: getSecurityIsLoading(state, props)
  }),
  dispatch => ({
    onSubmit: (data) => dispatch(signIn(data, 'UserModal'))
  })
)(LoginForm)

export default ConnectedLoginForm
