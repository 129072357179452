import PropTypes from 'prop-types'
import React, { useState, useMemo, useEffect } from 'react'
import { shallowEqual, useSelector } from 'react-redux'

import DeliveryOverlay from '../DeliveryOverlay'
import TimeslotInput from './TimeslotInput'
import TimeslotSelect from './TimeslotSelect'
import TimeslotTooltip from './TimeslotTooltip'

import { block } from 'utils/classnames'
import { pushHomepageEvent } from 'utils/googletagmanager'
import { useKeyDown } from 'hooks/dom'
import { getFormattedCartAddress } from 'reducers'

import './TimeslotSelector.sass'

const b = block.with('timeslot-selector')

export const TimeslotSelector = props => {
  const {
    shifts = [],
    forcedMealSetting = null,
    showTooltipNoTimeslot = false,
    dismissTooltip,
    selectedTimeslotId = '',
    onChange,
    disabled = false
  } = props
  const selectedAddress = useSelector(getFormattedCartAddress, shallowEqual)

  const [open, setIsOpen] = useState(false)

  const selected = useMemo(
    () => {
      let selected = null

      if (!selectedTimeslotId || !Array.isArray(shifts)) {
        return selected
      }

      shifts.forEach(shift => {
        if (Array.isArray(shift.timeslots)) {
          const needle = shift.timeslots.find(t => t.idShiftTimeslot === selectedTimeslotId)
          selected = needle && needle.idShiftTimeslot ? needle : selected
        }
      })

      return selected
    },
    [shifts, selectedTimeslotId]
  )

  useEffect(() => {
    if (selected) {
      pushHomepageEvent('SelectedTimeslot', selected, 'Homepage')
    }
  }, [selected])

  const handleOpen = () => {
    showTooltipNoTimeslot && dismissTooltip && dismissTooltip()
    setIsOpen(true)
  }

  const handleClose = () => {
    setIsOpen(false)
  }

  const handleChange = shift => timeslot => () => {
    if (timeslot && timeslot.isOpen) {
      onChange && onChange(shift.idShift, timeslot.idShiftTimeslot, selectedAddress)
      pushHomepageEvent('SelectedTimeslot', timeslot, 'Homepage')
      handleClose()
    }
  }

  useKeyDown(open ? handleClose : null, ['Escape'])

  const isOpen = open || showTooltipNoTimeslot || false

  if (shifts.length === 0) {
    return null
  }
  return (
    <div className={b('', { open: isOpen, disabled })}>
      <DeliveryOverlay
        shouldShow={isOpen}
        onClick={handleClose}
      />
      <TimeslotInput
        selected={selected}
        onClick={isOpen ? handleClose : handleOpen}
        className='timeslot-selector-input'
      />
      <TimeslotSelect
        isOpen={open}
        shifts={shifts}
        forcedMealSetting={forcedMealSetting}
        activeTimeslot={selectedTimeslotId}
        onClick={handleChange}
        className='timeslot-selector-select'
      />
      <TimeslotTooltip
        className='timeslot-selector-tooltip'
        show={showTooltipNoTimeslot}
      />
    </div>
  )
}
TimeslotSelector.propTypes = {
  shifts: PropTypes.array.isRequired,
  selectedTimeslotId: PropTypes.number,
  onChange: PropTypes.func,
  forcedMealSetting: PropTypes.object,
  showTooltipNoTimeslot: PropTypes.bool,
  disabled: PropTypes.bool,
  dismissTooltip: PropTypes.func
}
export default TimeslotSelector
