import braintree from 'braintree-web'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import React, {
  useCallback,
  useEffect,
  useMemo,
  useState
} from 'react'

import {
  useScript
} from 'hooks/script'

import {
  useAlternativeButton
} from 'hooks/checkout'

import {
  getPaymentMethod,
  getPayableAmount
} from 'reducers'

import {
  PAYPAL_BUTTON_SETUP,
  PAYPAL_PAYMENT_SETUP
} from 'config/paymentMethods'

import { PayPalSelector } from './PayPalSelector'

import './PayPal.sass'

export const PayPal = props => {
  const {
    isActive = true,
    isSelected = false,
    accessToken = '',
    vault = '',
    payableAmount = 0,
    onSubmit,
    onClick
  } = props

  const [isLoading, setIsLoading] = useState(false)
  const [isDisabled, setIsDisabled] = useState(!vault && !accessToken)
  const [shouldSubmit, setShouldSubmit] = useState(false)
  const [nonce, setNonce] = useState('')
  const [deviceData, setDeviceData] = useState('')

  const paypalIsReady = useScript(
    'https://www.paypalobjects.com/api/checkout.js',
    {
      async: true,
      'data-version-4': true
    },
    false
  )
  const hasAlternativeButton = useMemo(() => (
    !!(accessToken && isActive && !isDisabled && paypalIsReady && !vault)
  ), [accessToken, isActive, isDisabled, paypalIsReady, vault])

  const buttonContainer = useAlternativeButton(
    'paypal',
    hasAlternativeButton,
    isSelected
  )

  useEffect(() => {
    if (shouldSubmit) {
      onSubmit && onSubmit()
      setShouldSubmit(false)
    }
  }, [shouldSubmit])

  const handleCancel = useCallback(
    () => {
      isSelected && onClick && onClick()
    }
  )

  const handleError = useCallback(
    (e) => {
      console.log(e)
      setIsDisabled(!vault)
      handleCancel()
    }
  )

  const handlePaymentMethodReceived = useCallback(
    data => {
      setNonce(data.nonce)
      !isSelected && onClick && onClick()
      setShouldSubmit(true)
    }
  )

  const renderPayPalButton = useCallback(
    (instance) => {
      if (!buttonContainer) {
        return
      }
      buttonContainer.innerHTML = ''
      window.paypal.Button.render({
        ...PAYPAL_BUTTON_SETUP,
        payment: () => (
          instance
            .createPayment({
              ...PAYPAL_PAYMENT_SETUP,
              amount: payableAmount
            })
        ),
        onAuthorize: (data) => (
          instance
            .tokenizePayment(data)
            .then(handlePaymentMethodReceived)
        ),
        onCancel: handleCancel,
        onError: handleError
      }, buttonContainer.id)
    }
  )

  useEffect(() => {
    setIsLoading(hasAlternativeButton)
    hasAlternativeButton && braintree.client
      .create({ authorization: accessToken })
      .then((client) => {
        braintree
          .dataCollector
          .create({
            client,
            paypal: true
          }, (err, dataCollectorInstance) => {
            !err && setDeviceData(dataCollectorInstance.deviceData)
          })

        return braintree.paypalCheckout.create({ client })
      })
      .then(renderPayPalButton)
      .catch(handleError)
      .finally(() => { setIsLoading(false) })
  }, [hasAlternativeButton])

  return (
    <>
      <PayPalSelector
        isActive={isActive}
        onClick={onClick}
        isLoading={!paypalIsReady || isLoading}
        isDisabled={isDisabled}
        isSelected={isSelected}
        payableAmount={payableAmount}
        vault={vault}
      />
      <input
        type='hidden'
        name='nonce'
        value={isSelected ? nonce : ''}
      />
      <input
        type='hidden'
        name='deviceData'
        value={isSelected ? deviceData : ''}
      />
    </>
  )
}

PayPal.propTypes = {
  isActive: PropTypes.bool,
  isSelected: PropTypes.bool,
  accessToken: PropTypes.string,
  vault: PropTypes.string,
  payableAmount: PropTypes.number,
  onClick: PropTypes.func,
  onSubmit: PropTypes.func
}

export const ConnectedPayPal = connect(
  (state, props) => {
    const paymentMethod = getPaymentMethod(state, props) || {}
    const payableAmount = getPayableAmount(state, props)

    return {
      ...paymentMethod,
      payableAmount,
      isActive: !!paymentMethod.isActive && payableAmount > 0
    }
  }
)(PayPal)

export default PayPal
