import PropTypes from 'prop-types'
import React from 'react'
import ReactPhoneInput from 'react-phone-number-input/mobile'
import fr from 'react-phone-number-input/locale/fr'

import './PhoneInput.sass'

export const PhoneInput = ({
  required = false,
  disabled = false,
  value = '',
  onChange,
  placeholder = '',
  autoComplete = 'tel',
  autoFocus = false
}) => (
  <ReactPhoneInput
    displayInitialValueAsLocalNumber
    placeholder={placeholder}
    required={required}
    disabled={disabled}
    defaultCountry='FR'
    value={value}
    onChange={onChange}
    autoFocus={autoFocus}
    autoComplete={autoComplete}
    smartCaret={false}
    labels={fr}
  />
)

PhoneInput.propTypes = {
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  autoComplete: PropTypes.string,
  autoFocus: PropTypes.bool
}

export default PhoneInput
