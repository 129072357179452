import PropTypes from 'prop-types'
import React, {
  useRef,
  useEffect,
  useMemo,
  useState,
  useCallback
} from 'react'
import { useRouter } from 'next/router'
import { useDebounce } from 'react-use'

import DeliveryOverlay from 'components/DeliveryBar/DeliveryOverlay'
import AddressInput from './AddressInput'
import AddressSelect from './AddressSelect'
import AddressTooltip from './AddressTooltip'
import UndeliverableModal from './UndeliverableModal'

import { block } from 'utils/classnames'
import { getLabelFromAddress, addressTypes, addressTypesOrder } from 'utils/address'
import { pushHomepageEvent } from 'utils/googletagmanager'

import { getAddressIsLoading, getSoHappyAddresses, getUserCompany } from 'reducers'

import './AddressSelector.sass'
import { getPathById } from 'config/router'
import { useSelector } from 'react-redux'

const b = block.with('address-selector')

export const AddressSelector = props => {
  const {
    suggestions = [],
    isLoading = false,
    showTooltipNoAddress = false,
    isOutOfBound = false,
    selectedAddress,
    onInputChange,
    onSelectAddress,
    closeTooltip
  } = props

  const inputElement = useRef(null)

  const userCompany = useSelector(getUserCompany)
  const soHappyAddresses = useSelector(getSoHappyAddresses)
  const isAddressLoading = useSelector(getAddressIsLoading)
  const [inputValue, setInputValue] = useState(getLabelFromAddress(selectedAddress))
  const [showUndeliverableModal, setShowUndeliverableModal] = useState(false)
  const [showSuggestions, setShowSuggestions] = useState(false)
  const [inputHasFocus, setInputHasFocus] = useState(false)
  const [addressResults, setAddressResults] = useState(suggestions)
  const [hasSelectedAddress, setHasSelectedAddress] = useState(true)
  const [filteredSoHappyAddresses, setFilteredSoHappyAddresses] = useState(soHappyAddresses || [])
  const router = useRouter()

  const isEmpty = useMemo(
    () => (
      isLoading === false &&
      inputHasFocus === false &&
      inputValue === '' &&
      showTooltipNoAddress === false &&
      showSuggestions === false
    ),
    [isLoading, inputHasFocus, inputValue, showTooltipNoAddress, showSuggestions]
  )

  useEffect(() => {
    setInputValue(getLabelFromAddress(selectedAddress))
    selectedAddress && pushHomepageEvent('SelectedAddress', selectedAddress)
  }, [selectedAddress])

  useEffect(() => {
    isOutOfBound &&
      setShowUndeliverableModal(state => state === false || state)
  }, [isOutOfBound])

  useEffect(() => {
    const filteredSoHappyAddresses = filterSoHappyAddresses(soHappyAddresses, inputValue)
    setFilteredSoHappyAddresses(filteredSoHappyAddresses)
  }, [inputValue, soHappyAddresses])

  useEffect(() => {
    if (!isAddressLoading && inputHasFocus) {
      const filteredSuggestions = filterAddressSuggestions(suggestions, inputValue)
      const addressResults = filteredSoHappyAddresses.concat(filteredSuggestions)
      const sortedAddressResults = sortAddressResults(addressResults, addressTypesOrder)
      if (sortedAddressResults.length) {
        setAddressResults(sortedAddressResults)
        setShowSuggestions(true)
      } else {
        setAddressResults([])
        setShowSuggestions(false)
      }
    }
  }, [suggestions])

  // update address if SoHappy address
  useEffect(() => {
    if (soHappyAddresses && soHappyAddresses.length > 0 && soHappyAddresses[0].latitude && soHappyAddresses[0].longitude) {
      setHasSelectedAddress(true)
      setShowSuggestions(false)
      setInputValue(getLabelFromAddress(soHappyAddresses[0]))
      onSelectAddress && onSelectAddress(soHappyAddresses[0])
    }
  }, [])

  useDebounce(() => {
    if (inputHasFocus && (inputValue === '' || (inputValue.length > 2 && !hasSelectedAddress && inputValue !== getLabelFromAddress(selectedAddress)))) {
      onInputChange && onInputChange(inputValue)
    }
    setHasSelectedAddress(false)
  }, 500, [inputValue, inputHasFocus])

  const filterSoHappyAddresses = (addresses, inputValue) => {
    return Array.isArray(addresses)
      ? addresses?.filter(({ address }) => address.toLowerCase().includes(inputValue.toLowerCase()))
      : []
  }

  const filterAddressSuggestions = (suggestions, inputValue) => {
    return Array.isArray(suggestions)
      ? suggestions.filter(suggestion => suggestion.type !== addressTypes.FOODCHERI || (inputValue && suggestion?.name?.toLowerCase().includes(inputValue.toLowerCase())))
      : []
  }

  const sortAddressResults = (addresses, addressTypesOrder) => {
    return Array.isArray(addresses) && Array.isArray(addressTypesOrder)
      ? addresses.sort((addressA, addressB) => {
        const indexA = addressTypesOrder.indexOf(addressA.type)
        const indexB = addressTypesOrder.indexOf(addressB.type)
        const hasIndex = index => index > -1
        return hasIndex(indexA)
          ? hasIndex(indexB)
            ? indexA - indexB : -1
          : hasIndex(indexB)
            ? 1 : 0
      })
      : []
  }

  const handleChange = event => {
    event.preventDefault()
    setInputValue(event.target.value)
  }

  const handleFocus = () => {
    setInputHasFocus(true)
    showTooltipNoAddress && closeTooltip && closeTooltip()
  }

  const handleBlur = () => {
    resetInputValue()
    setInputHasFocus(false)
  }

  const handleClear = () => {
    setInputValue('')
    focusInput()
  }

  const focusInput = useCallback(
    () => inputElement.current && inputElement.current.focus(),
    [inputElement.current]
  )

  const handleOverlayClick = () => {
    resetInputValue()
    setInputHasFocus(false)
    showUndeliverableModal && handleCloseUndeliverableModal()
    showTooltipNoAddress && closeTooltip && closeTooltip()
  }

  const resetInputValue = () => {
    setInputValue(getLabelFromAddress(selectedAddress))
    setShowSuggestions(false)
  }

  const handleSelect = useCallback(
    (address) => () => {
      setHasSelectedAddress(true)
      setShowSuggestions(false)
      setInputValue(getLabelFromAddress(address))
      onSelectAddress && onSelectAddress(address)
      if (address.type === addressTypes.B2B && userCompany) {
        const params = getPathById('b2b-panel')
        router.push(params, params, { shallow: true })
      }
    }, [userCompany]
  )

  const handleCloseUndeliverableModal = () => {
    setShowUndeliverableModal(false)
    handleClear()
    setShowSuggestions(true)
  }

  return (
    <div className={b('', { open: showSuggestions || showTooltipNoAddress || showUndeliverableModal })}>
      <DeliveryOverlay
        shouldShow={showSuggestions || showTooltipNoAddress || showUndeliverableModal}
        onClick={handleOverlayClick}
      />
      <AddressInput
        className={b('input')}
        inputRef={inputElement}
        value={inputValue}
        onChange={handleChange}
        onBlur={showSuggestions ? undefined : handleBlur}
        onFocus={handleFocus}
        onClear={handleClear}
        hasFocus={inputHasFocus}
        isEmpty={isEmpty}
      />
      <AddressSelect
        className={b('select')}
        isOpen={showSuggestions}
        isLoading={isLoading}
        suggestions={addressResults}
        onClick={handleSelect}
      />
      <AddressTooltip
        className={b('tooltip')}
        show={showTooltipNoAddress && showSuggestions === false}
      />
      <UndeliverableModal
        className={b('out-of-bound')}
        isOpen={showUndeliverableModal}
        onClose={handleCloseUndeliverableModal}
      />
    </div>
  )
}

AddressSelector.propTypes = {
  isLoading: PropTypes.bool,
  isOutOfBound: PropTypes.bool,
  hasB2BOffers: PropTypes.bool,
  selectedAddress: PropTypes.object,
  suggestions: PropTypes.arrayOf(
    PropTypes.shape({
      address: PropTypes.string,
      name: PropTypes.string,
      type: PropTypes.string
    })
  ),
  onSelectAddress: PropTypes.func,
  onInputChange: PropTypes.func,
  onInputFocus: PropTypes.func,
  closeTooltip: PropTypes.func
}

export default AddressSelector
