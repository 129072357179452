import PropTypes from 'prop-types'
import React, { useCallback, useState } from 'react'
import { Picture } from 'react-responsive-picture'

import { getImageSrcSet, placeholderSrcSet } from 'utils/productPicture'

export const ImagePlaceholder = props => (
  <Picture sources={placeholderSrcSet(props.parent)} />
)

ImagePlaceholder.propTypes = {
  parent: PropTypes.string
}

export const Image = props => {
  const {
    publicId,
    parent,
    onClick,
    altText,
    isProductPage = false
  } = props
  const [isReady, setIsReady] = useState(false)
  const setReady = useCallback(() => {
    setIsReady(true)
  }, [])

  return (
    <>
      <Picture
        style={{ display: isReady || isProductPage ? 'block' : 'none' }}
        onLoad={setReady}
        sources={getImageSrcSet({ publicId, crop: 'fill', quality: 'auto', fetchFormat: 'auto', component: parent })}
        onClick={onClick}
        alt={altText}
      />
      {
        isReady
          ? null
          : <ImagePlaceholder parent={parent} />
      }
    </>
  )
}
Image.propTypes = {
  publicId: PropTypes.string,
  parent: PropTypes.string,
  onClick: PropTypes.func
}
export default Image
