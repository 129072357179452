import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import React, { useEffect } from 'react'

import {
  getPaymentMethod,
  getPayableAmount
} from 'reducers'

import { block } from 'utils/classnames'
import { eur } from 'utils/numeral'
import { pushFunnelEvent } from 'utils/googletagmanager'

import { EDENRED_PAYMENT_LIMIT } from 'config/paymentMethods'

import './Edenred.sass'

const b = block.with('edenred-selector')

export const Edenred = props => {
  const {
    isActive = false,
    isDisabled = false,
    isSelected = false,
    payableAmount = 0,
    onClick
  } = props

  useEffect(() => {
    !isDisabled && isActive && isSelected && pushFunnelEvent('PaymentMethodSelected', {
      paymentMethod: 'edenred',
      provider: 'edenred',
      amount: payableAmount
    })
  }, [isDisabled, isActive, isSelected, payableAmount])

  if (!isActive) {
    isSelected && onClick && onClick()

    return null
  }

  if (isDisabled) {
    isSelected && onClick && onClick()
  }

  return (
    <button
      type='button'
      onClick={onClick}
      className={b('', { active: isSelected })}
      disabled={isDisabled}
    >
      <i className={b('icon')} />
      <span className={b('text')}>
        Carte Edenred
        <br />
        <small>
          {
            isDisabled
              ? payableAmount > EDENRED_PAYMENT_LIMIT
                ? `Le paiement par carte Ticket Restaurant Edenred n'est pas disponible pour les commandes supérieures à ${EDENRED_PAYMENT_LIMIT} euros.`
                : 'Le solde de votre carte Ticket Restaurant Edenred est insuffisant.'
              : null
          }
        </small>
      </span>
      {
        isSelected
          ? (
            <span className={b('amount')}>
              {eur(payableAmount)}
            </span>
          )
          : null
      }
    </button>
  )
}

Edenred.propTypes = {
  onClick: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool,
  isActive: PropTypes.bool,
  isSelected: PropTypes.bool,
  payableAmount: PropTypes.number,
  paymentLimit: PropTypes.number
}

export const ConnectedEdenred = connect(
  (state, props) => {
    const paymentMethod = getPaymentMethod(state, props) || {}
    const payableAmount = getPayableAmount(state, props)
    const isActive = !!paymentMethod.isActive && payableAmount > 0
    const isDisabled = typeof paymentMethod.availableFunds === 'number' && payableAmount > paymentMethod.availableFunds

    return {
      ...paymentMethod,
      payableAmount: getPayableAmount(state, props),
      isActive,
      isDisabled
    }
  }
)(Edenred)

export default Edenred
