import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import { block } from 'utils/classnames'
import { pushFunnelEvent } from 'utils/googletagmanager'

import MultiStepPanel from 'components/Common/Panel/MultiStepPanel'

import './BasketDelivery.sass'

import BasketAuth from 'components/Basket/BasketAuth'
import DeliveryRecap from './DeliveryRecap'
import BasketShiftEdit from './BasketShiftEdit'
import BasketDeliveryComment from './BasketDeliveryComment'
import BasketCustomerEdit from './BasketCustomerEdit'

const b = block.with('basket-delivery')

const views = [
  'basket-auth',
  'basket-delivery',
  'basket-delivery-timeslot',
  'basket-delivery-drop-off',
  'basket-delivery-customer'
]

export const BasketDelivery = props => {
  const {
    isLoading,
    address,
    timeslot,
    user,
    shifts,
    selectedShiftId,
    onSelectTimeslot,
    areaId,
    dropOff,
    dropOffComment,
    defaultDropOff,
    defaultDropOffComment,
    dropOffForced,
    dropOffCommentRequired,
    mealSetting,
    onChangeDropOff,
    onNext,
    onBack
  } = props

  const [view, setView] = useState(
    user?.id ? 'basket-delivery' : 'basket-auth'
  )
  const lastView = useRef(null)
  const reverse = useMemo(() => {
    const r = views.indexOf(view) < views.indexOf(lastView.current)
    lastView.current = view
    return r
  }, [view])
  const selectedTimeslotId = useMemo(() => (
    timeslot && timeslot.idShiftTimeslot
  ), [timeslot])

  const handleBasketAuth = useCallback(
    () => {
      setView('basket-auth')
    },
    []
  )
  const handleEditTimeslot = useCallback(
    () => {
      setView('basket-delivery-timeslot')
    },
    []
  )
  const handleEditDropOff = useCallback(
    () => {
      setView('basket-delivery-drop-off')
    },
    []
  )
  const handleEditCustomer = useCallback(
    () => {
      setView('basket-delivery-customer')
    },
    []
  )
  const handleDeliveryRecap = useCallback(
    () => {
      setView('basket-delivery')
    },
    []
  )

  useEffect(() => {
    if (view === 'basket-auth' && user?.id) {
      handleDeliveryRecap()
    } else if (view !== 'basket-auth' && !user?.id) {
      handleBasketAuth()
    }
  }, [view, user])

  const handleNext = useCallback(() => {
    pushFunnelEvent('DeliveryInfo', {
      address,
      user,
      timeslot,
      dropOff: {
        type: dropOff,
        comment: dropOffComment
      }
    })
    const deliveryDetails = {
      idShiftTimeslot: timeslot?.idShiftTimeslot,
      idArea: areaId,
      address: address.address,
      addressName: address.name,
      latitude: address.latitude,
      longitude: address.longitude,
      firstName: user.firstName,
      lastName: user.lastName,
      phone: user.phone,
      dropOff: defaultDropOff || dropOff,
      dropOffComment: defaultDropOffComment || dropOffComment
    }
    onNext && onNext(deliveryDetails)
  }, [address, areaId, user, timeslot, dropOff, dropOffComment])

  return (
    <MultiStepPanel
      active={view}
      className={b('panel', { view })}
      reverse={reverse}
    >
      <div className={b('', { auth: true })} id='basket-auth'>
        <BasketAuth onBack={onBack} />
      </div>
      <DeliveryRecap
        id='basket-delivery'
        user={user}
        address={address}
        timeslot={timeslot}
        areaId={areaId}
        dropOff={dropOff}
        dropOffComment={dropOffComment}
        defaultDropOff={defaultDropOff}
        defaultDropOffComment={defaultDropOffComment}
        mealSetting={mealSetting}
        onBack={onBack}
        onNext={handleNext}
        onEditTimeslot={handleEditTimeslot}
        onEditDropOff={handleEditDropOff}
        onEditCustomer={handleEditCustomer}
        isLoading={isLoading}
        setDropOff={onChangeDropOff}
      />
      <div className={b('', { timeslot: true })} id='basket-delivery-timeslot'>
        <BasketShiftEdit
          shifts={shifts}
          selectedTimeslotId={selectedTimeslotId}
          selectedShiftId={selectedShiftId}
          onSelectTimeslot={onSelectTimeslot}
          onBack={handleDeliveryRecap}
        />
      </div>
      <div className={b('', { dropoff: true })} id='basket-delivery-drop-off'>
        <BasketDeliveryComment
          areaId={areaId}
          address={address}
          dropOff={dropOff}
          dropOffForced={dropOffForced}
          dropOffCommentRequired={dropOffCommentRequired}
          dropOffComment={dropOffComment}
          mealSetting={mealSetting}
          onChange={onChangeDropOff}
          onBack={handleDeliveryRecap}
        />
      </div>
      <div className={b('', { customer: true })} id='basket-delivery-customer'>
        <BasketCustomerEdit onBack={handleDeliveryRecap} />
      </div>
    </MultiStepPanel>
  )
}

export default BasketDelivery
