import { useSelector, useDispatch } from 'react-redux'
import React, { useEffect, useRef, useCallback, useState } from 'react'

import MultiStepPanel from 'components/Common/Panel/MultiStepPanel'

import { reloadCart, updateCartDelivery } from 'actions/home/cart'
import { clearCheckoutOrderHash, clearCheckoutLoading } from 'actions/home/checkout'

import { useRouterLink } from 'hooks/router'

import {
  getTotalCartQuantity
} from 'reducers'

import { getRouteById } from 'config/router'
import { block } from 'utils/classnames'

import './BasketPanel.sass'

import BasketContent from 'components/Basket/BasketContent'
import BasketDelivery from 'components/Basket/BasketDelivery'
import BasketPayment from 'components/Basket/BasketPayment'
import BasketConfirm from 'components/Basket/BasketConfirm'

const b = block.with('basket-panel')

const cartRoute = getRouteById('cart')
const params = {
  cart: {
    pathStep: ' ',
    step: 'cart',
    sort: 0
  },
  delivery: {
    pathStep: 'livraison',
    step: 'delivery',
    sort: 1
  },
  payment: {
    pathStep: 'paiement',
    step: 'payment',
    sort: 2
  },
  confirm: {
    pathStep: 'confirmation',
    step: 'confirm',
    sort: 3
  }
}

export const BasketPanel = (props) => {
  const {
    onClose,
    productId = null
  } = props

  const dispatch = useDispatch()

  const step = (
    props.step &&
    Object.values(params)
      .find(param => param.pathStep === props.step)?.step
  ) || params.cart.step

  const lastIndex = useRef(0)

  const goToCart = useRouterLink(cartRoute, { step: params.cart.pathStep }, false, false, productId)
  const goToDelivery = useRouterLink(cartRoute, { step: params.delivery.pathStep }, false, false, productId)
  const goToPaymentPanel = useRouterLink(cartRoute, { step: params.payment.pathStep }, false, false, productId)
  const goToConfirm = useRouterLink(cartRoute, { step: params.confirm.pathStep }, false, false, productId)
  const [fromPanel, setFromPanel] = useState(false)

  const goToPayment = useCallback(async deliveryDetails => {
    await dispatch(updateCartDelivery(deliveryDetails))
    await dispatch(reloadCart({ loadCartPayment: true, withPaymentMethods: true, onSuccess: goToPaymentPanel }))
    setFromPanel(true) // allow to determine if the payment panel has been instanciated from a refresh page or the panel
  }, [])

  const basketQuantity = useSelector(getTotalCartQuantity)

  useEffect(() => {
    return () => {
      dispatch(clearCheckoutOrderHash())
      dispatch(clearCheckoutLoading())
    }
  }, [])

  useEffect(() => {
    ![params.confirm.step, params.cart.step].includes(step) &&
      basketQuantity === 0 &&
      goToCart()
  }, [step, basketQuantity])

  const reverse = React.useMemo(() => {
    const last = lastIndex.current
    lastIndex.current = params[step].sort

    return last > params[step].sort
  }, [step])

  return (
    <>
      <MultiStepPanel
        active={step}
        className={b('', { step })}
        reverse={reverse}
      >
        <BasketContent
          id={params.cart.step}
          onNextPanel={goToDelivery}
          onBack={onClose}
        />
        <BasketDelivery
          id={params.delivery.step}
          onBack={goToCart}
          onNext={goToPayment}
        />
        <BasketPayment
          id={params.payment.step}
          onBack={goToDelivery}
          onNext={goToConfirm}
          fromPanel={fromPanel}
        />
        <BasketConfirm
          productId={productId}
          id={params.confirm.step}
          onBack={onClose}
          onNext={goToConfirm}
        />
      </MultiStepPanel>
    </>
  )
}

export default BasketPanel
