import PropTypes from 'prop-types'
import React, { useMemo } from 'react'

import { EventBanner } from './EventBanner'
import { EventModal } from './EventModal'

import { useInterval } from 'hooks/timeout'

export const Event = props => {
  const {
    areaId,
    onUpdate,
    activeEvents = null
  } = props

  const handleUpdate = useMemo(() => {
    if (!onUpdate || !areaId) {
      return null
    }
    return () => onUpdate(areaId)
  }, [onUpdate, areaId])

  useInterval(handleUpdate, 60 * 1000, true)

  const bannerEvent = useMemo(() => {
    if (!Array.isArray(activeEvents)) {
      return {}
    }
    return activeEvents
      .find(event => event.displayMode.toLowerCase() === 'banner') || {}
  }, [activeEvents])

  const pageEvent = useMemo(() => {
    if (!Array.isArray(activeEvents)) {
      return {}
    }
    return activeEvents
      .find(event => event.displayMode.toLowerCase() === 'page') || {}
  }, [activeEvents])

  return (
    <>
      <EventBanner {...bannerEvent} />
      <EventModal {...pageEvent} />
    </>
  )
}

Event.propTypes = {
  areaId: PropTypes.number,
  onUpdate: PropTypes.func,
  activeEvents: PropTypes.arrayOf(PropTypes.shape({
    idEvent: PropTypes.number
  }))
}

export default Event
