import { block } from 'utils/classnames'

import './EcoScoreModal.sass'

const b = block.with('ecoscore-modal')

export const EcoScoreModal = ({ onClose, ecoscore }) => {
  return (
    <div className={b('')}>
      <i className={b('icon-close')} onClick={onClose} />
      <div className={b('content')}>
        <div className={b('title')}>On vous présente l'Eco-Score</div>
        <div className={b('info')}>
            L’Eco-Score est un score sur 100 points représenté par <b className={b('info-important')}>une lettre allant de A à E</b>,
            qui reflète l'empreinte environnementale de nos plats en prenant en compte la provenance,
            les modes de production, le transport, les emballages et la saisonnalité.
        </div>
        <span className={b('ecoscore', { ecoscore })} />
        <div className={b('legend')}>Information fournie par Etiquettable duquel nous sommes partenaires depuis nos débuts. </div>
      </div>
    </div>
  )
}

export default EcoScoreModal
