import React from 'react'

import { ConnectedEditForm } from 'components/user/EditForm/EditForm'

import PanelContent from 'components/Common/Panel/PanelContent'

export const BasketCustomerEdit = (props) => {
  const {
    onBack
  } = props

  return (
    <PanelContent
      onBack={onBack}
      title='Mes infos client'
    >
      <ConnectedEditForm
        onBack={onBack}
      />
    </PanelContent>
  )
}

export default BasketCustomerEdit
