import PropTypes from 'prop-types'
import React from 'react'

import { PanelHeader } from './PanelHeader'
import { block } from 'utils/classnames'

import './PanelContent.sass'

const b = block.with('basket-panel-content')

export const BasketPanel = (props) => {
  const {
    title,
    onBack,
    children,
    className = ''
  } = props

  return (
    <>
      <PanelHeader
        title={title}
        onBack={onBack}
      />
      <div className={`${b()} ${className || ''}`.trim()}>
        <div className={b('content')}>
          {children}
        </div>
      </div>
    </>
  )
}
PanelHeader.propTypes = {
  title: PropTypes.string,
  onBack: PropTypes.func,
  children: PropTypes.node
}
export default BasketPanel
