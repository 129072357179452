import { useDispatch, useSelector } from 'react-redux'

import React, { useCallback, useMemo } from 'react'

import {
  getPublicationById
} from 'reducers'

import {
  updateCartProducts
} from 'actions/home/cart'

import OrderItem from 'components/Order/OrderItem'

import { block } from 'utils/classnames'
import { eur } from 'utils/numeral'
import { pushFunnelEvent } from 'utils/googletagmanager'

import './BasketContent.sass'

const b = block.with('basket-content')

export const BasketItem = ({ publicationId, quantity, isLast, offer }) => {
  const dispatch = useDispatch()
  const product = useSelector(state => getPublicationById(state, { publicationId })) || {}
  const { title, picture, price, menuItems } = product

  const totalPrice = useMemo(
    () => eur(price * quantity),
    [price, quantity]
  )

  const handleChange = useCallback((publicationId, newQuantity) => {
    if (newQuantity > quantity) {
      pushFunnelEvent('AddToCart', {
        product,
        previousQuantity: quantity,
        quantity: newQuantity
      })
    }
    if (newQuantity < quantity) {
      pushFunnelEvent('RemoveFromCart', {
        product,
        previousQuantity: quantity,
        quantity: newQuantity
      })
    }
    dispatch(updateCartProducts(publicationId, newQuantity))
  }, [product, quantity])

  return quantity > 0
    ? (
      <OrderItem
        publicationId={publicationId}
        className={b('item')}
        quantity={quantity}
        isLast={isLast}
        name={title}
        image={picture}
        price={totalPrice}
        onChange={handleChange}
        menu={menuItems}
        offer={offer}
      />
    )
    : null
}

export default BasketItem
