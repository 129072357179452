import React from 'react'
import PropTypes from 'prop-types'

import { getDateLabel } from 'utils/datetime'
import { block } from 'utils/classnames'

import './DayInput.sass'

const b = block.with('day-input')

export const DayInput = (props = {}) => {
  const {
    value = null,
    onClick,
    disabled = false,
    className = ''
  } = props

  return (
    <button
      onClick={onClick}
      className={`${b()} ${className}`.trim()}
      disabled={disabled}
    >
      {getDateLabel(value)}
    </button>
  )
}
DayInput.propTypes = {
  value: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  className: PropTypes.string
}

export default DayInput
