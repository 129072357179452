import PropTypes from 'prop-types'
import React from 'react'

export const TextInput = ({
  required = false,
  disabled = false,
  value = false,
  onChange,
  name = '',
  type = 'text',
  autoComplete,
  autoFocus = false,
  placeholder,
  className
}) => (
  <input
    type={type || 'text'}
    name={name}
    required={required}
    disabled={disabled}
    value={value}
    onChange={e => onChange && onChange(e.target.value)}
    autoComplete={autoComplete}
    autoFocus={autoFocus}
    placeholder={placeholder}
    className={className}
  />
)

TextInput.propTypes = {
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  value: PropTypes.string,
  onChange: PropTypes.func,
  name: PropTypes.string,
  type: PropTypes.string,
  autoComplete: PropTypes.string,
  autoFocus: PropTypes.bool
}

export default TextInput
