import PropTypes from 'prop-types'
import React from 'react'

import { block } from 'utils/classnames'

import './SpicyTag.sass'

const b = block.with('spicy-tag')

const spicinessLevels = [
  '',
  'Légèrement épicé',
  'Épicé',
  'Très épicé'
]

export const SpicyTag = props => {
  let {
    spiciness = null
  } = props

  if (!spiciness || spiciness <= 0) {
    return null
  }

  spiciness = Math.min(spiciness, spicinessLevels.length - 1)

  return (
    <span
      title={spicinessLevels[spiciness]}
      className={b('', { spiciness })}
      aria-label={spicinessLevels[spiciness]}
    >
      <i />
      {spicinessLevels[spiciness]}
    </span>
  )
}

SpicyTag.propTypes = {
  spiciness: PropTypes.number
}

export default SpicyTag
