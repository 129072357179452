import React, { useState, useEffect, useRef } from 'react'
import { connect } from 'react-redux'

import { block } from 'utils/classnames'

import { FormField } from 'components/form'
import formConfig from 'config/form/user/editCustomer'
import { getErrorMessageFromConfig } from 'utils/formValidation/user'

import { updateUser } from 'actions/user'
import { getUserErrors, getSecurityIsLoading, getMe } from 'reducers'

import './EditForm.sass'
import { PreventButton } from 'utils/preventButton'

const getErrorMessage = getErrorMessageFromConfig(formConfig)

export const EditForm = (props) => {
  const {
    onBack,
    isLoading,
    error,
    userData,
    onSubmit
  } = props

  const initialState = { ...formConfig.defaults, ...userData }

  const [data, setData] = useState(initialState)
  const [hasSubmitted, setHasSubmitted] = useState(false)
  const clickedRef = useRef(false)

  useEffect(() => {
    if (hasSubmitted && isLoading === false) {
      setHasSubmitted(false)
      if (!error || Object.keys(error).length === 0) {
        onBack && onBack()
      }
    }
  }, [hasSubmitted, setHasSubmitted, error, onBack, isLoading])

  const handleChange = name => value => setData(data => ({ ...data, [name]: value }))

  const handleSubmit = e => {
    e.preventDefault()
    if (clickedRef.current) return
    clickedRef.current = true
    onSubmit && onSubmit(data)
    setHasSubmitted(true)
  }

  return (
    <form onSubmit={handleSubmit} id='edit-customer' className={block('edit-customer-form')}>
      {
        Object
          .entries(formConfig.fields)
          .map(([name, props]) => (
            <FormField
              disabled={isLoading}
              key={name}
              name={name}
              error={getErrorMessage(name, error?.errors)}
              value={data[name]}
              onChange={handleChange(name)}
              type={props.type}
              placeholder={props.label}
            />
          ))
      }
      <PreventButton
        isForm
        className={block('edit-customer-form', 'button-submit', { loading: isLoading })}
        disabled={isLoading || clickedRef.current}
      >
        Valider
      </PreventButton>
    </form>
  )
}

export const ConnectedEditForm = connect(
  (state, props) => ({
    error: getUserErrors(state, props),
    isLoading: getSecurityIsLoading(state, props),
    userData: getMe(state, props)
  }),
  dispatch => ({
    onSubmit: data => dispatch(updateUser(data))
  })
)(EditForm)

export default ConnectedEditForm
