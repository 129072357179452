import { block } from 'utils/classnames'

import './LoyaltyCardLabel.sass'

const b = block.with('loyalty-card-label')

const LoyaltyCardLabel = ({ label }) => {
  return (
    <div className={b('')}>
      <div className={b('arrow')} />
      <div className={b('cardLabel')}>
        <div className={b('count')}>1</div>
        <span className={b('label')}>{label}</span>
      </div>
    </div>
  )
}

export default LoyaltyCardLabel
