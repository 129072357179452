import React, { useState, useEffect, useRef } from 'react'

import { block } from 'utils/classnames'

import './Sticky.sass'

const b = block.with('sticky')

export const useStickiness = () => {
  const [isStucked, setIsStucked] = useState(false)
  const [isObserving, setIsObserving] = useState(false)
  const stickyElement = useRef(null)

  const checkPosition = () => {
    if (!stickyElement.current) {
      return
    }
    let element = stickyElement.current
    let offset = element.offsetTop
    while (element.offsetParent) {
      element = element.offsetParent
      offset += element.offsetTop
    }

    if (window.pageYOffset >= offset) {
      setIsStucked(true)
    } else if (document.body.style.top === '') {
      setIsStucked(false)
    }
  }
  useEffect(() => {
    setIsObserving(true)
  }, [])

  useEffect(() => {
    if (typeof window === 'undefined') {
      return
    }
    window.addEventListener('scroll', checkPosition)
    window.addEventListener('resize', checkPosition)
    return () => {
      window.removeEventListener('scroll', checkPosition)
      window.removeEventListener('resize', checkPosition)
    }
  }, [isObserving])

  return [stickyElement, isStucked]
}

export const Sticky = props => {
  const {
    children,
    className = ''
  } = props

  const [stickyElement, isStucked] = useStickiness()

  return (
    <div
      ref={stickyElement}
      className={`${b('', { stucked: isStucked })} ${className}`.trim()}
    >
      <div className={b('inner', { stucked: isStucked })}>
        {children}
      </div>
    </div>
  )
}

export default Sticky
