import _sortBy from 'lodash/sortBy'

import { url } from 'utils/cloudinary'
import {
  BREAKPOINT_MOBILE_S,
  BREAKPOINT_MOBILE,
  BREAKPOINT_TABLET,
  BREAKPOINT_DESKTOP_XL
} from 'utils/device'
import {
  defaultProductImageId,
  defaultProductImageFile,
  productTypeOrder,
  imageUrlPrefix
} from 'config/productPicture'

const SIZES_CONFIG = {
  ProductCard: [
    {
      width: 318,
      height: 240,
      minWidth: BREAKPOINT_DESKTOP_XL
    },
    {
      width: 258,
      height: 240,
      minWidth: BREAKPOINT_TABLET
    },
    {
      width: 299,
      height: 198,
      minWidth: BREAKPOINT_MOBILE
    },
    {
      width: 369,
      height: 240,
      minWidth: BREAKPOINT_MOBILE_S
    },
    {
      width: 240,
      height: 240,
      minWidth: 0
    }
  ],
  ProductPanel: [
    {
      width: 600,
      height: 400,
      minWidth: 0
    }
  ]
}

export const imageUrl = (productId, filename, size = 0) => {
  if (filename.includes('http')) {
    return filename
  }

  return size ? (
    imageUrlPrefix +
    '/product/' +
    (productId || defaultProductImageId) +
    '/' +
    size +
    '__' +
    (filename || defaultProductImageFile)
  ) : (
    imageUrlPrefix +
    '/product/' +
    (filename ? productId : defaultProductImageId) +
    '/' +
    (filename || defaultProductImageFile)
  )
}

export const absoluteImageUrl = (filename = '', productId = '', size = '') => {
  if (filename.includes('foodcheri') || filename.includes('kfc')) {
    const aSize = size.split('x')
    return url(filename, { width: aSize[0], height: aSize[1], crop: 'fill' })
  } else {
    return imageUrl(productId, filename, size)
  }
}

export const getImageSrcSet = ({ publicId, crop, quality = null, fetchFormat = null, component = 'ProductCard' }) => {
  const config = SIZES_CONFIG[component] && SIZES_CONFIG[component].map((config, index) => {
    return ({
      src: `/images/blank_${config.width}x${config.height}.png`,
      srcSet: `${url(publicId, { width: config.width, height: config.height, crop, quality, fetchFormat })}, ${url(publicId, { width: config.width * 2, height: config.height * 2, crop, quality, fetchFormat })} 2x`,
      media: `(min-width: ${config.minWidth}px)`
    })
  })

  return config
}

export const placeholderSrcSet = (component = 'ProductCard') => {
  const config = SIZES_CONFIG[component] && SIZES_CONFIG[component].map((config, index) => {
    return ({
      srcSet: `/images/blank_${config.width}x${config.height}.png`,
      media: `(min-width: ${config.minWidth}px)`
    })
  })
  return config
}

// eslint-disable-next-line
export const sortPublicationsByProductType = ({ productType = '' } = {}) => {
  const i = productTypeOrder.indexOf(productType.toUpperCase())

  return i === -1 ? productTypeOrder.length : i
}

export const getProductPictures = product => {
  if (!product) {
    return null
  }
  // Return array of images for menu type products
  if (
    Array.isArray(product.menuItems) && product.menuItems.length > 0
  ) {
    return _sortBy(product.menuItems, sortPublicationsByProductType)
      .map(item => item?.picture)
      .filter(Boolean)
  }
  const picture = product.imgUrl || product.picture || null
  return picture
    ? [picture]
    : null
}

export const getMenuItemImage = (menuItems, menuImage) => {
  const image = menuImage

  const images = getProductPictures({ menuItems, picture: menuImage })

  return images && images.length > 0
    ? images[0]
    : image
}
