import React from 'react'
import { block } from 'utils/classnames'

import './Tooltip.sass'

export const Tooltip = props => {
  const {
    children,
    value,
    visible = false,
    Variant = 'div'
  } = props

  return (
    <Variant className={block('tooltip', { visible })}>
      <span className={block('tooltip', 'value')}>{value}</span>
      {children}
    </Variant>
  )
}

export default Tooltip
