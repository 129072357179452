import PropTypes from 'prop-types'
import qs from 'querystring'
import React, { useCallback, useEffect } from 'react'
import { Elements, useStripe } from '@stripe/react-stripe-js'

import { stripePromise } from 'config/stripe'

export const CreditCardAuthorization = props => {
  const {
    stripeClientSecret = '',
    paymentMethodId = '',
    onAuthorizationSuccess,
    onAuthorizationError
  } = props

  const stripe = useStripe()
  const cleanUrlParams = useCallback(
    () => {
      const currentLocation = window?.location
      if (currentLocation && currentLocation.search) {
        let search = qs.parse(currentLocation.search.replace('?', ''))
        search.hash && Reflect.deleteProperty(search, 'hash')
        search.provider && Reflect.deleteProperty(search, 'provider')
        search = qs.stringify(search)
        search = search ? '?' + search : ''
      }
    },
    []
  )

  const confirm = useCallback(
    (result = {}) => {
      if (result.paymentIntent?.id && onAuthorizationSuccess) {
        onAuthorizationSuccess(result.paymentIntent.id)
      }

      result.error &&
        onAuthorizationError &&
        onAuthorizationError(result.error)

      cleanUrlParams()
    },
    []
  )

  const authorize = async () => {
    if (
      paymentMethodId &&
        stripeClientSecret &&
        stripe &&
        stripe.handleCardAction
    ) {
      try {
        const result = await stripe.handleCardAction(stripeClientSecret)
        confirm(result)
      } catch (e) {
        onAuthorizationError && onAuthorizationError(e.message)
      }
    }
  }

  useEffect(() => {
    if (stripeClientSecret && stripe) {
      authorize()
    }
  }, [stripeClientSecret, stripe])

  return null
}
CreditCardAuthorization.propTypes = {
  stripeClientSecret: PropTypes.string,
  paymentMethodId: PropTypes.string,
  onAuthorizationSuccess: PropTypes.func,
  onAuthorizationError: PropTypes.func,
  orderHash: PropTypes.string
}

const StripeAuthorization = props => {
  return (
    <Elements stripe={stripePromise}>
      <CreditCardAuthorization {...props} />
    </Elements>
  )
}

StripeAuthorization.propTypes = {
  stripeClientSecret: PropTypes.string,
  paymentMethodId: PropTypes.string,
  onAuthorizationSuccess: PropTypes.func,
  onAuthorizationError: PropTypes.func,
  orderHash: PropTypes.string,
  stripeClient: PropTypes.object
}
export default StripeAuthorization
