import { connect } from 'react-redux'

import {
  CREDIT_CARD_ID
} from 'config/paymentMethods'

import {
  getPaymentMethod,
  getPayableAmount,
  getMealVoucherAmount,
  getTotalDueAmount,
  getPayableAmountAfterMealVoucher
} from 'reducers'

import CreditCard from './CreditCard'

export { CreditCardForm } from './CreditCardForm'

export const ConnectedCreditCard = connect(
  (state, props) => {
    const paymentMethod = getPaymentMethod(state, { paymentMethodId: CREDIT_CARD_ID }) || {}
    const mealVoucherAmount = getMealVoucherAmount(state, props)
    const payableAmount = getPayableAmountAfterMealVoucher(state, props)
    return {
      ...paymentMethod,
      isActive: paymentMethod.isActive && getPayableAmount(state, props) > 0,
      totalDue: getTotalDueAmount(state, props),
      payableAmount,
      shouldPush: mealVoucherAmount > 0 && payableAmount > 0
    }
  }
)(CreditCard)

export default CreditCard
