import PropTypes from 'prop-types'
import React, { useEffect } from 'react'

import { block } from 'utils/classnames'
import { eur } from 'utils/numeral'
import { pushFunnelEvent } from 'utils/googletagmanager'

import './PayPalSelector.sass'

const b = block.with('paypal-selector')

export const PayPalSelector = props => {
  const {
    isActive = true,
    isLoading = false,
    isDisabled = false,
    isSelected = false,
    vault = '',
    payableAmount = 0,
    onClick
  } = props

  useEffect(() => {
    isDisabled && isSelected && onClick && onClick()
  }, [isDisabled, isSelected, onClick])

  useEffect(() => {
    !isDisabled && isActive && isSelected && pushFunnelEvent('PaymentMethodSelected', {
      paymentMethod: 'paypal',
      provider: 'paypal',
      amount: payableAmount,
      vault
    })
  }, [isDisabled, isActive, isSelected, payableAmount])

  if (!isActive) {
    isSelected && onClick && onClick()

    return null
  }

  return (
    <button
      type='button'
      onClick={onClick}
      disabled={isDisabled}
      className={
        b('', {
          active: isSelected,
          loading: isLoading
        })
      }
    >
      <i className={b('icon')} />
      <span className={b('text')}>
        <span className={b('title')}>
          PayPal&ensp;
          {
            isSelected && vault
              ? (
                <span className={b('vault')}>
                  {vault}
                </span>
              )
              : null
          }
        </span>
        {
          isDisabled
            ? (
              <small className={b('info')}>
                PayPal n'est actuellement pas disponible.
              </small>
            )
            : null
        }
      </span>
      {
        isSelected
          ? (
            <span className={b('amount')}>
              {eur(payableAmount)}
            </span>
          )
          : null
      }
    </button>
  )
}

PayPalSelector.propTypes = {
  isActive: PropTypes.bool,
  isLoading: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isSelected: PropTypes.bool,
  vault: PropTypes.string,
  payableAmount: PropTypes.number,
  onClick: PropTypes.func
}

export default PayPalSelector
