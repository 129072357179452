import moment from 'moment'

import { OPTION_BUILDING, OPTION_RECEPTION_DESK } from 'utils/delivery'

moment.locale('fr')

export const MEAL_SETTING_LUNCH = 'LUNCH'
export const MEAL_SETTING_DINNER = 'DINNER'

export const AREA_DROPOFF_ID = 417

export const DROPOFF_LABEL = {
  default: 'au pied de l\'immeuble',
  area: 'Au stand',
  [OPTION_BUILDING]: {
    default: 'au pied de l\'immeuble',
    [MEAL_SETTING_LUNCH]: 'au pied de l\'immeuble',
    [MEAL_SETTING_DINNER]: 'au pied de l\'immeuble'
  },
  [OPTION_RECEPTION_DESK]: {
    default: 'à l\'accueil ou à ma porte',
    area: 'Au stand',
    [MEAL_SETTING_LUNCH]: 'à l\'accueil ou à ma porte',
    [MEAL_SETTING_DINNER]: 'à la porte'
  }
}

const DropOffLabel = ({ dropOff, mealSetting, lowerCase = false, areaId = null, comment = null }) => {
  let label = ''

  if (!dropOff) {
    label = DROPOFF_LABEL.default
  } else if (areaId && areaId === AREA_DROPOFF_ID) {
    label = DROPOFF_LABEL[dropOff].area ||
      DROPOFF_LABEL[dropOff].default ||
      DROPOFF_LABEL.area ||
      DROPOFF_LABEL.default ||
      ''
  } else if (!mealSetting) {
    label = DROPOFF_LABEL[dropOff].default || DROPOFF_LABEL.default
  } else {
    label = DROPOFF_LABEL[dropOff][mealSetting] ||
      DROPOFF_LABEL[dropOff].default ||
      DROPOFF_LABEL.default ||
      ''
  }

  if (lowerCase) {
    label = label.toLowerCase()
  }

  if (label && comment) {
    label = [label, comment].join(', ')
  }

  return label
}

export default DropOffLabel
