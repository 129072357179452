import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import React from 'react'

import AnimatedModal from 'components/Common/AnimatedModal'
import Multiline from 'components/Common/Multiline'

import { getGlobalError } from 'reducers'
import { clearGlobalError as onClose } from 'actions/page/globalError'

import { block } from 'utils/classnames'

import './GlobalErrorMessage.sass'

const b = block.with('global-error-message')

export const GlobalErrorMessage = props => {
  const {
    error,
    onClose
  } = props

  const handleClose = React.useMemo(
    () => !error?.shouldConfirm ? onClose : undefined,
    [error]
  )

  return (
    <AnimatedModal
      className={b('')}
      shouldShow={!!error}
      onClose={handleClose}
    >
      <div className={b('content')}>
        <main className={b('main')}>
          <h3 className={b('title')}>
            {error?.title}
          </h3>
          <Multiline className={b('body')}>
            {error?.body}
          </Multiline>
          <button onClick={onClose} className={b('cta')}>
            {error?.button}
          </button>
        </main>
        {
          error && handleClose
            ? (
              <button onClick={handleClose} className={b('dismiss')}>
                Fermer
              </button>
            )
            : null
        }
      </div>
    </AnimatedModal>
  )
}
GlobalErrorMessage.propTypes = {
  error: PropTypes.shape({
    title: PropTypes.string,
    body: PropTypes.string,
    button: PropTypes.string,
    shouldConfirm: PropTypes.bool
  }),
  onClose: PropTypes.func
}

export const ConnectedGlobalErrorMessage = connect(
  (state, props) => ({
    error: getGlobalError(state, props)
  }),
  { onClose }
)(GlobalErrorMessage)

export default GlobalErrorMessage
