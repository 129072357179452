import PropTypes from 'prop-types'
import React from 'react'

import { block } from 'utils/classnames'

import './EnvironmentInfo.sass'

const b = block.with('environment-info')

const sourcingPropertiesMinimum = [
  { key: 'frenchIngredientsRate', min: 70 },
  { key: 'certifiedIngredientsRate', min: 70 },
  { key: 'organicIngredientsRate', min: 60 },
  { key: 'seasonalIngredientsRate', min: 80 }
]
const extractEnvironmentInfo = sourcing => {
  if (!sourcing) {
    return null
  }
  return sourcingPropertiesMinimum
    .reduce((environmentInfo, { key, min }) => {
      if (
        !sourcing[key] ||
        isNaN(parseFloat(sourcing[key])) ||
        parseFloat(sourcing[key]) < min
      ) {
        return environmentInfo
      }

      return {
        ...(environmentInfo || {}),
        [key]: parseFloat(sourcing[key])
      }
    }, null)
}

export const EnvironmentInfo = (props) => {
  const {
    sourcing
  } = props

  const environmentInfo = React.useMemo(() => (
    extractEnvironmentInfo(sourcing)
  ), [sourcing])

  if (!environmentInfo) {
    return null
  }
  const envInfoLength = Object.keys(environmentInfo).length
  return (
    <div className={b()}>
      <h5 className={b('title')}>{envInfoLength > 1 ? 'Les + de cette recette' : 'Le + de cette recette'}</h5>
      <ul className={b('items')}>
        {
          environmentInfo.seasonalIngredientsRate
            ? (
              <li key='seasonal' className={b('item', { type: 'seasonal' })}>
                <strong>{environmentInfo.seasonalIngredientsRate}</strong>
                <span>Légumes et<br />fruits de saison</span>
              </li>
            )
            : null
        }
        {
          environmentInfo.organicIngredientsRate
            ? (
              <li key='organic' className={b('item', { type: 'organic' })}>
                <strong>{environmentInfo.organicIngredientsRate}</strong>
                <span>Ingrédients<br />Bio</span>
              </li>
            )
            : null
        }
        {
          environmentInfo.certifiedIngredientsRate
            ? (
              <li key='certified' className={b('item', { type: 'certified' })}>
                <strong>{environmentInfo.certifiedIngredientsRate}</strong>
                <span>Ingrédients<br />Labellisés</span>
              </li>
            )
            : null
        }
        {
          environmentInfo.frenchIngredientsRate
            ? (
              <li key='french' className={b('item', { type: 'french' })}>
                <strong>{environmentInfo.frenchIngredientsRate}</strong>
                <span>Ingrédients<br />Français</span>
              </li>
            )
            : null
        }
      </ul>
    </div>
  )
}
EnvironmentInfo.propTypes = {
  sourcing: PropTypes.shape({
    frenchIngredientsRate: PropTypes.oneOfType([
      PropTypes.number, PropTypes.string
    ]),
    certifiedIngredientsRate: PropTypes.oneOfType([
      PropTypes.number, PropTypes.string
    ]),
    organicIngredientsRate: PropTypes.oneOfType([
      PropTypes.number, PropTypes.string
    ]),
    seasonalIngredientsRate: PropTypes.oneOfType([
      PropTypes.number, PropTypes.string
    ])
  })
}
export default EnvironmentInfo
