import React, { useState } from 'react'
import { useSelector } from 'react-redux'

import { ConnectedAddressSelector as Address } from './AddressSelector'
import { ConnectedDaySelector as Day } from './DaySelector'
import { ConnectedTimeslotSelector as Timeslot } from './TimeslotSelector'
import { ConnectedNoShiftToday as NoShiftToday } from './NoShiftToday'
import SoHappyWarning from '../user/SoHappyWarning'
import { getIsNoShiftTodayModalDisplayed, getMe } from 'reducers'

import { block } from 'utils/classnames'

import './DeliveryBar.sass'
import Router from 'next/router'

const b = block.with('delivery-bar')

export const DeliveryBar = props => {
  const {
    className = '',
    modifiableDelivery = true
  } = props

  const [isLoading, setIsLoading] = useState(false)
  const { isBadgeActive } = useSelector(getMe) || {}
  const isActiveNoShiftTodayModal = useSelector(getIsNoShiftTodayModalDisplayed)

  const _backToHome = async () => {
    setIsLoading(true)
    await Router.push('/')
    setIsLoading(false)
  }

  return (
    <div className={`${b({ isProductPage: !modifiableDelivery })} ${className}`.trim()}>
      {modifiableDelivery ? (
        <>
          <NoShiftToday />
          <Address />
          <Day />
          <Timeslot />
          <SoHappyWarning noShift={isActiveNoShiftTodayModal} isCheckingOut={false} isActive={isBadgeActive === 1} />
        </>
      ) : (
        <button onClick={_backToHome} className={b('text', { isLoading })}>
          <div className={block('navDelivery', 'link')}>
            Retour
          </div>
        </button>
      )}
    </div>
  )
}

export default DeliveryBar
