import PropTypes from 'prop-types'
import React, { useState, useCallback } from 'react'

import { Image } from './Image'
import { ImageCloudinary } from 'components/ImageCloudinary'
import { block } from 'utils/classnames'

import { useTransition, animated } from 'react-spring'

import './Carousel.sass'

export const Carousel = props => {
  const {
    images,
    hasThumbnail = true,
    hasControl = true,
    onClick,
    parent,
    altText,
    isProductPage = false
  } = props

  if (!images && !Array.isArray(images)) {
    return null
  }

  const [index, set] = useState(0)

  const goToIndex = useCallback(
    (index, e) => {
      e.stopPropagation()
      set(state => index)
    },
    []
  )
  const goToNext = useCallback(
    (e) => {
      e.stopPropagation()
      set((index + 1) % images.length)
    },
    [index]
  )
  const goToPrev = useCallback(
    (e) => {
      e.stopPropagation()
      set(index === 0 ? images.length - 1 : index - 1)
    },
    [index]
  )

  const Frames = React.useMemo(
    () => images.map((image, index) =>
      ({ style }) => (
        <animated.div style={{ ...style }} onClick={onClick}>
          <Image isProductPage={isProductPage} parent={parent} publicId={image} altText={altText} />
        </animated.div>
      )
    ),
    // eslint-disable-next-line
    []
  )

  const transitions = useTransition(index, p => p, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 }
  })

  return (
    <>
      <div className={block('simple-carousel')}>
        {
          transitions.map(({ item, props, key }) => {
            const Frame = Frames[item]
            return <Frame key={key} style={props} />
          })
        }
        {
          hasControl ? (
            <>
              <button className={block('simple-carousel', 'btn-prev')} onClick={goToPrev} />
              <button className={block('simple-carousel', 'btn-next')} onClick={goToNext} />
            </>
          ) : null
        }
      </div>
      {
        hasThumbnail ? (
          <ul className={block('simple-carousel', 'thumbnail')}>
            {
              images.map((image, index) => {
                return (
                  <li key={index} onClick={e => goToIndex(index, e)} onMouseOver={e => goToIndex(index, e)}>
                    <ImageCloudinary key={index} publicId={image} />
                  </li>
                )
              })
            }
          </ul>
        ) : null
      }
    </>
  )
}
Carousel.propTypes = {
  images: PropTypes.array,
  hasThumbnail: PropTypes.bool,
  hasControl: PropTypes.bool,
  onClick: PropTypes.func
}
export default Carousel
