import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import React, { useCallback, useEffect, useState } from 'react'

import {
  getPaymentMethod,
  getPayableAmount,
  getPayableAmountWithoutCompanyDiscount
} from 'reducers'

import { block } from 'utils/classnames'
import { eur } from 'utils/numeral'
import { pushFunnelEvent } from 'utils/googletagmanager'

import './MonthlyBilling.sass'

const b = block.with('monthly-billing-selector')

const handleInputClick = e => {
  e.preventDefault && e.preventDefault()
  e.stopPropagation && e.stopPropagation()
}

export const MonthlyBilling = props => {
  const {
    isActive = true,
    isSelected = false,
    payableAmount = 0,
    onClick
  } = props

  const [companyCode, setCompanyCode] = useState('')
  const handleChange = useCallback(
    (e) => {
      const { value } = e.target
      setCompanyCode(value)
    },
    []
  )

  useEffect(() => {
    isActive && isSelected && pushFunnelEvent('PaymentMethodSelected', {
      paymentMethod: 'monthlybilling',
      provider: '',
      amount: payableAmount,
      companyCode
    })
  }, [isActive, isSelected, payableAmount])

  if (!isActive) {
    isSelected && onClick && onClick()

    return null
  }

  return (
    <button
      type='button'
      onClick={onClick}
      className={b('', { active: isSelected })}
    >
      <i className={b('icon')} />
      <span className={b('text')}>
        Paiement en fin de mois
      </span>
      {
        isSelected
          ? (
            <span className={b('amount')}>
              {eur(payableAmount)}
            </span>
          )
          : null
      }
      <span className={b('info')}>
        Le montant de votre commande sera ajouté au total des frais facturés à votre entreprise en fin de mois.
      </span>
      {
        isSelected
          ? (
            <label className={b('input')} htmlFor='companyCode'>
              <input
                type='text'
                aria-label="Numéro d'imputation"
                name='companyCode'
                placeholder="Numéro d'imputation (optionnel)"
                value={companyCode}
                onChange={handleChange}
                onClick={handleInputClick}
              />
            </label>
          )
          : null
      }
    </button>
  )
}

MonthlyBilling.propTypes = {
  onClick: PropTypes.func.isRequired,
  isActive: PropTypes.bool,
  isSelected: PropTypes.bool,
  payableAmount: PropTypes.number
}

export const ConnectedMonthlyBilling = connect(
  (state, props) => {
    const paymentMethod = getPaymentMethod(state, props) || {}
    const payableAmount = getPayableAmount(state, props)
    const payableAmountWithoutCompanyDiscount = getPayableAmountWithoutCompanyDiscount(state, props)

    return {
      ...paymentMethod,
      payableAmount: payableAmountWithoutCompanyDiscount,
      isActive: !!paymentMethod.isActive && payableAmount > 0
    }
  }
)(MonthlyBilling)

export default MonthlyBilling
