import { isEmpty } from 'lodash'
import PropTypes from 'prop-types'
import React from 'react'

import { block } from 'utils/classnames'

import './ProductIngredients.sass'

const b = block.with('product-ingredients')

export const ProductIngredients = ({ productIngredients }) => {
  if (!productIngredients.length) {
    return null
  }
  return (
    <div className={b('')}>
      <h5>Ingrédients</h5>
      <p>
        {
          productIngredients.map(({ label, percentage, allergens = [] }, index) => {
            let displayedLabel = null
            const percentageNumber = parseFloat(percentage)
            // percentage
            if (Number.isFinite(percentageNumber) && percentageNumber >= 1) {
              const parenthesisRegex = /\((.*)\)/
              displayedLabel = parenthesisRegex.test(label)
                ? label.replace(parenthesisRegex, '(' + percentageNumber + '%, $1)')
                : `${label} (${percentageNumber}%)`
            } else {
              displayedLabel = label
            }
            // allergens
            let displayedAllergens = ''

            if (Array.isArray(allergens) && !isEmpty(allergens)) {
              allergens.map((elem, index) => {
                displayedAllergens = displayedAllergens.concat(index === 0 ? '' : ', ', elem)
              })
            }
            if (displayedAllergens !== '') {
              displayedLabel = <span>{displayedLabel} <b>({displayedAllergens})</b></span>
            }
            return (
              <React.Fragment key={index}>
                {displayedLabel}{index < productIngredients.length - 1 ? ', ' : '.'}
              </React.Fragment>
            )
          })
        }
      </p>
    </div>
  )
}
ProductIngredients.propTypes = {
  productIngredients: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      percentage: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
      ])
    })
  )
}
export default ProductIngredients
