import _throttle from 'lodash/throttle'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import React, {
  useCallback,
  useEffect,
  useMemo,
  useState
} from 'react'

import { useTimeout } from 'hooks/timeout'

import { clearCart as onExpire } from 'actions/home/cart'
import { getTotalCartQuantity } from 'reducers'

import { pushFunnelEvent } from 'utils/googletagmanager'

import InactivityWarning from './InactivityWarning'

export const WARNING_TIMEOUT = 4 * 60 * 1000 // 4 minutes
export const EXPIRATION_TIMEOUT = 2 * 60 * 1000 // 2 minutes

export const InactivityWatcher = props => {
  const {
    warningTimeout = WARNING_TIMEOUT,
    expirationTimeout = EXPIRATION_TIMEOUT,
    onExpire,
    shouldWatch,
    quantity
  } = props

  const [status, setStatus] = useState('idle')
  const [isExpired, setIsExpired] = useState(false)

  const handleExpire = useCallback(
    () => {
      pushFunnelEvent('ExpiredCart', {
        quantity
      })
      onExpire && onExpire()
      setIsExpired(true)
      setStatus('expired')
    },
    [onExpire, quantity]
  )

  const displayWarning = useCallback(
    () => {
      setIsExpired(false)
      setStatus('expiring')
    },
    []
  )

  const closeWarning = useCallback(
    () => { setStatus('restart') },
    []
  )

  useEffect(() => {
    !isExpired && setStatus(shouldWatch === true ? 'watching' : 'idle')
  }, [isExpired, shouldWatch])

  const restart = useCallback(
    _throttle(
      () => {
        setStatus(status => status === 'watching' ? 'restart' : status)
      },
      2500
    ),
    []
  )

  useEffect(() => {
    if (status === 'watching') {
      window &&
        window.addEventListener &&
        window.addEventListener('mousemove', restart)
    }
    if (status === 'restart') {
      setStatus('watching')
    }
    return () => {
      window &&
        window.removeEventListener &&
        window.removeEventListener('mousemove', restart)
    }
  }, [status])

  useTimeout(
    status === 'watching' && displayWarning,
    warningTimeout
  )

  useTimeout(
    status === 'expiring' && handleExpire,
    expirationTimeout
  )

  const shouldShow = useMemo(() => (
    ['expiring', 'expired'].includes(status)
  ), [status])

  return (
    <InactivityWarning
      shouldShow={shouldShow}
      isExpired={isExpired}
      onClose={closeWarning}
    />
  )
}
InactivityWatcher.propTypes = {
  onExpire: PropTypes.func,
  shouldWatch: PropTypes.bool,
  warningTimeout: PropTypes.number,
  expirationTimeout: PropTypes.number
}

export const ConnectedInactivityWatcher = connect(
  (state, props) => ({
    shouldWatch: getTotalCartQuantity(state, props) > 0,
    quantity: getTotalCartQuantity(state, props)
  }),
  { onExpire }
)(InactivityWatcher)

export default InactivityWatcher
