import { useDispatch, useSelector } from 'react-redux'
import React, { useMemo } from 'react'

import { block } from 'utils/classnames'

import { hideUnavailableTimeslotModal, updateDeliveryFromShift } from 'actions/home/delivery'

import {
  getAvailableShiftsByActiveDay,
  getCheckoutError,
  getForcedMealSetting, getFormattedCartAddress, getIsUnavailableTimeslotModalDisplayed, getSelectedTimeslotId
} from 'reducers'

import './UnavailableShiftTimeSlotErrorModal.sass'

import UnavailableTimeSlotModal from 'components/DeliveryBar/TimeslotSelector/UnavailableTimeslotModal'
import { setCheckoutError } from 'actions/home/checkout'
import Router from 'next/router'

const b = block.with('unavailable-shift-timeslot-modal')

const UnavailableShiftTimeSlotErrorModal = () => {
  const shifts = useSelector(getAvailableShiftsByActiveDay) || []
  const forcedMealSetting = useSelector(getForcedMealSetting)
  const selectedTimeslotId = useSelector(getSelectedTimeslotId)
  const selectedAddress = useSelector(getFormattedCartAddress)
  const isUnavailableTimeslotModalDisplayed = useSelector(getIsUnavailableTimeslotModalDisplayed)
  const error = useSelector(getCheckoutError)

  const dispatch = useDispatch()

  const _onClose = async () => {
    if (window.location.pathname.includes('paiement')) {
      await Router.push('panier/livraison')
    }
    dispatch(hideUnavailableTimeslotModal())
    if (error) dispatch(setCheckoutError(null))
  }

  const selectedTimeslot = useMemo(
    () => {
      let selected = null

      if (!selectedTimeslotId || !Array.isArray(shifts)) {
        return selected
      }

      shifts.forEach(shift => {
        if (Array.isArray(shift.timeslots)) {
          const needle = shift.timeslots.find(t => t.idShiftTimeslot === selectedTimeslotId)
          selected = needle && needle.idShiftTimeslot ? needle : selected
        }
      })

      return selected
    },
    [shifts, selectedTimeslotId]
  )

  const handleUnavailableTimeslotModalChange = shift => timeslot => () => {
    if (timeslot && timeslot.isOpen) {
      dispatch(updateDeliveryFromShift(shift.idShift, timeslot.idShiftTimeslot, selectedAddress))
      _onClose()
    }
  }

  return (
    <UnavailableTimeSlotModal
      className={b()}
      isOpen={isUnavailableTimeslotModalDisplayed}
      onClose={_onClose}
      shifts={shifts}
      forcedMealSetting={forcedMealSetting}
      activeTimeslot={selectedTimeslotId}
      onClick={handleUnavailableTimeslotModalChange}
      selectedTimeslot={selectedTimeslot}
    />
  )
}

export default UnavailableShiftTimeSlotErrorModal
