import {
  useEffect,
  useRef
} from 'react'

import {
  PAY_BUTTON_CONTAINER_ID
} from 'config/paymentMethods'

import { block } from 'utils/classnames'

export const useAlternativeButton = (
  provider,
  isActive,
  isSelected
) => {
  const buttonContainer = useRef(null)

  // Add a button container specific to this provider
  if (
    typeof window !== 'undefined' &&
      document.getElementById(PAY_BUTTON_CONTAINER_ID) &&
      !buttonContainer.current
  ) {
    const alternativeButtons = document.getElementById(PAY_BUTTON_CONTAINER_ID)
    buttonContainer.current = document.createElement('div')
    buttonContainer.current.id = `${provider}-button-container`
    alternativeButtons.appendChild(buttonContainer.current)
  }

  // Remove the button container on unmount
  useEffect(() => () => {
    const alternativeButtons = document.getElementById(PAY_BUTTON_CONTAINER_ID)
    alternativeButtons && alternativeButtons.classList.remove(`active__${provider}`)

    buttonContainer.current && buttonContainer.current.parentNode && buttonContainer.current.parentNode.removeChild(buttonContainer.current)
    buttonContainer.current = null
  }, [])

  // Modify button container & payment submit container classnames based on current payment method state
  useEffect(() => {
    const alternativeButtons = document.getElementById(PAY_BUTTON_CONTAINER_ID)
    if (typeof window === 'undefined' || !alternativeButtons || !buttonContainer.current) {
      return
    }

    if (isActive && isSelected) {
      alternativeButtons.classList.add(`active__${provider}`)
    } else {
      alternativeButtons.classList.remove(`active__${provider}`)
    }

    buttonContainer.current.className = block(
      provider,
      'submit',
      { active: isActive && isSelected }
    )
  }, [isActive, isSelected])

  return buttonContainer.current
}
