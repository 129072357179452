import { useDispatch } from 'react-redux'
import React from 'react'

import { pushFunnelEvent } from 'utils/googletagmanager'

import CreditCardForm from 'components/Common/CreditCardForm'

export const NewCreditCard = props => {
  const {
    onSubmit,
    onAddCard,
    hasSave = true,
    hasSubmit = true,
    submitLabel = 'Choisir cette carte'
  } = props

  const dispatch = useDispatch()

  const handleSubmit = card => {
    dispatch(onAddCard(card))
    card && pushFunnelEvent('AddCreditCard', {
      brand: card.brand,
      provider: 'stripe',
      shouldSave: card.shouldSave
    })
    onSubmit && onSubmit()
  }
  return (
    <CreditCardForm
      hasSave={hasSave}
      hasSubmit={hasSubmit}
      onSubmit={handleSubmit}
      submitLabel={submitLabel}
    />
  )
}

export default NewCreditCard
