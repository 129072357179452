import PropTypes from 'prop-types'
import React, { useMemo } from 'react'

import { block } from 'utils/classnames'
import { formatNutritionalFacts, formatGlycemicFacts } from 'utils/publications'

import './NutritionalFacts.sass'

const b = block.with('nutritional-facts')

export const NutritionalFacts = (props) => {
  const {
    type = 'macro',
    nutritionalFacts,
    netWeight,
    hasGlycemicFooter = false,
    hideMissingValues = false
  } = props

  const facts = useMemo(
    () => {
      const facts = formatNutritionalFacts(nutritionalFacts, netWeight, type)
      return facts
        ? Object.values(facts)
          .filter(fact => !hideMissingValues || !!fact?.perServing)
          .sort((factA, factB) => type !== 'micro' ? 0 : factA.label < factB.label ? -1 : 1)
        : null
    },
    [nutritionalFacts, netWeight]
  )

  const glycemicFacts = useMemo(
    () => {
      if (!hasGlycemicFooter) {
        return null
      }
      const facts = formatGlycemicFacts(nutritionalFacts)
      return facts
        ? Object.values(facts).filter(Boolean)
        : null
    },
    [nutritionalFacts, hasGlycemicFooter]
  )

  if (!facts || facts.length === 0) {
    return null
  }

  return (
    <div className={b('', { type })}>
      <table>
        <thead>
          <tr>
            <th>Valeurs {type}-nutritionnelles</th>
            {netWeight ? <th>Pour 100g</th> : null}
            <th>Au total</th>
          </tr>
        </thead>
        <tbody>
          {
            facts
              .map(fact => (
                <tr key={fact.id}>
                  <th className={fact.className}>{fact.label}</th>
                  {
                    netWeight
                      ? <td>{fact.perCentGrams || '-'}</td>
                      : null
                  }
                  <td>{fact.perServing || '-'}</td>
                </tr>
              ))
          }
        </tbody>
        {
          glycemicFacts && glycemicFacts.length > 0
            ? (
              <tfoot>
                {
                  glycemicFacts
                    .map(fact => (
                      <tr key={fact.id}>
                        <th
                          className={fact.className}
                          colSpan={netWeight ? 2 : 1}
                        >
                          {fact.label}
                        </th>
                        <td>
                          {fact.value || '-'}
                        </td>
                      </tr>
                    ))
                }
              </tfoot>
            )
            : null
        }
      </table>
    </div>
  )
}
NutritionalFacts.propTypes = {
  type: PropTypes.oneOf(['micro', 'macro']),
  nutritionalFacts: PropTypes.shape({
    calories: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    fat: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    saturatedFattyAcids: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    carbohydrates: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    sugar: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    proteins: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    fibers: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    salt: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    calcium: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    copper: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    iron: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    magnesium: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    manganese: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    phosphorus: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    potassium: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    selenium: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    zinc: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    vitaminA: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    vitaminB: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    vitaminC: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    vitaminD: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    omega6: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    omega3: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    glycemicIndex: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    glycemicLoad: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
  }),
  netWeight: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  hasGlycemicFooter: PropTypes.bool,
  hideMissingValues: PropTypes.bool
}
export default NutritionalFacts
