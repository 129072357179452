import PropTypes from 'prop-types'
import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useMediaQuery } from 'react-responsive'
import { PaymentRequestButtonElement } from '@stripe/react-stripe-js'

import { block } from 'utils/classnames'
import { eur } from 'utils/numeral'
import { PreventButton } from 'utils/preventButton'
import { BREAKPOINT_TABLET } from 'utils/device'

import {
  APPLE_PAY_ID,
  PAYMENT_PROVIDERS_SUBMIT_PROPS,
  PAY_BUTTON_CONTAINER_ID
} from 'config/paymentMethods'

import { getSelectedPaymentMethods } from 'reducers'

import './PaymentSubmit.sass'

const b = block.with('basket-payment-submit')

export const PaymentSubmit = props => {
  const {
    isLoading = false,
    isDisabled = false,
    paymentProvider,
    payableAmount,
    paymentRequest
  } = props

  const {
    label = 'Passer commande'
  } = useMemo(() => {
    return paymentProvider && PAYMENT_PROVIDERS_SUBMIT_PROPS[paymentProvider]
      ? PAYMENT_PROVIDERS_SUBMIT_PROPS[paymentProvider]
      : {}
  }, [paymentProvider])
  const selectedPaymentMethods = useSelector(getSelectedPaymentMethods)
  const isApplePaySelected = Array.isArray(selectedPaymentMethods) && selectedPaymentMethods?.includes(APPLE_PAY_ID)
  const isTabletOrMobile = useMediaQuery({ query: `(max-width: ${BREAKPOINT_TABLET}px)` })

  return (
    <div
      className={b('', {
        provider: paymentProvider || false,
        loading: isLoading,
        disabled: isDisabled
      })}
    >
      <div
        id={PAY_BUTTON_CONTAINER_ID}
        className={b('alternative-button')}
      />
      {paymentRequest && isApplePaySelected
        ? (
          <PaymentRequestButtonElement options={{
            paymentRequest,
            style: { paymentRequestButton: { height: isTabletOrMobile ? '60px' : '50px' } }
          }}
          />
        ) : (
          <PreventButton
            isForm
            className={b('button', { loading: isLoading })}
            disabled={isDisabled}
            title={`${label} ${payableAmount ? eur(payableAmount) : ''}`.trim()}
          >
            {label}&ensp;{!!payableAmount && eur(payableAmount)}
          </PreventButton>
        )}
    </div>
  )
}

PaymentSubmit.propTypes = {
  isLoading: PropTypes.bool,
  isDisabled: PropTypes.bool,
  paymentProvider: PropTypes.string,
  payableAmount: PropTypes.number,
  paymentRequest: PropTypes.object
}

export default PaymentSubmit
