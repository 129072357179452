import PropTypes from 'prop-types'
import React, { useCallback, useEffect, useState } from 'react'

import { usePrevious } from 'hooks'

import { block } from 'utils/classnames'
import { pushFunnelEvent } from 'utils/googletagmanager'

import './CouponCode.sass'

const b = block.with('coupon-code-widget')

export const CouponCode = props => {
  const {
    isActive = true,
    isLoading = false,
    activeCoupon = null,
    onChange,
    onClear
  } = props

  const [code, setCode] = useState('')
  const previousLoadingState = usePrevious(isLoading)

  const handleSubmit = useCallback(
    (e) => {
      if (e.keyCode && e.keyCode !== 13) {
        return
      }
      e.preventDefault()
      e.stopPropagation()
      !!code && !isLoading && onChange && onChange(code)
    },
    [onChange, isLoading, code]
  )

  const handleChange = useCallback(
    (e) => {
      const newCode = e.target.value.trim()
      newCode !== code &&
        setCode(newCode)
    },
    [code]
  )

  useEffect(() => {
    // Clear coupon upon desactivation
    !isActive && activeCoupon && onClear && onClear()
  }, [isActive, activeCoupon, onClear])

  useEffect(() => {
    if (previousLoadingState && !isLoading) {
      setCode('')
    }
  }, [isLoading])

  useEffect(() => {
    if (activeCoupon) {
      const { code, discountValue, discountValueType } = activeCoupon
      pushFunnelEvent('AddCouponCode', { code, discountValue, discountValueType })
    }
  }, [activeCoupon])

  if (!isActive) {
    return null
  }

  return (
    <div className={b()}>
      {
        activeCoupon?.code
          ? (
            <p className={b('confirmation')}>
              Yay, votre code a été appliqué
            </p>
          )
          : (
            <div className={b('form')}>
              <label className={b('label')} htmlFor='coupon-code' aria-label='Code promo'>
                <input
                  name='coupon-code'
                  type='text'
                  className={b('input')}
                  value={code}
                  onChange={handleChange}
                  onKeyDown={handleSubmit}
                  placeholder='Code promo'
                  disabled={isLoading}
                />
              </label>
              <button
                type='button'
                className={b('submit', { loading: isLoading })}
                disabled={isLoading || !code}
                onClick={handleSubmit}
              >
                Ajouter
              </button>
            </div>
          )
      }
    </div>
  )
}

CouponCode.propTypes = {
  isLoading: PropTypes.bool,
  activeCoupon: PropTypes.object,
  onChange: PropTypes.func
}

export default CouponCode
