import React, { useEffect } from 'react'
import PropTypes from 'prop-types'

import { block } from 'utils/classnames'
import { eur } from 'utils/numeral'
import { pushFunnelEvent } from 'utils/googletagmanager'

import './ApplePaySelector.sass'

const b = block.with('applepay-selector')

export const ApplePaySelector = ({ isActive = true, isSelected = false, payableAmount = 0, onClick }) => {
  useEffect(() => {
    isActive && isSelected && pushFunnelEvent('PaymentMethodSelected', {
      paymentMethod: 'applepay',
      provider: 'stripe',
      amount: payableAmount
    })
  }, [isActive, isSelected, payableAmount])

  if (!isActive) {
    isSelected && onClick && onClick()
    return null
  }

  return (
    <button
      type='button'
      onClick={onClick}
      className={b('', { active: isSelected })}
    >
      <i className={b('icon')} />
      <span className={b('text')}>
        <span className={b('title')}>
          Apple Pay&ensp;
        </span>
      </span>
      {
        isSelected
          ? (
            <span className={b('amount')}>
              {eur(payableAmount)}
            </span>
          )
          : null
      }
    </button>
  )
}

ApplePaySelector.propTypes = {
  isActive: PropTypes.bool,
  isSelected: PropTypes.bool,
  payableAmount: PropTypes.number,
  onClick: PropTypes.func
}

export default ApplePaySelector
