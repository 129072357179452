import PropTypes from 'prop-types'
import React, { useRef } from 'react'

import AnimatedModal from 'components/Common/AnimatedModal'

import { useScript } from 'hooks/script'
import { useKeyDown } from 'hooks/dom'
import { block } from 'utils/classnames'

import './UndeliverableModal.sass'
import { clearAddressSuggestions } from 'actions/home/address'
import { useDispatch } from 'react-redux'

export const FORM_SCRIPT_SRC = 'https://foodchri.activehosted.com/f/embed.php?id=3'

const b = block.with('undeliverable-modal')

export const UndeliverableModal = props => {
  const {
    className = '',
    isOpen = false,
    onClose
  } = props
  const dispatch = useDispatch()
  useKeyDown(isOpen ? onClose : null, ['Escape'])
  const closeButton = useRef(null)
  const scriptIsReady = useScript(isOpen ? FORM_SCRIPT_SRC : null)

  React.useEffect(() => {
    scriptIsReady &&
      closeButton.current &&
      closeButton.current.focus()
    // eslint-disable-next-line
  }, [scriptIsReady])

  return (
    <AnimatedModal
      shouldShow={isOpen}
      className={`${className} ${b()}`.trim()}
    >
      <div className={b('content')}>
        <button
          className={b('close')} onClick={() => {
            dispatch(clearAddressSuggestions())
            onClose()
          }} aria-label='Fermer la modale' ref={closeButton}
        />
        <div className={b('body', { visible: scriptIsReady })}>
          <div className={b('head')}>
            <h4>
              <span>Zut ! </span>
              FoodChéri n'est pas encore arrivé jusqu'à votre adresse.
            </h4>
            <p>Donnez-nous l'opportunité de vous servir prochainement en nous partageant quelques informations sur vous.</p>
          </div>
          <div className={b('form') + ' _form_3'} />
        </div>
        <div className={b('footer', { visible: scriptIsReady })}>
          <p>Ces informations nous permettent de vous recontacter pour vous proposer nos solutions pour déjeuner ou dîner, à la maison ou au bureau. En aucun cas ces informations ne seront transmises à des tiers. Promis.</p>
        </div>
      </div>
    </AnimatedModal>
  )
}

UndeliverableModal.propTypes = {
  className: PropTypes.string,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func.isRequired
}

export default UndeliverableModal
