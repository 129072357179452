import { connect } from 'react-redux'
import Slideshow from './Slideshow'

import { getMe, getAllPublications } from 'reducers'

export const ConnectedSlideshow = connect(
  (state, props) => {
    const user = getMe(state, props)
    const products = getAllPublications(state, props)

    return {
      products,
      isActive: user && user?.b2b?.isManager
    }
  }
)(Slideshow)

export default Slideshow
