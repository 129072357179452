import PropTypes from 'prop-types'
import React from 'react'

import { block } from 'utils/classnames'
import { parseProductPrice } from 'utils/publications'

import './ProductPrice.sass'

const b = block.with('product-price')

export const ProductPrice = props => {
  const {
    price,
    originalPrice,
    companyDiscount,
    itemType,
    isOutOfStock = false
  } = props

  const parsedPrice = React.useMemo(() => (
    parseProductPrice(price, originalPrice, companyDiscount, itemType)
  ))

  if (!parsedPrice) {
    return null
  }

  return (
    <div className={b()}>
      <span className={b('amount', { free: parsedPrice.isFree, discount: parsedPrice.hasDiscount, isOutOfStock })}>
        <strong>{parsedPrice.price[0]},</strong>
        <small>{parsedPrice.price[1]}€</small>
      </span>
      {
        parsedPrice.hasDiscount
          ? (
            <span className={b('amount', { original: true })}>
              <strong>{parsedPrice.originalPrice[0]},</strong>
              <small>{parsedPrice.originalPrice[1]}€</small>
            </span>
          ) : null
      }
    </div>
  )
}

ProductPrice.propTypes = {
  price: PropTypes.number,
  originalPrice: PropTypes.number,
  companyDiscount: PropTypes.number,
  itemType: PropTypes.string
}

export default ProductPrice
