import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
  useRef
} from 'react'

import {
  getMealVoucherMaxAmount,
  getMealVoucherAmount,
  getMealVoucherHasError,
  getPayableAmount,
  getMayPay,
  getPaymentMethod
} from 'reducers'

import {
  setMealVoucherAmount as onChange
} from 'actions/home/checkout'

import { block } from 'utils/classnames'
import { pushFunnelEvent } from 'utils/googletagmanager'

import './MealVoucher.sass'

const b = block.with('meal-voucher-selector')

const handleInputClick = e => {
  e.preventDefault && e.preventDefault()
  e.stopPropagation && e.stopPropagation()
}

export const MealVoucher = props => {
  const {
    isActive = true,
    isValid = false,
    isSelected = false,
    hasError = false,
    defaultAmount = 0,
    amount = 0,
    minAmount = 0,
    maxAmount,
    onClick,
    onChange
  } = props

  const [isFocused, setIsFocused] = useState(false)
  const lastAmount = useRef(defaultAmount || amount || 0)

  const handleClick = useCallback(
    () => {
      onClick && onClick()
      if (isSelected) {
        amount && onChange && onChange(0)
      } else {
        !amount &&
          lastAmount.current &&
          onChange &&
          onChange(lastAmount.current)
      }
    },
    [amount, isSelected, onClick, onChange]
  )

  const handleFocus = useCallback(
    () => { setIsFocused(true) },
    []
  )

  const handleBlur = useCallback(
    () => {
      setIsFocused(false)
      if (isSelected && amount <= 0) {
        handleClick && handleClick()
      }
    },
    [isSelected, amount, handleClick]
  )

  const handleChange = useCallback(
    (e) => {
      let { value } = e.target
      value = parseFloat(value)
      value = isNaN(value) ? 0 : value
      lastAmount.current = value
      onChange && onChange(value)
    },
    [onChange]
  )

  const value = useMemo(() => {
    let value = parseFloat(amount)
    value = isNaN(value) ? 0 : value
    value = isFocused
      ? (value || '')
      : String(value.toFixed(2)).replace('.00', '')

    return value
  }, [amount, isFocused])

  useEffect(() => {
    if (!isSelected && amount > 0) {
      onChange && onChange(0)
    }
  }, [isSelected, amount, onChange])

  useEffect(() => {
    isActive && isSelected && pushFunnelEvent('PaymentMethodSelected', {
      paymentMethod: 'mealvoucher',
      provider: '',
      amount
    })
  }, [isActive, isSelected, amount])

  if (!isActive) {
    isSelected && handleClick && handleClick()

    return null
  }

  return (
    <button
      type='button'
      onClick={handleClick}
      className={
        b('', {
          selected: isSelected && !isValid,
          valid: isSelected && isValid,
          error: hasError,
          focused: isFocused
        })
      }
    >
      <span className={b('text')}>
        Titre restaurant papier
        <br />
        <span className={b('alert')}>
          Remise sécurisée, sans contact
        </span>
        {
          hasError
            ? (
              <span className={b('error')}>
                Vous avez été trop gourmand ;)
              </span>
            )
            : null
        }
      </span>
      <label
        className={b('input', { error: hasError })}
        htmlFor='mealVoucherAmount'
      >
        {
          isSelected
            ? (
              <>
                <input
                  type='number'
                  min={minAmount}
                  max={maxAmount}
                  lang={isFocused ? 'en' : 'fr'}
                  onFocus={handleFocus}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  step='0.01'
                  aria-label='Montant de ticket restaurant papier'
                  value={value}
                  onClick={handleInputClick}
                  autoFocus={!isFocused}
                />
                <span>&nbsp;€</span>
              </>
            )
            : null
        }
        <input
          type='hidden'
          name='mealVoucherAmount'
          value={isSelected ? amount : 0}
        />
      </label>
    </button>
  )
}

MealVoucher.propTypes = {
  onClick: PropTypes.func.isRequired,
  isActive: PropTypes.bool,
  isValid: PropTypes.bool,
  isSelected: PropTypes.bool,
  hasError: PropTypes.bool,
  amount: PropTypes.number,
  minAmount: PropTypes.number,
  maxAmount: PropTypes.number
}

export const ConnectedMealVoucher = connect(
  (state, props) => {
    const paymentMethod = getPaymentMethod(state, props) || {}
    const payableAmount = getPayableAmount(state, props)
    return {
      ...paymentMethod,
      amount: getMealVoucherAmount(state, props),
      maxAmount: getMealVoucherMaxAmount(state, props),
      hasError: getMealVoucherHasError(state, props),
      isActive: !!paymentMethod.isActive && payableAmount > 0,
      isValid: getMayPay(state, props)
    }
  },
  { onChange }
)(MealVoucher)

export default MealVoucher
