import React, { useMemo } from 'react'

import ProductCarousel from './Carousel'
import ProductImage, { ImagePlaceholder } from './Image'

import { getProductPictures } from 'utils/productPicture'

export const Image = ProductImage
export const ProductPicturePlaceholder = ImagePlaceholder
export const Carousel = ProductCarousel

export const ProductPicture = props => {
  const {
    product,
    isProductPage,
    ...rest
  } = props
  const images = useMemo(() => getProductPictures(product), [product])
  if (!Array.isArray(images) || images.length === 0) {
    return null
  }
  const altText = `${product.title} FC`

  return images.length === 1
    ? <Image isProductPage={isProductPage} {...rest} publicId={images[0]} altText={altText} />
    : <Carousel isProductPage={isProductPage} {...rest} images={images} altText={altText} />
}

export default ProductPicture
