import PropTypes from 'prop-types'
import React, { useMemo } from 'react'

import { ProductPicture } from 'components/product/ProductPicture'
import { Multiline } from 'components/Common/Multiline'
import { ProductPrice } from 'components/product/ProductPrice'
import { ProductControls } from 'components/product/ProductControls'
import { Description } from './Description'
import { EnvironmentInfo } from './EnvironmentInfo'
import { ProductIngredients } from './ProductIngredients'
import { NutritionalFacts } from './NutritionalFacts'
import { Preparation } from './Preparation'
import { Sourcing } from './Sourcing'

import { getMarketingTag } from 'utils/publications'
import { block } from 'utils/classnames'

import './ProductSheet.sass'
import NutriRecap from './NutriRecap.js'

const b = block.with('product-sheet')

export const ProductSheet = props => {
  const {
    product,
    publicationId = null,
    publication = null,
    price = 0,
    originalPrice = 0,
    quantity = 0,
    timeframe = null,
    menuItems,
    isLast = false,
    saleIsBlocked = false,
    updateCartProducts,
    isDemo = false,
    isProductPage,
    onDisplayInvalidDeliveryModal = null,
    showModalInvalidDeliveryInfo = false,
    isMobile = false
  } = props

  const ribbonTag = useMemo(() => (
    getMarketingTag(product?.tags)
  ), [product])

  if (!product) {
    return null
  }

  const isOutOfStock = publication?.stock === 0 || false
  const typeConditions = ['MENU', 'CHEESE', 'SNACK', 'YAOURT', 'DRINK', 'DRINK_ALCOHOL', 'CUTLERY', 'BREAD', 'FOODCHERIVORE']
  const productNetWeight = useMemo(() => product?.roundedNetWeight || product?.netWeight || 0, [product])
  const isProductSupplier = publication?.idProductSupplier !== 1
  const etiquettableText = !isMobile ? 'Les informations sur ce produit sont établies avec' : ' Données établies avec'

  return (
    <div className={b()}>
      {[
        <div key='picture' className={b('picture', { isProductPage })}>
          <ProductPicture
            product={{ ...product, menuItems }}
            parent='ProductPanel'
            hasThumbnail={false}
            isProductPage={isProductPage}
          />
        </div>,
        ribbonTag
          ? (
            <p
              key='ribbonTag'
              className={b('ribbon-tag')}
              style={{
                color: ribbonTag.textColor,
                backgroundColor: ribbonTag.backgroundColor,
                borderColor: ribbonTag.borderColor,
                borderRadius: ribbonTag.borderRadius
              }}
            >
              {ribbonTag.name}
            </p>
          )
          : null,
        product.title
          ? (
            <h3 key='title' className={b('title')}>
              <Multiline>{product.title + '.'}</Multiline>
            </h3>
          )
          : null,
        product.nutritionalInformations
          ? (
            <NutriRecap
              key='nutriRecap'
              nutriscore={product.nutritionalInformations.nutriscore}
              ecoscore={product.nutritionalInformations.carbonScore}
              calories={product.nutritionalInformations.calories}
            />
          )
          : null,
        publicationId
          ? (
            <div key='publication' className={b('publication')}>
              <div className={b('price')}>
                <ProductPrice
                  itemType={product.productType}
                  price={price}
                  originalPrice={originalPrice || price}
                />
              </div>
              <div className={b('controls')}>
                {isOutOfStock
                  ? <div className={b('outOfStock')}>Victime de son success</div>
                  : (
                    <ProductControls
                      onDisplayInvalidDeliveryModal={onDisplayInvalidDeliveryModal}
                      showModalInvalidDeliveryInfo={showModalInvalidDeliveryInfo}
                      publicationId={publicationId}
                      quantity={quantity}
                      isLast={isLast}
                      timeframe={timeframe}
                      onChange={updateCartProducts}
                      disabled={saleIsBlocked}
                      hasTextAddButton
                      noAddButton={isDemo}
                    />
                  )}
              </div>
            </div>
          )
          : null,
        product.description
          ? (
            <Description
              key='description'
              description={product.description}
              chefInfos={typeConditions.some(type => publication?.productTypeName.includes(type)) || isProductSupplier ? null : { idChef: publication.idChef, chefPhoto: publication.chefPhoto, chefName: publication.chefName }}
              tags={product?.tags}
              spiciness={product?.spicy}
            />
          )
          : null,
        product.sourcingInformation
          ? (
            <EnvironmentInfo
              key='environment'
              sourcing={product.sourcingInformation}
            />
          )
          : null,
        product.ingredients
          ? (
            <ProductIngredients
              key='ingredients'
              productIngredients={product.ingredients}
            />
          )
          : null,
        product.allergens
          ? (
            <div className={b('allergens')} key='allergens'>
              <h5>Allergènes</h5>
              <p>{product.allergens}</p>
            </div>
          )
          : null,
        productNetWeight
          ? (
            <div className={b('weight')} key='weight'>
              <h5>Poids net</h5>
              <p>{productNetWeight}g</p>
            </div>
          )
          : null,
        product.nutritionalInformations
          ? (
            <NutritionalFacts
              key='macroNutritionalFacts'
              type='macro'
              nutritionalFacts={product.nutritionalInformations}
              netWeight={product.netWeight}
              hasGlycemicFooter
            />
          )
          : null,
        product.nutritionalInformations
          ? (
            <NutritionalFacts
              key='microNutritionalFacts'
              type='micro'
              nutritionalFacts={product.nutritionalInformations}
              netWeight={product.netWeight}
              hideMissingValues
            />
          )
          : null,
        product.preparation
          ? (
            <Preparation
              key='preparation'
              preparation={product?.preparation}
              productType={product?.productType}
            />
          )
          : null,
        product.sourcingInformation
          ? (
            <Sourcing
              key='sourcing'
              sourcing={product.sourcingInformation}
            />
          )
          : null
      ]}
      <div className={b('etiquettable')}>{etiquettableText}
        <a href='https://etiquettable.eco2initiative.com/' rel='noopener noreferrer' target='_blank'>
          <img className={b('etiquettableImage')} src='/images/product-panel/etiquettable-logo.png' alt='etiquettable' />
        </a>
      </div>
    </div>
  )
}
ProductSheet.propTypes = {
  product: PropTypes.object,
  publicationId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  price: PropTypes.number,
  originalPrice: PropTypes.number,
  quantity: PropTypes.number,
  timeframe: PropTypes.object,
  isLast: PropTypes.bool,
  saleIsBlocked: PropTypes.bool,
  updateCartProducts: PropTypes.func,
  isDemo: PropTypes.bool,
  isMobile: PropTypes.bool
}
export default ProductSheet
