import { shallowEqual, useDispatch, useSelector } from 'react-redux'

import {
  getAddressIsLoading,
  getIsAddressTooltipDisplayed,
  getAddressSuggestions,
  getAddressIsOutOfBound,
  getFormattedCartAddress
} from 'reducers'

import {
  HIDE_ADDRESS_TOOLTIP_ACTION,
  getAddressSuggestions as fetchAddressSuggestions,
  updateDeliveryFromAddress
} from 'actions/home/address'
import AddressSelector from './AddressSelector'

export const ConnectedAddressSelector = () => {
  const dispatch = useDispatch()

  const isLoading = useSelector(getAddressIsLoading)
  const selectedAddress = useSelector(getFormattedCartAddress, shallowEqual)
  const suggestions = useSelector(getAddressSuggestions)
  const isOutOfBound = useSelector(getAddressIsOutOfBound)
  const showTooltipNoAddress = useSelector(getIsAddressTooltipDisplayed)

  const _onSelectAddress = address => {
    dispatch(updateDeliveryFromAddress(address))
  }
  const _onInputChange = query => {
    dispatch(fetchAddressSuggestions(query))
  }
  const _closeTooltip = () => {
    dispatch({ type: HIDE_ADDRESS_TOOLTIP_ACTION })
  }

  return (
    <AddressSelector
      isLoading={isLoading}
      selectedAddress={selectedAddress}
      suggestions={suggestions}
      isOutOfBound={isOutOfBound}
      showTooltipNoAddress={showTooltipNoAddress}
      hasB2BOffers={false}
      onSelectAddress={_onSelectAddress}
      onInputChange={_onInputChange}
      closeTooltip={_closeTooltip}
    />
  )
}

export default AddressSelector
