import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import React, { useEffect } from 'react'

import {
  getPaymentMethod,
  getPayableAmount
} from 'reducers'

import {
  TRD_ID,
  PROVIDER_PAYGREEN
} from 'config/paymentMethods'

import { block } from 'utils/classnames'
import { eur } from 'utils/numeral'
import { pushFunnelEvent } from 'utils/googletagmanager'

import './Trd.sass'

const b = block.with('trd-selector')

export const Trd = props => {
  const {
    isActive = true,
    isDisabled = false,
    isSelected = false,
    payableAmount = 0,
    availableFunds,
    onClick
  } = props

  useEffect(() => {
    !isDisabled && isActive && isSelected && pushFunnelEvent('PaymentMethodSelected', {
      paymentMethod: 'trd',
      provider: 'paygreen',
      amount: payableAmount
    })
  }, [isDisabled, isActive, isSelected, payableAmount])

  if (!isActive) {
    isSelected && onClick && onClick()

    return null
  }

  if (isDisabled) {
    isSelected && onClick && onClick()
  }

  return (
    <>
      <button
        type='button'
        onClick={onClick}
        className={b('', { active: isSelected })}
        disabled={isDisabled}
      >
        <i className={b('icon', { apetiz: true })} />
        <i className={b('icon', { up: true })} />
        <span className={b('text')}>
        Apetiz / UpDéjeuner
          <br />
          <small>
            {
              isDisabled
                ? `Le paiement par Appetiz/UpDéjeuner n'est pas disponible pour les commandes supérieures à ${availableFunds} euros.`
                : null
            }
          </small>
        </span>
        {
          isSelected
            ? (
              <span className={b('amount')}>
                {eur(payableAmount)}
              </span>
            )
            : null
        }
      </button>
      {
        isSelected
          ? (
            <input
              type='hidden'
              name='paygreenPaymentMethod'
              value={TRD_ID}
            />
          )
          : null
      }
      {
        isSelected
          ? (
            <input
              type='hidden'
              name='provider'
              value={PROVIDER_PAYGREEN}
            />
          )
          : null
      }
    </>
  )
}

Trd.propTypes = {
  onClick: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool,
  isActive: PropTypes.bool,
  isSelected: PropTypes.bool,
  payableAmount: PropTypes.number,
  availableFunds: PropTypes.number
}

export const ConnectedTrd = connect(
  (state, props) => {
    const paymentMethod = getPaymentMethod(state, props) || {}
    const payableAmount = getPayableAmount(state, props)
    const isActive = !!paymentMethod.isActive && payableAmount > 0
    const isDisabled = !!paymentMethod.availableFunds && payableAmount > paymentMethod.availableFunds

    return {
      ...paymentMethod,
      payableAmount: getPayableAmount(state, props),
      isActive,
      isDisabled
    }
  }
)(Trd)

export default Trd
