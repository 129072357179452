import PropTypes from 'prop-types'
import React from 'react'

export const CreditCardForm = props => {
  const {
    paymentMethodId = '',
    paymentIntentId = '',
    shouldSave = false,
    authorizationError = null
  } = props

  return (
    <>
      <input
        type='hidden'
        name='savePaymentMethod'
        value={paymentMethodId && shouldSave ? 1 : 0}
      />
      <input
        type='hidden'
        name='stripePaymentMethodId'
        value={paymentMethodId || ''}
      />
      <input
        type='hidden'
        name='stripeIntentId'
        value={paymentIntentId || ''}
      />
      {
        paymentMethodId
          ? (
            <input
              type='hidden'
              name='withoutCard'
              value='0'
            />
          )
          : null
      }
      {
        authorizationError
          ? (
            <input
              type='hidden'
              name='authorizationError'
              value={JSON.stringify(authorizationError)}
            />
          )
          : null
      }
    </>
  )
}

CreditCardForm.propTypes = {
  paymentMethodId: PropTypes.string,
  shouldSave: PropTypes.bool,
  paymentIntentId: PropTypes.string,
  authorizationError: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string
  ])
}

export default CreditCardForm
