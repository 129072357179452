import PropTypes from 'prop-types'
import React from 'react'

import AnimatedOverlay from 'components/Common/AnimatedOverlay'

import { block } from 'utils/classnames'

import './DeliveryOverlay.sass'

const b = block.with('delivery-overlay')

export const DeliveryOverlay = props => {
  const {
    shouldShow = false,
    onClick,
    className = ''
  } = props

  return (
    <AnimatedOverlay
      tabIndex={-1}
      shouldShow={shouldShow}
      onClick={onClick}
      className={`${b()} ${className}`.trim()}
    />
  )
}

DeliveryOverlay.propTypes = {
  shouldShow: PropTypes.bool,
  onClick: PropTypes.func,
  className: PropTypes.string
}

export default DeliveryOverlay
