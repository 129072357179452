import { useSelector } from 'react-redux'

import PanelContent from 'components/Common/Panel/PanelContent'
import LoyaltyCard, { MAX_LOYALTY_LEVEL } from 'components/Account/Loyalty/LoyaltyCard'
import { LOYALTY_MIN_AMOUNT } from 'components/Basket/BasketContent/Loyalty'

import { useRouterLink } from 'hooks/router'
import { getRouteById } from 'config/router'

import { block } from 'utils/classnames'

import { getCheckoutOrderHash, getMe, getTotalAmount, getDropOff } from 'reducers'

import './BasketConfirm.sass'
import { PreventButton } from 'utils/preventButton'
import { useDebounce } from 'react-use'
import { useState } from 'react'

const b = block.with('payment-confirm')

export const BasketConfirm = props => {
  const {
    firstName,
    loyaltyProgramLevel = 0,
    onBack,
    isCorner,
    dropOff,
    orderHash,
    totalAmount,
    productId
  } = props

  const [hasSubmitted, setHasSubmitted] = useState(false)
  const handleClick = useRouterLink(getRouteById('orderTracking'), { orderHash }, false, false, productId)

  useDebounce(async () => {
    if (hasSubmitted) {
      document.querySelector('.animated-panel').animate([
        { transform: 'translate3d(200%,0,0)' }
      ], {
        duration: 800
      })
      setTimeout(() => handleClick(), 400)
    }
  }, 1000, [hasSubmitted])

  return (
    <PanelContent title='Commande confirmée' onBack={onBack}>
      <div className={b('')}>
        <div>
          <img className={b('illustration')} src='/images/basket/thanks.svg' />
          <h2>Merci {firstName} !</h2>
          <h4>Votre commande est confirmée</h4>
          {
            !isCorner ? (
              <PreventButton onClick={() => setHasSubmitted(true)}>Voir ma commande</PreventButton>
            ) : (
              <h4>Rendez-vous au corner FoodChéri pour la récupérer</h4>
            )
          }
          {
            isCorner && dropOff ? (
              <p className='drop-off-comment'>{dropOff}</p>
            ) : null
          }
        </div>
        {
          totalAmount >= LOYALTY_MIN_AMOUNT && loyaltyProgramLevel < MAX_LOYALTY_LEVEL
            ? <LoyaltyCard count={loyaltyProgramLevel + 1} />
            : null
        }
      </div>
    </PanelContent>
  )
}

export const ConnectedBasketConfirm = (props) => {
  const user = useSelector(getMe)
  const orderHash = useSelector(getCheckoutOrderHash)
  const totalAmount = useSelector(getTotalAmount)
  const dropOff = useSelector(getDropOff)

  const confirmProps = {
    ...props,
    firstName: user?.firstName,
    loyaltyProgramLevel: user?.loyaltyProgram?.level || 0,
    isCorner: false,
    dropOff,
    orderHash,
    totalAmount
  }

  return (
    <BasketConfirm
      {...confirmProps}
    />
  )
}

export default ConnectedBasketConfirm
