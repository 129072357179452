import React from 'react'

import { block } from 'utils/classnames'

import './BasketEmpty.sass'

const b = block.with('basket-empty')

export const BasketEmpty = () => {
  return (
    <div className={b('')}>
      <div className={b('infos')}>
        <img className={b('image')} src='/images/basket/basket_empty.svg' />
        <h4 className={b('title')}>Votre panier est vide</h4>
        <p className={b('text')}>Ajouter un produit de votre choix pour passer commande</p>
      </div>
    </div>
  )
}

export default BasketEmpty
