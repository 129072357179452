import PropTypes from 'prop-types'
import React, { useCallback } from 'react'

import { block } from 'utils/classnames'

import './NewCreditCardSelector.sass'

const b = block.with('new-credit-card-selector')

export const NewCreditCardSelector = props => {
  const {
    onClick,
    shouldPush = false
  } = props

  const handleClick = useCallback(() => {
    onClick && onClick()
  }, [onClick])

  return (
    <button
      type='button'
      onClick={handleClick}
      className={b()}
    >
      <span className={b('icons')}>
        <i className={b('icon', { brand: 'visa' })} />
        <i className={b('icon', { brand: 'mastercard' })} />
        <i className={b('icon', { brand: 'amex' })} />
        <i className={b('icon', { brand: 'swile' })} />
      </span>
      {
        shouldPush
          ? <span className={b('push')}>Compléter</span>
          : <span className={b('text')}>Ajouter une carte</span>

      }
    </button>
  )
}

NewCreditCardSelector.propTypes = {
  onClick: PropTypes.func.isRequired,
  shouldPush: PropTypes.bool
}

export default NewCreditCardSelector
