import PropTypes from 'prop-types'
import React from 'react'

import { LoginForm } from 'components/user/LoginForm/LoginForm'

import './SignIn.sass'

export const SignIn = props => {
  const {
    user,
    errors,
    onSubmit,
    isLoading
  } = props

  return (
    <>
      <h3>
        Me connecter
      </h3>
      <LoginForm
        onSubmit={onSubmit}
        userData={user}
        error={errors}
        isLoading={isLoading}
        className='basket-sign-in'
      />
    </>
  )
}

SignIn.propTypes = {
  user: PropTypes.object,
  errors: PropTypes.object,
  onSubmit: PropTypes.func,
  onResetPassword: PropTypes.func,
  isLoading: PropTypes.bool
}

export default SignIn
