import React, { useState, useEffect } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'

import { getSlots } from 'actions/home/delivery'
import { getDateLabel } from 'utils/datetime'
import { block } from 'utils/classnames'
import { DEFAULT_NB_AVAILABLE_DAYS, MAXIMUM_NB_AVAILABLE_DAYS } from 'config/delivery'
import { getFormattedCartAddress } from 'reducers'

import './DaySelect.sass'

const b = block.with('day-select')

export const availableOrderDays = DEFAULT_NB_AVAILABLE_DAYS

export const DaySelect = props => {
  const {
    isOpen = false,
    activeDay = null,
    days = [],
    onClick,
    className = ''
  } = props

  const dispatch = useDispatch()
  const selectedAddress = useSelector(getFormattedCartAddress, shallowEqual)
  const [isExpanded, setIsExpanded] = useState(false)
  const [hasFetchedAllDays, setHasFetchedAllDays] = useState(false)

  useEffect(() => {
    setIsExpanded(false)
  }, [isOpen, activeDay])

  useEffect(() => {
    setHasFetchedAllDays(false)
  }, [selectedAddress])

  const toggleExpand = async () => {
    if (!hasFetchedAllDays) {
      const { address, latitude, longitude } = selectedAddress
      await dispatch(getSlots({ address, latitude, longitude }, MAXIMUM_NB_AVAILABLE_DAYS))
      setHasFetchedAllDays(true)
    }
    setIsExpanded(state => !state)
  }

  const availableDays = isExpanded
    ? days
    : days.slice(0, availableOrderDays)

  const canExpand = days.length < MAXIMUM_NB_AVAILABLE_DAYS

  if (!isOpen || availableDays.length === 0) {
    return null
  }

  return (
    <div className={`${className} ${b('')}`.trim()}>
      <ul className={b('list', { expanded: isExpanded, collapsed: canExpand })}>
        {
          availableDays.map(day => (
            <li key={day}>
              <button
                onClick={onClick && onClick(day)}
                className={b('item', { active: activeDay && day === activeDay })}
              >
                {getDateLabel(day)}
              </button>
            </li>
          ))
        }
      </ul>
      {
        canExpand
          ? (
            <button
              type='button'
              onClick={toggleExpand}
              className={b('expander', { expanded: isExpanded })}
            >
              <svg stroke='#746C85' width='11' height='6' viewBox='0 0 11 6' fill='none' xmlns='http://www.w3.org/2000/svg'>
                <path d='M10.2219 0.610962L5.61096 5.22192L1 0.610962' stroke='inherit' />
              </svg>
            &ensp;
              {isExpanded ? 'Voir moins' : 'Voir plus'}
            </button>
          )
          : null
      }
    </div>
  )
}
DaySelect.propTypes = {
  isOpen: PropTypes.bool,
  activeDay: PropTypes.string,
  days: PropTypes.arrayOf(PropTypes.string),
  onClick: PropTypes.func,
  className: PropTypes.string
}
export default DaySelect
