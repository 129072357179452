import React, { useState } from 'react'
import LoyaltyCardItem from 'components/Account/Loyalty/card/LoyaltyCardItem'
import LoyaltyCardLabel from 'components/Account/Loyalty/card/LoyaltyCardLabel'

import { getMonthNameFromDate } from 'utils/datetime'
import { getPreposition } from 'utils/strings'
import { block } from 'utils/classnames'

import './LoyaltyCard.sass'
import { LoyaltyGift } from './card/LoyaltyGift'

const b = block.with('loyalty-card')

export const MAX_LOYALTY_LEVEL = 9

const LoyaltyCard = (props) => {
  const {
    count = 0,
    fullSize = false,
    showGift = true
  } = props

  const [shouldShowGift, setShouldshowGift] = useState(showGift)

  const hideGift = () => {
    setShouldshowGift(false)
  }

  const Gift = (count) => {
    switch (count) {
      case 4:
        return <LoyaltyGift giftType='dessert' className={b('gift')} onClose={hideGift} />
      case 9:
        return <LoyaltyGift giftType='plat' className={b('gift')} onClose={hideGift} />
      default:
        return null
    }
  }

  const currentMonth = getMonthNameFromDate()

  return (
    <div className={b('', { fullSize })}>
      {
        shouldShowGift && Gift(count)
      }
      <div className={b('card')}>
        <div className={b('title', { fullSize })}>Votre carte fidélité</div>
        <div className={b('subtitle', { fullSize })}>du mois {getPreposition(currentMonth) + currentMonth}</div>
        <div className={b('topPart')}>
          <LoyaltyCardItem label='f' active={count >= 1} toActivate={count === 1 && !fullSize} />
          <LoyaltyCardItem label='o' active={count >= 2} toActivate={count === 2 && !fullSize} />
          <LoyaltyCardItem label='o' active={count >= 3} toActivate={count === 3 && !fullSize} />
          <LoyaltyCardItem label='d' active={count >= 4} toActivate={count === 4 && !fullSize} />
          <LoyaltyCardLabel label='Dessert offert' />
        </div>
        <div className={b('bottomPart')}>
          <LoyaltyCardItem label='c' active={count >= 5} toActivate={count === 5 && !fullSize} />
          <LoyaltyCardItem label='h' active={count >= 6} toActivate={count === 6 && !fullSize} />
          <LoyaltyCardItem label='é' active={count >= 7} toActivate={count === 7 && !fullSize} />
          <LoyaltyCardItem label='r' active={count >= 8} toActivate={count === 8 && !fullSize} />
          <LoyaltyCardItem label='i' active={count >= 9} toActivate={count === 9 && !fullSize} />
          <LoyaltyCardLabel label='Plat offert' />
        </div>
      </div>
    </div>
  )
}

export default LoyaltyCard
