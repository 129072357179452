import PropTypes from 'prop-types'
import React from 'react'

import { block } from 'utils/classnames'

import './AddressInput.sass'

const b = block.with('address-input')

export const AddressInput = (props = {}) => {
  const {
    value = '',
    hasFocus = false,
    inputRef,
    onChange,
    onBlur,
    onFocus,
    onClear,
    isEmpty = false,
    className = ''
  } = props

  return (
    <div className={`${className} ${b('', { empty: isEmpty })}`.trim()}>
      {
        value !== ''
          ? (
            <button
              tabIndex={-1}
              aria-label='Supprimer'
              onClick={onClear}
              className={b('close')}
            />
          )
          : null
      }
      <input
        ref={inputRef}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        onFocus={onFocus}
        className={b('field', { empty: isEmpty })}
        placeholder={hasFocus ? 'Votre adresse de livraison' : 'On vous livre où ?'}
      />
    </div>
  )
}

AddressInput.propTypes = {
  value: PropTypes.string.isRequired,
  hasFocus: PropTypes.bool,
  inputRef: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  onClear: PropTypes.func,
  isEmpty: PropTypes.bool,
  className: PropTypes.string
}

export default AddressInput
