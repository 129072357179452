import PropTypes from 'prop-types'
import React, { useCallback, useEffect, useState } from 'react'

import MultiStepPanel from 'components/Common/Panel/MultiStepPanel'
import { SignIn } from './SignIn'
import { SignUp } from './SignUp'
import { ResetPassword } from './ResetPassword'

import { block } from 'utils/classnames'

import './BasketAuth.sass'

const b = block.with('basket-auth')

export const views = [
  'sign-up',
  'sign-in',
  'reset-password'
]

export const BasketAuth = props => {
  const {
    user,
    errors,
    isLoading = false,
    signIn,
    signUp,
    lostPassword,
    onBack,
    currentView = views[0]
  } = props

  const [view, setView] = useState(
    views.includes(currentView) ? currentView : views[0]
  )
  const [reverse, setReverse] = useState(false)

  const handleSignIn = useCallback(() => {
    setReverse(view === 'reset-password')
    setView('sign-in')
  }, [view])

  const handleSignUp = useCallback(() => {
    setReverse(true)
    setView('sign-up')
  }, [])

  const handleResetPassword = useCallback(() => {
    setReverse(false)
    setView('reset-password')
  }, [])

  useEffect(() => {
    views.includes(currentView) && setView(currentView)
  }, [currentView])

  return (
    <div className={b()}>
      <nav className={b('form-switch')}>
        <button
          className={b('form-switch-button', { back: true })}
          onClick={onBack}
          disabled={!onBack}
        >
          Retour
        </button>
        <button
          className={b('form-switch-button', { active: view === 'sign-up' })}
          onClick={handleSignUp}
          disabled={view === 'sign-up'}
        >
          M'inscrire
        </button>
        <button
          className={b('form-switch-button', { active: view === 'sign-in' })}
          onClick={handleSignIn}
          disabled={view === 'sign-in'}
        >
          Me connecter
        </button>
      </nav>
      <MultiStepPanel active={view} reverse={reverse}>
        <article id='sign-in' className={b('view', { view })}>
          <div className={b('form')}>
            <SignIn
              user={user}
              errors={errors}
              onSubmit={signIn}
              isLoading={isLoading}
            />
          </div>
          <footer className={b('footer')}>
            <p className={b('text-link')}>
              Pas encore client ?
              &nbsp;
              <button onClick={handleSignUp}>
                S'inscrire
              </button>
            </p>
            <button type='button' className={b('lost-password')} onClick={handleResetPassword}>
              Mot de passe oublié ?
            </button>
          </footer>
        </article>
        <article id='sign-up' className={b('view', { view })}>
          <div className={b('form')}>
            <SignUp
              id='sign-up'
              user={user}
              errors={errors}
              onSubmit={signUp}
              isLoading={isLoading}
            />
          </div>
          <footer className={b('footer', { signup: true })}>
            <p className={b('text-link')}>
              Déjà inscrit ?
              &nbsp;
              <button onClick={handleSignIn}>
                Se connecter
              </button>
            </p>
          </footer>
        </article>
        <article id='reset-password' className={b('view', { view })}>
          <div className={b('form')}>
            <ResetPassword
              onSubmit={lostPassword}
              isLoading={isLoading}
            />
          </div>
          <footer className={b('footer')}>
            <p className={b('text-link')}>
              <button onClick={handleSignIn}>
                Se connecter
              </button>
            </p>
          </footer>
        </article>
      </MultiStepPanel>
    </div>
  )
}

BasketAuth.propTypes = {
  user: PropTypes.object,
  errors: PropTypes.object,
  isLoading: PropTypes.bool,
  signIn: PropTypes.func,
  signUp: PropTypes.func,
  lostPassword: PropTypes.func,
  onBack: PropTypes.func
}

export default BasketAuth
