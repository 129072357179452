import PropTypes from 'prop-types'
import React from 'react'

import { withRouter } from 'components/Router'

import Modal from 'components/Common/Modal'

const ModalComponentPropTypes = {
  shouldShow: PropTypes.bool,
  onClose: PropTypes.func,
  query: PropTypes.object
}

export const RouteModal = props => {
  const {
    path,
    Component,
    isFullsSreenOnMobile
  } = props

  if (!path || !Component) {
    return null
  }

  const ModalComponent = React.useMemo(
    () => withRouter(path)(
      ({ shouldShow, onClose, query, ...rest }) => (
        <Modal shouldShow={shouldShow} onClose={onClose} isFullsSreenOnMobile={isFullsSreenOnMobile}>
          <Component onClose={onClose} {...query} {...rest} />
        </Modal>
      )
    ),
    [path]
  )

  ModalComponent.displayName = 'ModalComponent'
  ModalComponent.propTypes = ModalComponentPropTypes

  return <ModalComponent {...props} />
}
RouteModal.propTypes = {
  path: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
  Component: PropTypes.elementType
}
export default RouteModal
