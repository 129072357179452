import PropTypes from 'prop-types'
import React from 'react'

import { block } from 'utils/classnames'

import './Sourcing.sass'

const b = block.with('product-sourcing')

export const Sourcing = (props) => {
  const {
    sourcing
  } = props

  if (!sourcing || !Object.values(sourcing).some(Boolean)) {
    return null
  }

  return (
    <div className={b()}>
      <h5>Dans cette recette</h5>
      <ul className={b('values')}>
        {[
          sourcing.seasonalIngredientsRate
            ? (
              <li className={b('seasonal-ingredients')} key='seasonal-ingredients'>
                {sourcing.seasonalIngredientsRate}% de légumes et fruits de saison
              </li>
            )
            : null,
          sourcing.localIngredientsRate
            ? (
              <li className={b('local-ingredients')} key='local-ingredients'>
                {sourcing.localIngredientsRate}% d'ingrédients en circuits courts
              </li>
            )
            : null,
          sourcing.frenchIngredientsRate
            ? (
              <li className={b('french-ingredients')} key='french-ingredients'>
                {sourcing.frenchIngredientsRate}% d'ingrédients français
              </li>
            )
            : null,
          sourcing.carbonFootprint
            ? (
              <li className={b('carbon-footprint')} key='carbon-footprint'>
                {sourcing.carbonFootprint}g d'empreinte CO2
              </li>
            )
            : null,
          sourcing.certifiedIngredientsRate
            ? (
              <li className={b('certified-ingredients')} key='certified-ingredients'>
                {sourcing.certifiedIngredientsRate}% d'ingrédients labellisés
              </li>
            )
            : null,
          sourcing.organicIngredientsRate
            ? (
              <li className={b('organic-ingredients')} key='organic-ingredients'>
                {sourcing.organicIngredientsRate}% d'ingrédients bio
              </li>
            )
            : null,
          sourcing.sustainableFishing
            ? (
              <li className={b('sustainable-fishing')} key='sustainable-fishing'>
                Pêche durable
              </li>
            )
            : null
        ].filter(Boolean)}
      </ul>
    </div>
  )
}
Sourcing.propTypes = {
  sourcing: PropTypes.shape({
    frenchIngredientsRate: PropTypes.number,
    certifiedIngredientsRate: PropTypes.number,
    organicIngredientsRate: PropTypes.number,
    localIngredientsRate: PropTypes.number,
    seasonalIngredientsRate: PropTypes.number,
    sustainableFishing: PropTypes.bool,
    carbonFootprint: PropTypes.number
  })
}
export default Sourcing
