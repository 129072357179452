import React, { useEffect, useMemo, useState } from 'react'
import { connect, useDispatch } from 'react-redux'

import { FormField } from 'components/form'

import { block } from 'utils/classnames'
import { getErrorMessageFromConfig } from 'utils/formValidation/user'
import formConfig from 'config/form/user/signUp'
import href from 'config/hrefs'

import { clearUserErrors, signUp } from 'actions/user'
import { getUserErrors, getSecurityIsLoading } from 'reducers'

import './RegisterForm.sass'

const getErrorMessage = getErrorMessageFromConfig(formConfig)

export const RegisterForm = props => {
  const {
    isLoading,
    error,
    onSubmit,
    onClose,
    forceAgreement = false
  } = props

  const formFields = useMemo(() => (
    forceAgreement
      ? {
        ...formConfig.fields,
        legalAgreement: {
          label: (
            <span className='legal-notice'>
              J'accepte la <a href={href.rootPrivacyPolicy} target='_blank' rel='noopener noreferrer'>politique de confidentialité</a> et les <a href={href.generalSaleConditionPage} target='_blank' rel='noopener noreferrer'>conditions générales</a>.
            </span>
          ),
          type: 'checkbox',
          required: true
        }
      }
      : formConfig.fields
  ), [])

  const className = props.className || 'register-form'

  const initialState = { ...formConfig.defaults }
  const [data, setData] = useState(initialState)
  const [hasSubmitted, setHasSubmitted] = useState(false)
  const dispatch = useDispatch()
  const maySubmit = useMemo(() => (
    !Object.entries(formFields)
      .filter(([key, field]) => field.required)
      .some(([key, field]) => !data[key])
  ), [formFields, data])

  useEffect(() => {
    if (hasSubmitted && isLoading === false) {
      setHasSubmitted(false)
      if (!error || Object.keys(error).length === 0) {
        onClose && onClose()
      }
    }
  })
  useEffect(() => {
    dispatch(clearUserErrors())
  }, [])

  const handleChange = name => value => setData(data => ({ ...data, [name]: value }))

  const handleSubmit = e => {
    e.preventDefault()
    onSubmit && onSubmit(data)
    setHasSubmitted(true)
    dispatch(clearUserErrors())
  }
  return (
    <div className={block(className)}>
      <h4 className={block(className, 'title')}>
        Nouveau client ?&nbsp;
        <br />
        Inscrivez-vous
      </h4>
      <form className={block(className, 'form')} onSubmit={handleSubmit}>
        {
          Object
            .entries(formFields)
            .map(([name, props]) => (
              <FormField
                disabled={isLoading}
                key={name}
                placeholder={props.label}
                name={name}
                error={getErrorMessage(name, error)}
                value={data[name]}
                onChange={handleChange(name)}
                showInformation
                type={props.type}
              />
            ))
        }
        <p className={block(className, 'title-communications')}>Je souhaite recevoir l’actualité de FoodChéri par :</p>
        <div className={block(className, 'container-communications')}>
          <FormField
            disabled={isLoading}
            key='subscribedNewsletterMail'
            placeholder='Mail'
            name='subscribedNewsletterMail'
            error={getErrorMessage('subscribedNewsletterMail', error)}
            value={data.subscribedNewsletterMail}
            onChange={handleChange('subscribedNewsletterMail')}
            showInformation
            type='checkbox'
          />
          <FormField
            disabled={isLoading}
            key='subscribedNewsletterSms'
            placeholder='Sms'
            name='subscribedNewsletterSms'
            error={getErrorMessage('subscribedNewsletterSms', error)}
            value={data.subscribedNewsletterSms}
            onChange={handleChange('subscribedNewsletterSms')}
            showInformation
            type='checkbox'
          />
          <FormField
            disabled={isLoading}
            key='subscribedNewsletterPush'
            placeholder='Notifications push'
            name='subscribedNewsletterPush'
            error={getErrorMessage('subscribedNewsletterPush', error)}
            value={data.subscribedNewsletterPush}
            onChange={handleChange('subscribedNewsletterPush')}
            showInformation
            type='checkbox'
          />
        </div>
        {
          error && getErrorMessage('global', error)
            ? (
              <span className={block(className, 'error')}>
                {getErrorMessage('global', error)}
              </span>
            )
            : null
        }
        <div className={block(className, 'container-submit')}>
          <button
            type='submit'
            className={block(className, 'button-submit', { loading: isLoading })}
            disabled={isLoading || !maySubmit}
          >
          S'inscrire
          </button>
        </div>
      </form>
    </div>
  )
}

export const ConnectedRegisterForm = connect(
  (state, props) => ({
    error: getUserErrors(state, props),
    isLoading: getSecurityIsLoading(state, props)
  }),
  dispatch => ({
    onSubmit: (data) => dispatch(signUp(data, 'UserModal'))
  })
)(RegisterForm)

export default ConnectedRegisterForm
