import { connect, useDispatch, useSelector } from 'react-redux'
import React, { useEffect, useRef, useState } from 'react'
import { Elements, useStripe } from '@stripe/react-stripe-js'

import {
  getCheckoutAmounts,
  getCheckoutCompanySetting,
  getActiveCoupon,
  getHasSodexo,
  getCompanyDiscountAmount,
  getCouponAmount,
  getMealVoucherAmount,
  getHasMonthlyBilling,
  getPayableAmountWithoutCompanyDiscount,
  getPayableAmount,
  getTotalAmount,
  getTotalDueAmount,
  getPaymentMethodDefaults,
  getSelectedPaymentMethods,
  getCheckoutCouponIsLoading,
  getSelectedPaymentProvider,
  getCheckoutOrderHash,
  getMayPay,
  getCheckoutIsLoading,
  getCheckoutError,
  getMe,
  getCheckoutPaymentWaitingAuthorization,
  getPaymentMethod
} from 'reducers'

import {
  selectPaymentMethods as updateSelection,
  appendPaymentMethods,
  pay,
  setCheckoutError,
  POPIN_ERROR_MESSAGE,
  SHIFT_TIMESLOT_UNAVAILABLE
} from 'actions/home/checkout'
import {
  updateCartDiscounts as checkCoupon,
  clearCartDiscounts as clearCoupon
} from 'actions/home/cart'
import { updateCommunicationPreferences } from 'actions/user'

import { block } from 'utils/classnames'
import { communicationPreferencesLabels } from 'utils/communications'
import { formDataToObject } from 'utils/data'
import href from 'config/hrefs'

import { CouponCode } from './CouponCode'
import { OrderTotal } from 'components/Order/OrderTotal'
import PaymentMethodSelector from './PaymentMethods/PaymentMethodSelector'
import PaymentSubmit from './PaymentSubmit'
import PaymentError from './PaymentError'
import {
  PaymentMethodIcons,
  ICONS_KEY,
  UNACCEPTED_KEY
} from 'components/layout/Footer/PaymentMethodIcons'

import {
  CREDIT_CARD_ID,
  PAYMENT_PROVIDERS,
  PROVIDER_USER
} from 'config/paymentMethods'
import { stripePromise } from 'config/stripe'

import './PaymentForm.sass'

const b = block.with('basket-payment-form')

const ConnectedCouponCode = connect(
  (state, props) => ({
    isLoading: getCheckoutCouponIsLoading(state, props),
    isActive: getPayableAmount(state, props) >= 0 && !getHasSodexo(state, props),
    activeCoupon: getActiveCoupon(state, props)
  }),
  dispatch => ({
    onChange: (code) => dispatch(checkCoupon(code)),
    onClear: () => dispatch(clearCoupon())
  })
)(CouponCode)

const ConnectedOrderTotal = connect(
  (state, props) => ({
    ...getCheckoutAmounts(state, props),
    companySetting: getCheckoutCompanySetting(state, props),
    activeCoupon: getActiveCoupon(state, props),
    hasSodexo: getHasSodexo(state, props),
    hasMonthlyBilling: getHasMonthlyBilling(state, props),
    totalDueWithoutCompanyDiscount: getPayableAmountWithoutCompanyDiscount(state, props, false),
    totalDue: getPayableAmount(state, props, false),
    total: getTotalAmount(state, props)
  }),
  { clearCoupon }
)(OrderTotal)

const ConnectedPaymentMethodSelector = connect(
  (state, props) => ({
    selected: getSelectedPaymentMethods(state, props),
    defaults: getPaymentMethodDefaults(state, props),
    creditCardPaymentMethod: getPaymentMethod(state, { paymentMethodId: CREDIT_CARD_ID }),
    hasCompanyOffer: getCompanyDiscountAmount(state, props) > 0,
    hasCoupon: getCouponAmount(state, props) > 0,
    totalDue: getTotalDueAmount(state, props),
    payableAmount: getPayableAmount(state, props),
    mealVoucherAmount: getMealVoucherAmount(state, props),
    mayPay: getMayPay(state, props)
  }),
  { updateSelection, appendPaymentMethods }
)(PaymentMethodSelector)

export const defaultFormValues = {
  hash: '',
  savePaymentMethod: '',
  stripePaymentMethodId: '',
  stripeIntentId: '',
  discountCode: '',
  mealVoucherAmount: 0,
  companyCode: '',
  withoutCard: '1',
  provider: PAYMENT_PROVIDERS.default,
  _token: ''
}

export const PaymentForm = ({
  paymentProvider,
  hash,
  onSubmit,
  mayPay = false,
  onAddCreditCard,
  isLoading,
  checkoutErrorMessage,
  hasAcceptedTermsOfSale,
  payableAmount,
  previousCardLength,
  productsRemoved = []
}) => {
  const form = useRef(null)
  const [isTermsOfSaleInputChecked, setIsTermsOfSaleInputChecked] = useState(false)
  const dispatch = useDispatch()
  const clickedRef = useRef(false)
  const [isClickable, setIsClickable] = useState(true)
  const isWaitingPaymentAuthorization = useSelector(getCheckoutPaymentWaitingAuthorization)
  const stripe = useStripe()
  const [paymentRequest, setPaymentRequest] = useState(null)

  useEffect(() => {
    if (stripe) {
      const pr = stripe.paymentRequest({
        country: 'FR',
        currency: 'eur',
        total: {
          label: 'FoodChéri',
          amount: Math.round(payableAmount * 100)
        },
        disableWallets: ['googlePay', 'browserCard']
      })
      pr.canMakePayment().then(result => {
        if (result) {
          setPaymentRequest(pr)
        }
      })
    }
  }, [stripe])

  useEffect(() => {
    if (isLoading || clickedRef.current || checkoutErrorMessage) setIsClickable(false)
    else setIsClickable(true)
  }, [clickedRef.current, isLoading, checkoutErrorMessage])

  const handleSubmit = async event => {
    event && event.preventDefault()
    if (clickedRef.current) {
      return
    }
    clickedRef.current = true
    if (!hasAcceptedTermsOfSale && isTermsOfSaleInputChecked) {
      await dispatch(updateCommunicationPreferences(
        { [communicationPreferencesLabels.TERMS_OF_SALE]: true }, false
      ))
    }
    var formData = formDataToObject(form.current)
    var data = defaultFormValues
    Object.entries(formData)
      .forEach(([key, value]) => { data[key] = value })
    onSubmit && onSubmit(data)
    clickedRef.current = false
  }

  const handleCheckoutError = (e) => {
    e.preventDefault()
    clickedRef.current = false
    dispatch(setCheckoutError(null))
  }

  return (
    <form ref={form} className={b()} onSubmit={handleSubmit}>
      <div className={b('body')}>
        <input
          type='hidden'
          name='provider'
          value={paymentProvider || PROVIDER_USER}
        />
        <input
          type='hidden'
          name='hash'
          value={hash || ''}
        />
        <ConnectedCouponCode />
        <ConnectedOrderTotal />
        <ConnectedPaymentMethodSelector
          onSubmit={handleSubmit}
          onAddCreditCard={onAddCreditCard}
          paymentRequest={paymentRequest}
          previousCardLength={previousCardLength}
        />
        <div>
          <PaymentMethodIcons sections={[ICONS_KEY, UNACCEPTED_KEY]} />
        </div>
      </div>
      <div className={b('footer')}>
        {
          checkoutErrorMessage && checkoutErrorMessage !== POPIN_ERROR_MESSAGE && checkoutErrorMessage !== 'conflict' && checkoutErrorMessage !== SHIFT_TIMESLOT_UNAVAILABLE
            ? <PaymentError checkoutErrorMessage={checkoutErrorMessage} setCheckoutError={handleCheckoutError} clickedRef={clickedRef.current} />
            : null
        }
        {
          hasAcceptedTermsOfSale
            ? null
            : (
              <label className={b('terms-of-sale-acceptance')}>
                <div className={b('checkbox', { checked: isTermsOfSaleInputChecked })} onClick={() => setIsTermsOfSaleInputChecked(state => !state)} />
                <div className={b('label')}>J'accepte les <a target='_blank' rel='noopener noreferrer' href={href.generalSaleConditionPage}>conditions générales de vente</a>.</div>
              </label>
            )
        }
        <PaymentSubmit
          paymentProvider={paymentProvider}
          isDisabled={!mayPay || !isClickable || (!hasAcceptedTermsOfSale && !isTermsOfSaleInputChecked) || isWaitingPaymentAuthorization}
          paymentRequest={paymentRequest}
          isLoading={!isClickable && !checkoutErrorMessage}
        />
      </div>
    </form>
  )
}

export const ConnectedStripedPaymentForm = props => {
  const paymentProvider = useSelector(getSelectedPaymentProvider)
  const hash = useSelector(getCheckoutOrderHash)
  const mayPay = useSelector(getMayPay)
  const isLoading = useSelector(getCheckoutIsLoading)
  const checkoutErrorMessage = useSelector(getCheckoutError)
  const hasAcceptedTermsOfSale = !!useSelector(getMe)?.communicationPreferences?.termsOfSale
  const payableAmount = useSelector(getPayableAmount)
  const dispatch = useDispatch()
  const onSubmit = (data) => dispatch(pay(data, props.onNext))
  return (
    <Elements stripe={stripePromise}>
      <PaymentForm
        paymentProvider={paymentProvider}
        hash={hash}
        onSubmit={onSubmit}
        mayPay={mayPay}
        isLoading={isLoading}
        checkoutErrorMessage={checkoutErrorMessage}
        hasAcceptedTermsOfSale={hasAcceptedTermsOfSale}
        payableAmount={payableAmount}
        {...props}
      />
    </Elements>
  )
}
export default ConnectedStripedPaymentForm
