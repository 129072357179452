import React, { useState } from 'react'

import { block } from 'utils/classnames'

import './PasswordInput.sass'

export const PasswordInput = props => {
  const {
    value = '',
    onChange,
    name,
    placeholder,
    showInformation = false,
    disabled = false
  } = props

  const [show, setShow] = useState(false)

  const toggleShowPassword = () => {
    setShow(!show)
  }

  const handleChange = (e) => {
    const { value } = e.target
    onChange && onChange(value.trim())
  }

  return (
    <div className={block('password-input')}>
      <input
        type={show ? 'text' : 'password'}
        value={value}
        onChange={handleChange}
        name={name}
        className={block('password-input', 'input')}
        placeholder={placeholder}
        disabled={disabled}
      />
      <span className={block('password-input', 'button-show-password', { show })} onClick={toggleShowPassword} />
      {
        showInformation ? (
          <div className={block('password-input', 'info')}>
            <i className={block('icon-info')} />
            <div className={block('password-input', 'info-text')}>
              Votre mot de passe doit contenir 8 caractères minimum et au moins deux types de caractères parmi les suivants : majuscule, minuscule, chiffre, caractère spécial.
            </div>
          </div>
        ) : null
      }
    </div>
  )
}

export default PasswordInput
