import PropTypes from 'prop-types'
import React from 'react'
import { confirmable, createConfirmation } from 'react-confirm'
import AnimatedModal from './AnimatedModal'

import { block } from 'utils/classnames'

import './Confirm.sass'

const b = block.with('confirm-modal')

export const ConfirmModal = props => {
  const {
    show = false,
    proceed,
    cancel,
    confirmation,
    options = {}
  } = props

  const {
    title = '',
    proceedLabel,
    cancelLabel
  } = options

  return (
    <AnimatedModal
      className={b()}
      shouldShow={show}
      onClick={cancel}
    >
      <div className={b('content')}>
        <div className={b('body')}>
          {
            title
              ? (
                <h2 className={b('title')}>
                  {title}
                </h2>
              )
              : null
          }
          <p className={b('confirmation')}>
            {confirmation || 'Vous êtes sûrs ?'}
          </p>
        </div>

        <footer className={b('footer')}>
          <button
            onClick={proceed}
            className={b('button', { proceed: true })}
          >
            {proceedLabel || 'continuer'}
          </button>

          <button
            onClick={cancel}
            className={b('button', { cancel: true })}
          >
            {cancelLabel || 'annuler'}
          </button>
        </footer>
      </div>
    </AnimatedModal>
  )
}
ConfirmModal.propTypes = {
  proceed: PropTypes.func,
  cancel: PropTypes.func,
  confirmation: PropTypes.string,
  options: PropTypes.shape({
    title: PropTypes.string,
    proceedLabel: PropTypes.string,
    cancelLabel: PropTypes.string
  })
}

const Confirm = confirmable(ConfirmModal)

export const confirm = (confirmation, options = {}) => (
  createConfirmation(Confirm)({ confirmation, options })
)

export default ConfirmModal
