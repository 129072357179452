import PropTypes from 'prop-types'
import React, { useCallback, useEffect, useMemo } from 'react'

import { block } from 'utils/classnames'
import { eur } from 'utils/numeral'
import { pushFunnelEvent } from 'utils/googletagmanager'

import './CreditCardSelector.sass'

const b = block.with('credit-card-selector')

export const CreditCardSelector = props => {
  const {
    card = {},
    isSelected = false,
    totalDue = 0,
    shouldPush = false,
    onClick
  } = props

  const {
    last4
  } = card

  const brand = useMemo(() => (
    card.brand
      ? card.brand.trim().replace(/\s+/g, '-').toLowerCase()
      : false
  ), [card])

  const handleClick = useCallback(() => {
    onClick && onClick(card)
  }, [onClick, card])

  useEffect(() => {
    isSelected && pushFunnelEvent('PaymentMethodSelected', {
      paymentMethod: 'creditcard',
      provider: 'stripe',
      card,
      amount: totalDue
    })
  }, [isSelected, card, totalDue])

  return (
    <button
      type='button'
      onClick={handleClick}
      className={b('', { active: isSelected })}
    >
      <i className={b('icon', { brand })} />
      <span className={b('text')}>
        ••••&ensp;{last4}
      </span>
      {
        isSelected
          ? (
            <span className={b('amount')}>
              {eur(totalDue)}
            </span>
          )
          : null
      }
      {
        shouldPush && !isSelected
          ? <span className={b('push')}>Compléter</span>
          : null
      }
    </button>
  )
}

CreditCardSelector.propTypes = {
  onClick: PropTypes.func.isRequired,
  shouldPush: PropTypes.bool,
  card: PropTypes.shape({
    brand: PropTypes.string,
    last4: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number
    ])
  }),
  isSelected: PropTypes.bool,
  totalDue: PropTypes.number
}

export default CreditCardSelector
