import { connect } from 'react-redux'
import React from 'react'
import PropTypes from 'prop-types'

import {
  COMPANY_DISCOUNT_ID,
  USER_CREDIT_ID
} from 'config/paymentMethods'

import {
  getTotalDueAmount,
  getCompanyDiscountAmount,
  getCurrentCredit,
  getTotalAmount,
  getSelectedPaymentMethods
} from 'reducers'

import './NothingToPay.sass'

export const NothingToPay = ({ togglePaymentMethod = () => {}, isActive = false, total = 0, companyDiscountAmount = 0, currentCredit = 0, selectedPaymentMethods = [] }) => {
  React.useEffect(() => {
    if (isActive) {
      if (total > 0) {
        if (companyDiscountAmount > 0 && !selectedPaymentMethods.includes(COMPANY_DISCOUNT_ID)) {
          togglePaymentMethod(COMPANY_DISCOUNT_ID)
        }
        if (total - companyDiscountAmount > 0 && currentCredit > 0 && !selectedPaymentMethods.includes(USER_CREDIT_ID)) {
          togglePaymentMethod(USER_CREDIT_ID)
        }
      }
    } else {
      for (const paymentMethod of [COMPANY_DISCOUNT_ID, USER_CREDIT_ID]) {
        if (selectedPaymentMethods.includes(paymentMethod)) {
          togglePaymentMethod(paymentMethod)
        }
      }
    }
  }, [togglePaymentMethod, isActive, total, companyDiscountAmount, currentCredit, selectedPaymentMethods])
  return isActive
    ? (
      <div className='nothing-to-pay'>
        <p>
          Vous n'avez rien à payer pour cette commande
        </p>
      </div>
    )
    : null
}

NothingToPay.propTypes = {
  togglePaymentMethod: PropTypes.func,
  isActive: PropTypes.bool,
  total: PropTypes.number,
  companyDiscountAmount: PropTypes.number,
  currentCredit: PropTypes.number,
  selectedPaymentMethods: PropTypes.arrayOf(PropTypes.string)
}

export const ConnectedNothingToPay = connect(
  (state, props) => ({
    isActive: getTotalDueAmount(state, props) <= 0,
    total: getTotalAmount(state, props),
    companyDiscountAmount: getCompanyDiscountAmount(state, props),
    currentCredit: getCurrentCredit(state, props),
    selectedPaymentMethods: getSelectedPaymentMethods(state, props)
  })
)(NothingToPay)

export default NothingToPay
