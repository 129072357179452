import React from 'react'

import { eur } from 'utils/numeral'
import { block } from 'utils/classnames'

import './Loyalty.sass'

const b = block.with('basket-loyalty')

export const LOYALTY_MIN_AMOUNT = 9

const Loyalty = (props) => {
  const {
    total
  } = props

  if (total >= LOYALTY_MIN_AMOUNT) {
    return null
  }

  return (
    <div className={b('')}>
      <p className={b('text')}><span className={b('amount')}>Plus que {eur(LOYALTY_MIN_AMOUNT - total)}</span> pour que votre commande soit éligible au programme de fidélité</p>
    </div>
  )
}

export default Loyalty
