import { connect, useDispatch } from 'react-redux'
import moment from 'moment'
import React, { useEffect } from 'react'

import BodyClassName from 'components/Common/BodyClassName'

import { getNextAvailableDay } from 'reducers'

import { block } from 'utils/classnames'

import './NoShiftToday.sass'
import { HIDE_NO_SHIFT_TODAY_MODAL, SHOW_NO_SHIFT_TODAY_MODAL } from 'actions/home/delivery'

const b = block.with('no-shift-today')

const body = {
  tomorrow: 'Commandez dès maintenant pour les jours à venir.',
  later: 'Commandez pour le %s ou plus tard.',
  never: 'On se retrouve le %s ou plus tard.'
}

export const NoShiftTodayModal = props => {
  const {
    when,
    nextAvailableDate,
    onClose
  } = props

  return (
    <div className={b('', { when })}>
      <BodyClassName className={b('')} />
      <div className={b('main')}>
        <h4 className={b('title')}>
          Ça ne sera pas pour aujourd'hui !
        </h4>
        <p className={b('body')}>
          {
            body[when].replace(
              '%s',
              moment(nextAvailableDate).format('D MMMM')
            )
          }
        </p>
      </div>
      <button className={b('dismiss')} onClick={onClose}>
        Fermer
      </button>
    </div>
  )
}

export const NoShiftToday = props => {
  const {
    nextAvailableDate = null
  } = props
  const dispatch = useDispatch()
  const [shouldShow, setShoudShow] = React.useState(true)

  const handleClose = React.useCallback(() => {
    setShoudShow(false)
    dispatch({ type: HIDE_NO_SHIFT_TODAY_MODAL })
  }, [])

  const when = React.useMemo(() => {
    const today = moment()

    const isBeforeTwoPM = today.hours() < 14
    const nextAvailable = moment(nextAvailableDate)

    if (
      !nextAvailableDate ||
        !isBeforeTwoPM ||
        !nextAvailable.isValid()
    ) {
      return null
    }

    if (
      today.format('YYYY-MM-DD') === nextAvailable.format('YYYY-MM-DD')
    ) {
      return null
    }

    const diff = nextAvailable.diff(today, 'days', true)

    if (diff < 2) {
      return 'tomorrow'
    }
    if (diff < 4) {
      return 'later'
    }
    return 'never'
  }, [nextAvailableDate])

  useEffect(() => {
    if (shouldShow && when !== null) dispatch({ type: SHOW_NO_SHIFT_TODAY_MODAL })
  }, [shouldShow, when])

  return shouldShow && when !== null
    ? (
      <NoShiftTodayModal
        when={when}
        nextAvailableDate={nextAvailableDate}
        onClose={handleClose}
      />
    )
    : null
}

export const ConnectedNoShiftToday = connect(
  (state, props) => ({
    nextAvailableDate: getNextAvailableDay(state, props)
  })
)(NoShiftToday)

export default NoShiftToday
