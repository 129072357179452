import PropTypes from 'prop-types'
import React from 'react'

import { RegisterForm } from 'components/user/RegisterForm/RegisterForm'

import './SignUp.sass'

export const SignUp = props => {
  const {
    user,
    errors,
    onSubmit,
    isLoading
  } = props

  return (
    <>
      <h3>
        Créer mon compte
      </h3>
      <RegisterForm
        onSubmit={onSubmit}
        userData={user}
        error={errors}
        isLoading={isLoading}
        className='basket-sign-up'
        forceAgreement
      />
    </>
  )
}

SignUp.propTypes = {
  user: PropTypes.object,
  errors: PropTypes.object,
  onSubmit: PropTypes.func,
  isLoading: PropTypes.bool
}

export default SignUp
