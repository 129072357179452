import { useState, useEffect } from 'react'

export const useScript = (src, attrs = {}, shouldCleanUp = true) => {
  const [isReady, updateIsReady] = useState(false)
  const setIsReady = () => updateIsReady(true)

  useEffect(() => {
    let script = null

    if (src) {
      script = document.querySelector(`script[src="${src}"]`)
      if (!script) {
        script = document.createElement('script')
        script.src = src
        script.async = true

        if (attrs) {
          Object
            .entries(attrs)
            .forEach(([key, value]) => {
              script[key] = value
            })
        }
        document.body.appendChild(script)
        script.addEventListener('load', setIsReady)
      } else {
        setIsReady()
      }
    }

    return () => {
      script && script.removeEventListener('load', setIsReady)
      shouldCleanUp && script && document.body.removeChild(script)
    }
  }, [src])

  return isReady
}
