import React, { useEffect, useState, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { usePrevious } from 'hooks'

import { block } from 'utils/classnames'

import MultiStepPanel from 'components/Common/Panel/MultiStepPanel'
import PanelContent from 'components/Common/Panel/PanelContent'
import PaymentForm from './PaymentForm'
import NewCreditCard from './NewCreditCard'
import PaymentAuthorizationFrame from './PaymentAuthorizationFrame'

import {
  addCreditCard,
  confirmPayment,
  clearPaymentAuthorizationUrl,
  setPaymentWaitingAuthorization
} from 'actions/home/checkout'
import { reloadCart } from 'actions/home/cart'

import {
  getPaymentAuthorizationUrl,
  getPaymentMethod,
  getSelectedPaymentProvider
} from 'reducers'
import { CREDIT_CARD_ID } from 'config/paymentMethods'

import './BasketPayment.sass'

const b = block.with('basket-payment')

export const BasketPayment = props => {
  const {
    onBack,
    onNext,
    authUrl,
    provider,
    fromPanel = false,
    previousCardLength
  } = props

  const [view, setView] = useState('payment-form')

  const dispatch = useDispatch()

  const previousAuthUrl = usePrevious(
    authUrl
  )

  const goToCreditCardForm = useCallback(
    () => {
      setView('credit-card-form')
    },
    []
  )

  const goToPaymentForm = useCallback(
    () => {
      setView('payment-form')
    },
    []
  )

  const goToAuthFrame = useCallback(
    () => {
      setView('auth-frame')
    },
    []
  )

  const closeAuthFrame = useCallback(
    () => {
      goToPaymentForm()
      dispatch(clearPaymentAuthorizationUrl())
      dispatch(setPaymentWaitingAuthorization(false))
    },
    []
  )

  useEffect(() => {
    if (!fromPanel) {
      (async function () {
        await dispatch(reloadCart({ loadCartPayment: true, withPaymentMethods: true }))
      })()
    }
  }, [])

  useEffect(() => {
    if (!previousAuthUrl && authUrl) {
      goToAuthFrame()
    } else if (previousAuthUrl && !authUrl) {
      closeAuthFrame()
    }
  }, [authUrl])

  return (
    <MultiStepPanel
      active={view}
      className={b('panel', { view })}
      reverse={view === 'payment-form'}
    >
      <PanelContent
        onBack={onBack}
        title='Mon paiement'
        className={b('', { payment: true })}
        id='payment-form'
      >
        <PaymentForm
          onNext={onNext}
          onAddCreditCard={goToCreditCardForm}
          previousCardLength={previousCardLength}
        />
      </PanelContent>
      <PanelContent
        onBack={goToPaymentForm}
        title='Ma nouvelle carte'
        id='credit-card-form'
      >
        <NewCreditCard
          onAddCard={addCreditCard}
          onSubmit={goToPaymentForm}
        />
      </PanelContent>
      <PanelContent
        onBack={closeAuthFrame}
        title='Mon paiement'
        id='auth-frame'
      >
        <PaymentAuthorizationFrame
          src={authUrl}
          onConfirm={confirmPayment}
          onSuccess={onNext}
          provider={provider}
        />
      </PanelContent>
    </MultiStepPanel>
  )
}

const ConnectedBasketPayment = (props) => {
  const paymentAuthorizationUrl = useSelector(getPaymentAuthorizationUrl)
  const provider = useSelector(getSelectedPaymentProvider)
  const { cards = [] } = useSelector(state => getPaymentMethod(state, { paymentMethodId: CREDIT_CARD_ID })) || {}
  const previousCardLength = usePrevious(cards.length)

  return (
    <BasketPayment
      {...props}
      authUrl={paymentAuthorizationUrl}
      provider={provider}
      previousCardLength={previousCardLength}
    />
  )
}

export default ConnectedBasketPayment
