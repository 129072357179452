import PropTypes from 'prop-types'
import React, { useMemo } from 'react'

import { block } from 'utils/classnames'

import './Preparation.sass'

const b = block.with('product-preparation')

const typeLabels = {
  pan: 'à la poële',
  oven: 'au four',
  microwave: 'au micro-ondes',
  default: ''
}

const formatDuration = duration => (
  duration
    .toLowerCase()
    .replace(/\s?min\s?/, 'mn')
    .replace(/\s?sec\s?/, '')
)

const getOptionLabel = (option, type) => (
  `${
    formatDuration(option.duration)
  } ${
    typeLabels[type] || typeLabels.default
  }${
    option.power ? ', ' + option.power : ''
  }`.trim()
)

export const Preparation = (props) => {
  const {
    preparation,
    productType
  } = props

  const preparationOptions = useMemo(() => {
    if (!preparation) {
      return []
    }
    return Object
      .entries(preparation)
      .map(
        ([key, option]) => (
        option?.duration
          ? {
            ...option,
            type: key,
            label: getOptionLabel(option, key)
          }
          : null
        ))
      .filter(Boolean)
  }, [preparation])

  const isDrink = useMemo(() => (
    productType && productType.toUpperCase().includes('DRINK')
  ), [productType])

  if (!preparation) {
    return null
  }

  return (
    <div className={b()}>
      {
        !isDrink
          ? <h5 className={b('instructions')}>{preparation.instructions}</h5>
          : null
      }
      {
        preparationOptions.map(option => (
          <p key={option.type} className={b('option', { type: option.type })}>
            <span>
              {option.label}
              <small>{option.instructions}</small>
            </span>
          </p>
        ))
      }
      {
        preparationOptions.length === 0
          ? (
            <p key='cold' className={b('option', { type: 'cold' })}>
              <span>
                {isDrink ? 'Cette boisson se boit froide' : 'Ce plat se mange froid'}
              </span>
            </p>
          )
          : null
      }
    </div>
  )
}
Preparation.propTypes = {
  preparation: PropTypes.shape({
    pan: PropTypes.shape({
      power: PropTypes.string,
      instructions: PropTypes.string,
      duration: PropTypes.string
    }),
    oven: PropTypes.shape({
      power: PropTypes.string,
      instructions: PropTypes.string,
      duration: PropTypes.string
    }),
    microwave: PropTypes.shape({
      power: PropTypes.string,
      instructions: PropTypes.string,
      duration: PropTypes.string
    }),
    instructions: PropTypes.string
  }),
  productType: PropTypes.string
}
export default Preparation
