import _sortBy from 'lodash/sortBy'
import PropTypes from 'prop-types'
import React, { useCallback, useMemo } from 'react'

import Timeslot from './Timeslot'

import { block } from 'utils/classnames'

import './MealTimeslots.sass'

const b = block.with('shift-timeslots')

const MEAL_LABEL = {
  DINNER: 'Dîner',
  LUNCH: 'Déjeuner'
}

export const MealTimeslots = props => {
  const {
    shift,
    activeTimeslot = '',
    displayMealSettingName = true,
    onClick
  } = props

  const handleClick = useCallback(
    onClick && onClick(shift),
    [onClick, shift]
  )

  const timeslots = useMemo(() => (
    Array.isArray(shift.timeslots)
      ? _sortBy(shift.timeslots, ['deliveryStartDate'])
      : []
  ), [shift])

  return (
    <ul className={b('', { meal: shift.meal.toLowerCase() })}>
      {
        displayMealSettingName
          ? (
            <li key='shift-setting-name' className={b('name')}>
              {MEAL_LABEL[shift.meal.toUpperCase()] || shift.meal}
            </li>
          )
          : null
      }
      {
        timeslots.map(timeslot => (
          <li key={timeslot.idShiftTimeslot} className={b('item')}>
            <Timeslot
              timeslot={timeslot}
              isActive={!!activeTimeslot && activeTimeslot === timeslot.idShiftTimeslot}
              onClick={handleClick}
            />
          </li>
        ))
      }
    </ul>
  )
}
MealTimeslots.propTypes = {
  shift: PropTypes.shape({
    timeslot: PropTypes.arrayOf(PropTypes.shape({
      idShiftTimeslot: PropTypes.number,
      isOpen: PropTypes.bool
    })),
    meal: PropTypes.string,
    idShift: PropTypes.number
  }),
  activeTimeslot: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  displayMealSettingName: PropTypes.bool,
  onClick: PropTypes.func.isRequired
}

export default MealTimeslots
