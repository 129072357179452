import { connect } from 'react-redux'
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import { getPaymentMethod, getPayableAmount } from 'reducers'

import { ApplePaySelector } from './ApplePaySelector'
import { CreditCardForm } from 'components/Basket/BasketPayment/PaymentMethods/CreditCard'

export const ApplePay = ({
  isActive = true,
  isSelected = false,
  payableAmount = 0,
  onSubmit,
  onClick,
  paymentRequest
}) => {
  const [shouldSubmit, setShouldSubmit] = useState(false)
  const [paymentMethodId, setPaymentMethodId] = useState('')

  useEffect(() => {
    if (shouldSubmit) {
      onSubmit && onSubmit()
      setShouldSubmit(false)
    }
  }, [shouldSubmit])

  useEffect(() => {
    if (paymentRequest) {
      paymentRequest.on('paymentmethod', event => {
        if (event?.paymentMethod?.id) {
          setPaymentMethodId(event.paymentMethod.id)
          setShouldSubmit(true)
        }
        event.complete('success')
      })
    }
  }, [paymentRequest])

  return (
    <>
      {!!paymentRequest && (
        <ApplePaySelector
          isActive={isActive}
          onClick={onClick}
          isSelected={isSelected}
          payableAmount={payableAmount}
        />
      )}
      {
        isSelected
          ? (
            <>
              <CreditCardForm
                paymentMethodId={paymentMethodId}
              />
              <input
                type='hidden'
                name='provider'
                value='stripe'
              />
              <input
                type='hidden'
                name='tokenizationMethod'
                value='apple_pay'
              />
            </>
          )
          : null
      }
    </>
  )
}

ApplePay.propTypes = {
  isActive: PropTypes.bool,
  isSelected: PropTypes.bool,
  payableAmount: PropTypes.number,
  onClick: PropTypes.func,
  onSubmit: PropTypes.func,
  paymentRequest: PropTypes.object
}

export const ConnectedApplePay = connect(
  (state, props) => {
    const paymentMethod = getPaymentMethod(state, props) || {}
    const payableAmount = getPayableAmount(state, props)

    return {
      ...paymentMethod,
      payableAmount,
      isActive: !!paymentMethod.isActive && payableAmount > 0
    }
  }
)(ApplePay)

export default ApplePay
