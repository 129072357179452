import { connect } from 'react-redux'

import {
  getSelectedTimeslot,
  getMe,
  getAvailableShifts,
  getSelectedShiftId,
  getCheckoutIsLoading,
  getDropOff,
  getCartDelivery,
  getFormattedCartAddress
} from 'reducers'

import { updateDeliveryFromShift } from 'actions/home/delivery'
import { setDropOff } from 'actions/home/dropOff'

import BasketDelivery from './BasketDelivery'
import { DEFAULT_DROPOFF_DETAILS } from 'utils/delivery'

// This is just a temporary fix until the drop-off is stored in the database
const getStoredDropOff = address => {
  const localStoredDropoff = typeof window !== 'undefined' ? localStorage.getItem('userDefinedDropoff') : null
  const dropOffList = localStoredDropoff ? JSON.parse(localStoredDropoff) : []
  if (!address || !Array.isArray(dropOffList)) return DEFAULT_DROPOFF_DETAILS
  const addressRelatedDropOff = dropOffList.find(dropOffItem => dropOffItem.address === address?.address)
  return addressRelatedDropOff ? { dropOff: addressRelatedDropOff.dropOff, comment: addressRelatedDropOff.comment } : DEFAULT_DROPOFF_DETAILS
}

export const ConnectedBasketDelivery = connect(
  (state, props) => {
    const { dropOff = null, comment = null } = getDropOff(state) || {}
    const { defaultDropOff = null, defaultDropOffComment = null, idArea = null } = getCartDelivery(state) || {}

    const address = getFormattedCartAddress(state, props)
    const localStoredDropoff = getStoredDropOff(address)
    return {
      address: getFormattedCartAddress(state, props),
      timeslot: getSelectedTimeslot(state, props),
      user: getMe(state, props),
      shifts: getAvailableShifts(state, props),
      selectedShiftId: getSelectedShiftId(state, props),
      areaId: idArea,
      mealSetting: state?.home?.delivery?.address?.selected?.mealSetting || null,
      forceMealSetting: state?.home?.delivery?.timeslot?.forceMealSetting || null,
      dropOff: dropOff || localStoredDropoff?.dropOff,
      dropOffComment: comment || localStoredDropoff?.comment,
      defaultDropOff,
      defaultDropOffComment,
      isLoading: getCheckoutIsLoading(state, props)
    }
  },
  dispatch => ({
    onSelectTimeslot: (shiftId, timeslotId, address) => dispatch(updateDeliveryFromShift(shiftId, timeslotId, address)),
    onChangeDropOff: (dropOffDetails, address) => dispatch(setDropOff(dropOffDetails, address))
  })
)(BasketDelivery)

export default ConnectedBasketDelivery
