import PropTypes from 'prop-types'
import React from 'react'

import TimeslotLabel from 'components/Common/Delivery/Timeslot'

import { block } from 'utils/classnames'

import './TimeslotInput.sass'

const b = block.with('timeslot-input')

export const TimeslotInput = props => {
  const {
    selected = null,
    onClick,
    disabled = false,
    className = ''
  } = props

  return (
    <button
      className={`${b('', { disabled, empty: !selected })} ${className}`.trim()}
      onClick={onClick}
      disabled={disabled}
    >
      <TimeslotLabel
        start={selected && selected.deliveryStartDate}
        end={selected && selected.deliveryEndDate}
        fallback='À quelle heure ?'
      />
    </button>
  )
}
TimeslotInput.propTypes = {
  selected: PropTypes.shape({
    deliveryStartDate: PropTypes.string,
    deliveryEndDate: PropTypes.string
  }),
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  className: PropTypes.string
}
export default TimeslotInput
