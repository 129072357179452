import { connect } from 'react-redux'

import {
  updateCartCutlery as setCutleryQuantity,
  dismissCutleryWarning
} from 'actions/home/cart'

import {
  isCartLoading,
  getCartProductsWithoutCutlery,
  getCartCutlery,
  getTotalCartQuantity,
  getTotalAmount
} from 'reducers'

import BasketContent from './BasketContent'

const ConnectedBasketContent = connect(
  (state, props) => ({
    isLoading: isCartLoading(state, props),
    cartProductsWithoutCutlery: getCartProductsWithoutCutlery(state, props),
    publications: state?.home?.publications?.byId || {},
    cutlery: getCartCutlery(state, props),
    basketQuantity: getTotalCartQuantity(state, props),
    totalDue: getTotalAmount(state, props)
  }),
  {
    setCutleryQuantity,
    dismissCutleryWarning
  }
)(BasketContent)

export default ConnectedBasketContent
