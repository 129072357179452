import { connect } from 'react-redux'

import PropTypes from 'prop-types'
import React, { useCallback, useEffect, useState } from 'react'

import AnimatedModal from 'components/Common/AnimatedModal'

import { block } from 'utils/classnames'

import {
  getWebappDevice,
  isSoHappyNewUser,
  getMe,
  getNewsletterSubscription
} from 'reducers'

import { subscribeToNewsletter } from 'actions/user'

import './WelcomeModal.sass'

const b = block.with('welcome-modal')

export const WelcomeModal = props => {
  const {
    firstName = '',
    email = '',
    isActive = false,
    hasSuccess = false,
    isLoading = false,
    onConfirm
  } = props

  const [shouldShow, setShouldShow] = useState(false)
  useEffect(() => {
    setShouldShow(isActive)
  }, [isActive])

  const handleClose = useCallback(() => {
    setShouldShow(false)
  }, [])

  const handleConfirm = useCallback(() => {
    onConfirm && onConfirm(email)
  }, [onConfirm, email])

  return (
    <AnimatedModal
      className={b()}
      shouldShow={shouldShow}
      onClick={handleClose}
      isFullsSreenOnMobile
    >
      <div className={b('content')}>
        <button
          className={b('close')}
          onClick={handleClose}
          disabled={isLoading}
          aria-label='Fermer la modale'
        />
        <h4 className={b('title')}>
          {
            hasSuccess
              ? 'Inscription validée'
              : 'Recevez le menu du jour dans votre boîte mail'
          }
        </h4>
        <p className={b('body')}>
          {
            hasSuccess
              ? (
              `Merci ${firstName}, c’est bien enregistré. Vous recevrez nos newsletters très bientôt ;)`
              )
              : 'L’actualité de FoodChéri dans votre entreprise et les dernières nouveautés à la carte'
          }
        </p>
        <button
          className={b('confirm')}
          disabled={!hasSuccess && isLoading}
          onClick={hasSuccess ? handleClose : handleConfirm}
        >
          {hasSuccess ? 'Découvrir la carte' : "M'inscrire"}
        </button>
        {
          hasSuccess
            ? null
            : (
              <button
                className={b('cancel')}
                onClick={handleClose}
                disabled={isLoading}
              >
              Non merci
              </button>
            )
        }
      </div>
    </AnimatedModal>
  )
}
WelcomeModal.propTypes = {
  firstName: PropTypes.string,
  email: PropTypes.string,
  isLoading: PropTypes.bool,
  hasSuccess: PropTypes.bool,
  onConfirm: PropTypes.func
}

export const ConnectedWelcomeModal = connect(
  (state, props) => {
    const isSoHappy = getWebappDevice(state, props) === 'so-happy'
    const isNew = isSoHappyNewUser(state, props)
    const user = getMe(state, props)
    const newsletterSubscription = getNewsletterSubscription(state, props)

    return {
      isActive: isSoHappy && isNew && !!user.email,
      firstName: user?.firstName,
      email: user?.email,
      hasSuccess: !!newsletterSubscription?.message,
      isLoading: !!newsletterSubscription?.isLoading
    }
  },
  dispatch => ({
    onConfirm: (email) => dispatch(subscribeToNewsletter(email))
  })
)(WelcomeModal)

export default WelcomeModal
