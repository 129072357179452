import { connect } from 'react-redux'

import TimeslotSelector from './TimeslotSelector'

import {
  getAvailableShiftsByActiveDay,
  getSelectedTimeslotId,
  getForcedMealSetting,
  getIsTimeslotTooltipDisplayed,
  getFormattedCartAddress
} from 'reducers'

import {
  updateDeliveryFromShift as onChange
} from 'actions/home/delivery'

export const ConnectedTimeslotSelector = connect(
  (state, props) => {
    const { address, latitude, longitude } = getFormattedCartAddress(state, props)
    return {
      shifts: getAvailableShiftsByActiveDay(state, props),
      selectedTimeslotId: getSelectedTimeslotId(state, props),
      forcedMealSetting: getForcedMealSetting(state, props),
      showTooltipNoTimeslot: getIsTimeslotTooltipDisplayed(state, props),
      disabled: !(address && latitude && longitude)
    }
  },
  {
    onChange
  }
)(TimeslotSelector)

export default TimeslotSelector
