import { block } from 'utils/classnames'

import './LoyaltyCardItem.sass'

const b = block.with('loyalty-card-item')

const LoyaltyCardItem = ({ label, active = false, toActivate = false }) => {
  return (
    <div className={b('')}>
      {
        toActivate ? (
          <div className={b('label', { fake: true })}>{label}</div>
        ) : null
      }
      <div className={b('label', { active, toActivate })}>{label}</div>
      <div className={b('mark', { active, toActivate })}>
        <img src='/images/svg-icons/check-purple-stroke.svg' alt='status' />
      </div>
    </div>
  )
}

export default LoyaltyCardItem
