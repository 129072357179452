import PropTypes from 'prop-types'
import React from 'react'

import { block } from 'utils/classnames'

import { SlideshowItem } from './SlideshowItem'

import './SlideshowContent.sass'

const b = block.with('product-slideshow-content')

export const SlideshowContent = props => {
  const {
    mayClose,
    onClose,
    onMouseMove,
    items,
    slideDuration = 10000
  } = props

  const [active, setActive] = React.useState(0)

  const goToNext = React.useCallback(() => setActive(state => (state + 1) % items.length))

  React.useEffect(() => {
    const interval = setInterval(goToNext, slideDuration)

    return () => {
      clearInterval(interval)
    }
  }, [])

  return (
    <main onMouseMove={onMouseMove} className={b()}>
      {
        items.map((item, i) => (
          <SlideshowItem
            key={item.id}
            shouldShow={i === active}
            product={item}
          />
        ))
      }
      {
        mayClose
          ? (
            <button type='button' onClick={onClose} className={b('close')}>
              <svg width='60' height='60' viewBox='0 0 60 60' fill='none' xmlns='http://www.w3.org/2000/svg'>
                <circle cx='30' cy='30' r='30' fill='white' />
                <path fill-rule='evenodd' clip-rule='evenodd' d='M38.5495 18.6925C38.9427 18.2992 39.4745 18.0317 40.0299 18.0012C41.1602 17.9391 42.0611 18.8399 41.9989 19.9703C41.9684 20.5256 41.7009 21.0574 41.3077 21.4506L32.2601 30.4982L41.3077 39.5457C41.7009 39.9389 41.9684 40.4708 41.9989 41.0261C42.0611 42.1564 41.1602 43.0573 40.0299 42.9951C39.4745 42.9646 38.9427 42.6971 38.5495 42.3039L29.502 33.2564L20.4544 42.3039C20.0612 42.6971 19.5294 42.9646 18.9741 42.9951C17.8437 43.0573 16.9429 42.1564 17.005 41.0261C17.0355 40.4708 17.303 39.939 17.6962 39.5457L26.7438 30.4982L17.6962 21.4506C17.303 21.0574 17.0355 20.5256 17.005 19.9703C16.9429 18.8399 17.8437 17.9391 18.9741 18.0012C19.5294 18.0317 20.0612 18.2992 20.4544 18.6925L29.502 27.74L38.5495 18.6925Z' fill='#6C6C6C' />
              </svg>
              <span>
                <strong>Quitter le diaporama</strong>
                Ou appuyez sur Echap
              </span>
            </button>
          )
          : null
      }
    </main>
  )
}
SlideshowContent.propTypes = {
  mayClose: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onMouseMove: PropTypes.func.isRequired,
  items: PropTypes.array.isRequired
}
export default SlideshowContent
