import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import React from 'react'

import {
  getPaymentMethod,
  getCustomerCreditAmount,
  getPayableAmount
} from 'reducers'

import { block } from 'utils/classnames'
import { eur } from 'utils/numeral'

import './CustomerCredit.sass'

const b = block.with('customer-credit-selector')

export const CustomerCredit = props => {
  const {
    isDisabled = false,
    amount = 0
  } = props

  if (!amount || amount <= 0) {
    return null
  }

  return (
    <div className={b('', { disabled: isDisabled, active: !isDisabled })}>
      <span className={b('text')}>
        Ma cagnotte FoodChéri
      </span>
      <span className={b('amount')}>
        {eur(amount)}
      </span>
    </div>
  )
}

CustomerCredit.propTypes = {
  isActive: PropTypes.bool,
  amount: PropTypes.number
}

export const ConnectedCustomerCredit = connect(
  (state, props) => {
    const paymentMethod = getPaymentMethod(state, props) || {}
    const amount = Math.min(
      getPayableAmount(state, props, false),
      getCustomerCreditAmount(state, props)
    )

    return {
      ...paymentMethod,
      amount
    }
  }
)(CustomerCredit)

export default CustomerCredit
