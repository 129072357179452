import PropTypes from 'prop-types'
import React from 'react'

import { block } from 'utils/classnames'

import './PanelHeader.sass'

const b = block.with('basket-panel-header')

export const PanelHeader = (props) => {
  const {
    title,
    onBack
  } = props

  return (
    <header className={b('')}>
      {
        onBack
          ? (
            <button
              className={b('button')}
              onClick={onBack}
            />
          )
          : null
      }
      <h3 className={b('title')}>
        {title}
      </h3>
    </header>
  )
}
PanelHeader.propTypes = {
  title: PropTypes.string,
  onBack: PropTypes.func
}
export default PanelHeader
