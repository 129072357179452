import * as cartActions from 'actions/home/cart'
import * as deliveryActions from 'actions/home/delivery'
import { START_PAGE_LOADING_ACTION, END_PAGE_LOADING_ACTION } from 'actions/page/loading'
import { getCartDelivery, getAvailableShifts, getIsCartOutdated, getSelectedTimeslotId } from 'reducers'
import { DEFAULT_ADDRESS } from 'config/delivery'
import { getParamsFromURL } from 'utils/url'
import { getShiftFromMenuOrMeal, getFirstAvailableShift, getShiftFromTimeslotId } from 'utils/delivery'

export const initializeDelivery = () => async (dispatch, getState) => {
  await dispatch({ type: START_PAGE_LOADING_ACTION })
  await dispatch(cartActions.recoverCartFromCookie())
  const cartDelivery = getCartDelivery(getState())

  const address = cartDelivery?.address && cartDelivery?.latitude && cartDelivery?.longitude
    ? {
      address: cartDelivery.address,
      latitude: cartDelivery.latitude,
      longitude: cartDelivery.longitude
    }
    : DEFAULT_ADDRESS

  address.name = cartDelivery.addressName || null

  await dispatch(deliveryActions.getSlots(address))
  const availableShifts = getAvailableShifts(getState())
  const cartTimeslotId = cartDelivery?.idShiftTimeslot || null
  const { menu, meal } = getParamsFromURL(['menu', 'meal'])
  const selectedShift = menu || meal
    ? getShiftFromMenuOrMeal(availableShifts, { menu, meal })
    : getShiftFromTimeslotId(availableShifts, cartTimeslotId) || getFirstAvailableShift(availableShifts)
  if (selectedShift) {
    if (!address?.isDefault) {
      await dispatch(deliveryActions.selectDay(selectedShift.date.substr(0, 10)))
      let timeslotIdToSelect = cartTimeslotId
      if (!selectedShift?.timeslots?.find(timeslot => timeslot.isOpen && timeslot.idShiftTimeslot === cartTimeslotId)) {
        timeslotIdToSelect = selectedShift?.timeslots?.find(timeslot => timeslot.isOpen)?.idShiftTimeslot
      }
      await dispatch(deliveryActions.selectTimeslot(selectedShift.idShift, timeslotIdToSelect))
      if (getIsCartOutdated(getState())) {
        const selectedTimeslotId = getSelectedTimeslotId(getState())
        const areaId = cartDelivery?.idArea
        await dispatch(cartActions.initializeCart(selectedTimeslotId, areaId, address))
      }
    }
  }
  await dispatch({ type: END_PAGE_LOADING_ACTION })
}
