import PropTypes from 'prop-types'
import React, { useMemo, useState } from 'react'

import Modal from 'components/Common/Modal'
import EcoScoreModal from './EcoScoreModal'

import { block } from 'utils/classnames'

import './NutriRecap.sass'

const b = block.with('nutri-recap-container')

export const NutriRecap = (props) => {
  const {
    nutriscore = null,
    ecoscore = null,
    calories
  } = props

  const [isShown, setIsShown] = useState(false)
  const onClose = () => setIsShown(false)

  const kcals = useMemo(() => (
    calories && !isNaN(parseInt(calories)) ? parseInt(calories) : null
  ), [calories])

  return (
    <>
      <div className={b()}>
        <div className={b('nutri-recap')}>
          {
            nutriscore
              ? (
                <div key='nutriscore'>
                  <span className={b('nutriscore', { nutriscore })}>
                  Nutriscore {nutriscore}
                  </span>
                </div>
              )
              : null
          }
          {
            ecoscore
              ? (
                <div key='ecoscore'>
                  <button onClick={() => setIsShown(true)} className={b('ecoscore', { ecoscore })}>
                  Ecoscore {ecoscore}
                  </button>
                </div>
              )
              : null
          }
          {
            kcals
              ? (
                <div key='calories' className={b('calories')}>
                  <span>{kcals}</span>
                  <small>Kcal</small>
                </div>
              )
              : null
          }
        </div>
      </div>
      <Modal
        shouldShow={isShown}
        onClose={onClose}
      >
        <EcoScoreModal onClose={onClose} ecoscore={ecoscore} />
      </Modal>
    </>
  )
}
NutriRecap.propTypes = {
  description: PropTypes.string,
  nutriscore: PropTypes.oneOf([
    'A', 'B', 'C', 'D', 'E'
  ]),
  ecoscore: PropTypes.oneOf([
    'A', 'B', 'C', 'D', 'E'
  ]),
  calories: PropTypes.oneOfType([
    PropTypes.number, PropTypes.string
  ])
}
export default NutriRecap
