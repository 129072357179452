import PropTypes from 'prop-types'
import { useMemo } from 'react'

import { getPrepTag } from 'utils/publications'

/**
 * PrepTag - functional component to extract preconfigured preparation tags from a tag array of objects
 * @param { object[] } tags - The array of tag object to search
 * @returns { string|null } The string name if at least one match is found, null otherwise
 */
export const PrepTag = ({ tags = [] }) => {
  const prepTag = useMemo(() => getPrepTag(tags), [tags])

  return prepTag && prepTag.name
}

PrepTag.propTypes = {
  tags: PropTypes.array
}

export default PrepTag
