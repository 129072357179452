import PropTypes from 'prop-types'
import React from 'react'

import AnimatedModal from 'components/Common/AnimatedModal'
import MealTimeslots from 'components/DeliveryBar/TimeslotSelector/TimeslotSelect/MealTimeslots'
import TimeslotLabel from 'components/Common/Delivery/Timeslot'

import { block } from 'utils/classnames'

import './UnavailableTimeSlotModal.sass'

const b = block.with('unavailable-timeslot-modal')

export const UnavailableTimeSlotModal = props => {
  const {
    className = '',
    isOpen = false,
    onClose,
    shifts,
    forcedMealSetting,
    activeTimeslot,
    onClick,
    selectedTimeslot = null
  } = props

  const [isDropdownOpen, setIsDropdownOpen] = React.useState(false)

  React.useEffect(() => {
    if (!isOpen) {
      setIsDropdownOpen(false)
    }
  }, [isOpen])

  const availableShifts = React.useMemo(() => (
    shifts.filter(shift => {
      if (!shift) {
        return false
      }
      if (forcedMealSetting && shift.meal !== forcedMealSetting) {
        return false
      }
      if (
        !Array.isArray(shift.timeslots) ||
          shift.timeslots.some(timeslot => timeslot.isOpen) === false
      ) {
        return false
      }
      return true
    })
  ), [shifts, forcedMealSetting])

  return (
    <AnimatedModal
      shouldShow={isOpen}
      className={`${className} ${b()}`.trim()}
      onClick={onClose}
    >
      <div className={b('content')}>
        <button className={b('close')} onClick={onClose} aria-label='Fermer la modale' />
        <div className={b('body')}>
          <h4>Horaire de livraison non disponible</h4>
          <p>Le créneau que vous aviez choisi est indisponible.</p>
          <p>Veuillez en choisir un autre.</p>
          <div
            className={b('dropdown-trigger')} onClick={() => setIsDropdownOpen(!isDropdownOpen)}
          >
            <i className={b('chevron', { open: isDropdownOpen })} />
            <button>
              <TimeslotLabel
                start={selectedTimeslot && selectedTimeslot.deliveryStartDate}
                end={selectedTimeslot && selectedTimeslot.deliveryEndDate}
                fallback={'Choisissez un créneau'.toUpperCase()}
              />
            </button>
            {
              isDropdownOpen
                ? (
                  <div className={b('timeslot-select')}>
                    {
                      availableShifts.map(mealSetting => {
                        return (
                          <MealTimeslots
                            key={mealSetting.idShift}
                            shift={mealSetting}
                            displayMealSettingName={availableShifts.length > 1}
                            activeTimeslot={activeTimeslot}
                            onClick={onClick}
                          />
                        )
                      })
                    }
                  </div>
                ) : null
            }
          </div>
        </div>
        <div className={b('footer')}>
          <p>Les produits disponibles sur la carte peuvent changer en fonction de votre horaire de livraison.</p>
        </div>
      </div>
    </AnimatedModal>
  )
}

UnavailableTimeSlotModal.propTypes = {
  className: PropTypes.string,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  shifts: PropTypes.array,
  forcedMealSetting: PropTypes.string,
  activeTimeslot: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  onClick: PropTypes.func.isRequired,
  selectedTimeslot: PropTypes.shape({
    deliveryStartDate: PropTypes.string,
    deliveryEndDate: PropTypes.string
  })
}

export default UnavailableTimeSlotModal
