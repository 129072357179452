import PropTypes from 'prop-types'
import React, { useMemo } from 'react'

import MealTimeslots from './MealTimeslots'

import { block } from 'utils/classnames'

import './TimeslotSelect.sass'

const b = block.with('timeslot-select')

export const TimeslotSelect = props => {
  const {
    isOpen = false,
    shifts = [],
    activeTimeslot = '',
    forcedMealSetting = '',
    onClick,
    className = ''
  } = props

  if (!isOpen || shifts.length === 0) {
    return null
  }

  const mealSettings = useMemo(() => (
    shifts.filter(mealSetting => {
      if (!mealSetting) {
        return false
      }
      if (forcedMealSetting && mealSetting.meal !== forcedMealSetting) {
        return false
      }
      if (
        !Array.isArray(mealSetting.timeslots) ||
          mealSetting.timeslots.some(t => t.isOpen) === false
      ) {
        return false
      }
      return true
    })
  ), [shifts, forcedMealSetting])

  return (
    <div className={`${b()} ${className}`.trim()}>
      {
        mealSettings.map(mealSetting => (
          <MealTimeslots
            key={mealSetting.idShift}
            shift={mealSetting}
            displayMealSettingName={mealSettings.length > 1}
            activeTimeslot={activeTimeslot}
            onClick={onClick}
          />
        ))
      }
    </div>
  )
}
TimeslotSelect.propTypes = {
  isOpen: PropTypes.bool,
  shifts: PropTypes.array,
  activeTimeslot: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  forcedMealSetting: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  className: PropTypes.string
}

export default TimeslotSelect
