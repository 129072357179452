import { useDispatch } from 'react-redux'
import React, { useCallback, useState } from 'react'

import RegisterForm from 'components/user/RegisterForm/RegisterForm'
import LoginForm from 'components/user/LoginForm/LoginForm'
import ResetPasswordForm from 'components/user/PasswordForm/ResetPasswordForm'

import { block } from 'utils/classnames'
import { clearLostPasswordMessages, clearUserErrors } from 'actions/user'

import './UserForm.sass'

export const UserForm = props => {
  const {
    onClose
  } = props

  const [status, setStatus] = useState('login')

  const dispatch = useDispatch()
  const updateStatus = useCallback(
    (newStatus) => {
      if (dispatch) {
        dispatch(clearUserErrors())
        dispatch(clearLostPasswordMessages())
      }
      setStatus(newStatus)
    },
    [dispatch]
  )

  const handleLogin = useCallback(
    () => updateStatus('login'),
    [updateStatus]
  )
  const handleRegister = useCallback(
    () => updateStatus('register'),
    [updateStatus]
  )
  const handlePassword = useCallback(
    () => updateStatus('password'),
    [updateStatus]
  )

  const handleClose = useCallback(
    () => {
      onClose && dispatch && dispatch(clearUserErrors())
      onClose && onClose()
    },
    [dispatch, onClose]
  )

  return (
    <div className={block('user-form')}>
      <i className={block('icon-close')} onClick={handleClose} />
      <div className={block('user-form', 'content', { isResetForm: status === 'password' })}>
        {
          status === 'login' ? (
            <>
              <LoginForm
                onClose={handleClose}
                handlePassword={handlePassword}
              />
              <div className={block('user-form', 'preview')}>
                <p className={block('user-form', 'preview-desktop')}>
                  Vous n'avez pas de compte?
                  <button onClick={handleRegister}>
                    Inscription
                  </button>
                </p>
                <p className={block('user-form', 'preview-mobile')}>
                  Pas de compte ?
                  <button onClick={handleRegister}>
                    S'inscrire
                  </button>
                </p>
              </div>
            </>
          ) : null
        }
        {
          status === 'register' ? (
            <>
              <RegisterForm forceAgreement onClose={handleClose} />
              <div className={block('user-form', 'preview')}>
                <span className={block('user-form', 'preview-desktop')}>Vous avez déjà un compte FoodChéri?
                  <button onClick={handleLogin}>Connexion</button>
                </span>
                <span className={block('user-form', 'preview-mobile')}>Déjà un compte?
                  <button onClick={handleLogin}>Connexion</button>
                </span>
              </div>
            </>
          ) : null
        }
        {
          status === 'password' ? (
            <ResetPasswordForm
              onClose={handleClose}
              handleLogin={handleLogin}
            />
          ) : null
        }
      </div>
    </div>
  )
}

export default UserForm
