import React from 'react'
import { useSelector } from 'react-redux'
import { block } from 'utils/classnames'

import { getTotalCartQuantity } from 'reducers'

import './CartMobileButton.sass'

const b = block.with('cart-mobile-button')

export const CartMobileButton = props => {
  const {
    quantity = 0,
    onClick
  } = props

  return (
    <button
      className={b('', { hidden: quantity < 1 })}
      onClick={onClick}

    >
      mon panier
      <span className={b('quantity')}>
        {quantity}
      </span>
    </button>
  )
}

export const ConnectedCartMobileButton = props => {
  const quantity = useSelector(getTotalCartQuantity)

  return <CartMobileButton {...props} quantity={quantity} />
}

export default CartMobileButton
