import { connect } from 'react-redux'
import { Elements, useStripe } from '@stripe/react-stripe-js'
import PropTypes from 'prop-types'
import React, {
  useCallback,
  useEffect,
  useRef,
  useState
} from 'react'

import {
  useScript
} from 'hooks/script'

import {
  useAlternativeButton
} from 'hooks/checkout'

import {
  getPaymentMethod,
  getPayableAmount
} from 'reducers'

import {
  GOOGLE_PAY_SCRIPT_SRC,
  getIsReadyToPayRequest,
  getPaymentDataRequest
} from 'utils/googlepay'
import { stripePromise } from 'config/stripe'

import { GooglePaySelector } from './GooglePaySelector'
import { CreditCardForm } from 'components/Basket/BasketPayment/PaymentMethods/CreditCard'

import './GooglePay.sass'

export const GooglePay = props => {
  const {
    isActive = true,
    isSelected = false,
    payableAmount = 0,
    onSubmit,
    onClick
  } = props

  const stripe = useStripe()
  const paymentsClient = useRef(null)
  const googlePayButton = useRef(null)
  const [isLoading, setIsLoading] = useState(true)
  const [isDisabled, setIsDisabled] = useState(!process.env.STRIPE_API_KEY)
  const [shouldSubmit, setShouldSubmit] = useState(false)
  const [paymentMethodId, setPaymentMethodId] = useState('')

  useEffect(() => {
    if (shouldSubmit) {
      onSubmit && onSubmit()
      setShouldSubmit(false)
    }
  }, [shouldSubmit])

  const googlePayIsReady = useScript(
    GOOGLE_PAY_SCRIPT_SRC,
    { async: true },
    false
  )

  const buttonContainer = useAlternativeButton(
    'googlepay',
    isActive && !isDisabled && googlePayIsReady,
    isSelected
  )

  const getPaymentsClient = useCallback(() => {
    if (!paymentsClient.current) {
      paymentsClient.current = new window.google.payments.api.PaymentsClient({
        environment: process.env.GOOGLE_PAY_ENV
      })
    }

    return paymentsClient.current
  })

  const handleCancel = useCallback(
    (e) => {
      setPaymentMethodId('')
      isSelected && onClick && onClick()
    }
  )

  const handlePaymentMethodReceived = response => {
    if (response.error || !response?.paymentMethod?.id) {
      handleCancel(response.error)
      return
    }
    setPaymentMethodId(response.paymentMethod.id)
    setShouldSubmit(true)
    !isSelected && onClick && onClick()
  }

  const createPaymentMethod = useCallback(
    (token) => {
      const card = {
        type: 'card',
        card: { token }
      }
      return stripe
        .createPaymentMethod('card', card)
        .then(handlePaymentMethodReceived)
    }
  )

  const handlePaymentDataReceived = useCallback(
    (response = {}) => {
      const { paymentMethodData = {} } = response
      let stripeId = ''
      if (paymentMethodData?.tokenizationData?.token) {
        try {
          const token = JSON.parse(paymentMethodData.tokenizationData.token)
          stripeId = token?.id
        } catch (e) {
          stripeId = ''
        }
      }
      stripeId && createPaymentMethod(stripeId)
    }
  )

  const handleSubmit = useCallback(
    (e) => {
      e.preventDefault()
      const paymentsClient = getPaymentsClient()
      const paymentDataRequest = getPaymentDataRequest(payableAmount)
      paymentsClient
        .loadPaymentData(paymentDataRequest)
        .then(handlePaymentDataReceived)
        .catch(handleCancel)
    }
  )

  const handleNotReadyToPay = useCallback(
    (e) => {
      setIsDisabled(true)
      isSelected && onClick && onClick()
    }
  )

  const addGooglePayButton = useCallback(
    () => {
      if (googlePayButton.current) {
        return null
      }
      const paymentsClient = getPaymentsClient()
      googlePayButton.current = paymentsClient.createButton({ onClick: handleSubmit })
      buttonContainer && buttonContainer.appendChild(googlePayButton.current)
    }
  )

  const prefetchGooglePaymentData = useCallback(
    () => {
      const paymentsClient = getPaymentsClient()
      const paymentDataRequest = getPaymentDataRequest()
      paymentsClient.prefetchPaymentData(paymentDataRequest)
    }
  )

  const handleReadyToPay = useCallback(
    (response) => {
      if (!response?.result) {
        handleNotReadyToPay()
        return
      }

      setIsDisabled(false)
      addGooglePayButton()
      prefetchGooglePaymentData()
    }
  )

  useEffect(() => {
    if (typeof window === 'undefined' || !googlePayIsReady || !stripe) {
      return
    }
    const paymentsClient = getPaymentsClient()
    const isReadyToPayRequest = getIsReadyToPayRequest()

    paymentsClient
      .isReadyToPay(isReadyToPayRequest)
      .then(handleReadyToPay)
      .catch(handleNotReadyToPay)
      .finally(() => {
        setIsLoading(false)
      })
  }, [stripe, googlePayIsReady])

  return stripe
    ? (
      <>
        <GooglePaySelector
          isActive={isActive}
          onClick={onClick}
          isLoading={isLoading}
          isDisabled={isDisabled}
          isSelected={isSelected}
          payableAmount={payableAmount}
        />
        {
          isSelected
            ? (
              <>
                <CreditCardForm
                  paymentMethodId={paymentMethodId}
                />
                <input
                  type='hidden'
                  name='provider'
                  value='stripe'
                />
                <input
                  type='hidden'
                  name='tokenizationMethod'
                  value='google_pay'
                />
              </>
            )
            : null
        }
      </>
    ) : null
}

GooglePay.propTypes = {
  isActive: PropTypes.bool,
  isSelected: PropTypes.bool,
  payableAmount: PropTypes.number,
  onClick: PropTypes.func,
  onSubmit: PropTypes.func
}

export const StripedGooglePay = props => {
  return (
    <Elements stripe={stripePromise}>
      <GooglePay {...props} />
    </Elements>
  )
}

export const ConnectedGooglePay = connect(
  (state, props) => {
    const paymentMethod = getPaymentMethod(state, props) || {}
    const payableAmount = getPayableAmount(state, props)

    return {
      ...paymentMethod,
      payableAmount,
      isActive: !!paymentMethod.isActive && payableAmount > 0
    }
  }
)(StripedGooglePay)

export default StripedGooglePay
