import React from 'react'

import { block } from 'utils/classnames'

import './LoyaltyGift.sass'

const b = block.with('loyalty-gift')

export const LoyaltyGift = (props) => {
  const {
    giftType = 'dessert',
    onClose
  } = props

  return (
    <div className={b('')}>
      <a className={b('close')} onClick={onClose} />
      <img className={b('image')} src='/images/basket/gift.svg' />
      <h3>Votre {giftType} offert</h3>
      <p>Votre code promo sera disponible dans votre espace Fidélité après réception de votre commande.</p>
    </div>
  )
}
