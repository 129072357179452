import PropTypes from 'prop-types'
import React, {
  useCallback,
  useEffect,
  useRef,
  useState
} from 'react'

import AnimatedModal from 'components/Common/AnimatedModal'

import { block } from 'utils/classnames'

import './EventModal.sass'

const b = block.with('event-modal')

export const EventModal = props => {
  const {
    idEvent = null,
    messageCtaMessage = null,
    messageTitle = null,
    messageContentPart1 = null,
    messageContentPart2 = null
  } = props

  // Keep track of the shown events to avoid showing the same event twice
  const events = useRef([idEvent])

  const [shouldShow, setShouldShow] = useState(!!idEvent)
  const handleClose = useCallback(() => {
    setShouldShow(false)
  }, [])

  useEffect(() => {
    if (!idEvent) {
      setShouldShow(false)
      return
    }
    if (events.current.includes(idEvent)) {
      return
    }
    events.current.push(idEvent)
    setShouldShow(true)
  }, [idEvent])

  return (
    <AnimatedModal
      className={b()}
      shouldShow={shouldShow}
      isFullsSreenOnMobile
    >
      <div className={b('content')}>
        <h2>{messageTitle}</h2>
        <p>{messageContentPart1}</p>
        <p>{messageContentPart2}</p>
        <button onClick={handleClose}>
          {messageCtaMessage || 'OK'}
        </button>
      </div>
    </AnimatedModal>
  )
}

EventModal.propTypes = {
  idEvent: PropTypes.number,
  messageCtaMessage: PropTypes.string,
  messageTitle: PropTypes.string,
  messageContentPart1: PropTypes.string,
  messageContentPart2: PropTypes.string
}
export default EventModal
