import PropTypes from 'prop-types'
import React from 'react'

import { block } from 'utils/classnames'

import './TimeslotTooltip.sass'

const b = block.with('timeslot-tooltip')

const TimeslotTooltip = (props = {}) => {
  const {
    show = false,
    className = ''
  } = props

  if (!show) { return null }

  return (
    <div className={`${b()} ${className}`.trim()}>
      <h4 className={b('title')}>
        On vous livre à quelle heure ?
      </h4>
      <p className={b('body')}>
        Indiquez votre horaire de livraison pour vérifier la disponibilité des produits.
      </p>
    </div>
  )
}
TimeslotTooltip.propTypes = {
  className: PropTypes.string,
  show: PropTypes.bool
}

export default TimeslotTooltip
