import { connect } from 'react-redux'

import DaySelector from './DaySelector'

import { updateDeliveryFromDay } from 'actions/home/delivery'

import {
  getAvailableDays,
  getSelectedDay,
  getCartUUID,
  getFormattedCartAddress
} from 'reducers'

export const ConnectedDaySelector = connect(
  (state, props) => {
    const { address, latitude, longitude } = getFormattedCartAddress(state, props)
    return {
      days: getAvailableDays(state, props),
      value: getSelectedDay(state, props),
      disabled: !(address && latitude && longitude),
      cartUUID: getCartUUID(state)
    }
  },
  { updateDeliveryFromDay }
)(DaySelector)

export default DaySelector
