import PropTypes from 'prop-types'
import React from 'react'

import AnimatedModal from 'components/Common/AnimatedModal'

import { block } from 'utils/classnames'

import './InactivityWarning.sass'

const b = block.with('inactivity-warning')

export const InactivityWarning = props => {
  const {
    shouldShow = false,
    isExpired = false,
    onClose
  } = props

  return (
    <AnimatedModal
      className={b('')}
      shouldShow={shouldShow}
      onClick={onClose}
    >
      <div className={b('content')}>
        <main className={b('main')}>
          <p className={b('body')}>
            {
              isExpired
                ? <span>Votre panier a expiré.<br />Soyez plus rapide la prochaine fois ;-)</span>
                : <span>Attention, votre panier<br />va bientôt expirer</span>
            }
          </p>
          <button onClick={onClose} className={b('cta')}>
            {
              isExpired
                ? 'Retour à la carte'
                : 'Je veux plus de temps'
            }
          </button>
        </main>
        {
          onClose
            ? (
              <button onClick={onClose} className={b('dismiss')}>
                Fermer
              </button>
            )
            : null
        }
      </div>
    </AnimatedModal>
  )
}
InactivityWarning.propTypes = {
  shouldShow: PropTypes.bool,
  isExpired: PropTypes.bool,
  onClose: PropTypes.func
}

export default React.memo(InactivityWarning)
