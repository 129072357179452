/*
    Google Pay API static params & util functions
    https://developers.google.com/pay/api/web/reference/object
*/
export const GOOGLE_PAY_SCRIPT_SRC = 'https://pay.google.com/gp/p/js/pay.js'

export const baseRequest = {
  apiVersion: 2,
  apiVersionMinor: 0,
  merchantInfo: {
    merchantName: 'FoodChéri'
  }
}
export const allowedCardNetworks = ['MASTERCARD', 'VISA']
export const allowedAuthMethods = ['PAN_ONLY', 'CRYPTOGRAM_3DS']

export const tokenizationSpecification = {
  type: 'PAYMENT_GATEWAY',
  parameters: {
    gateway: 'stripe',
    'stripe:version': '2019-05-16',
    'stripe:publishableKey': process.env.STRIPE_API_KEY
  }
}

export const baseCardPaymentMethod = {
  type: 'CARD',
  parameters: {
    allowedAuthMethods,
    allowedCardNetworks,
    billingAddressRequired: false
  }
}

export const cardPaymentMethod = {
  ...baseCardPaymentMethod,
  tokenizationSpecification
}

export const getIsReadyToPayRequest = () => ({
  ...baseRequest,
  allowedPaymentMethods: [baseCardPaymentMethod]
})

export const getTransactionInfo = (totalPrice = '0') => {
  return totalPrice
    ? {
      currencyCode: 'EUR',
      totalPriceStatus: 'FINAL',
      totalPrice: String(parseFloat(totalPrice).toFixed(2))
    }
    : {
      totalPriceStatus: 'NOT_CURRENTLY_KNOWN',
      currencyCode: 'EUR'
    }
}

export const getPaymentDataRequest = (payableAmount) => {
  const paymentDataRequest = {
    ...baseRequest,
    allowedPaymentMethods: [cardPaymentMethod],
    transactionInfo: getTransactionInfo(payableAmount)
  }
  if (process.env.GOOGLE_PAY_ENV === 'PRODUCTION' && process.env.GOOGLE_PAY_MERCHANT_ID) {
    paymentDataRequest.merchantInfo = {
      ...paymentDataRequest.merchantInfo,
      merchantId: process.env.GOOGLE_PAY_MERCHANT_ID
    }
  }

  return paymentDataRequest
}
