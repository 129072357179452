import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { formatPhoneNumber } from 'react-phone-number-input/mobile'

import { block } from 'utils/classnames'
import href from 'config/hrefs'

import PanelContent from 'components/Common/Panel/PanelContent'
import DropOffLabel from './DropOffLabel'
import Timeslot from 'components/Common/Delivery/Timeslot'

import { DEFAULT_DROPOFF_DETAILS } from 'utils/delivery'

import './DeliveryRecap.sass'

const b = block.with('basket-delivery-recap')

export const DeliveryRecap = ({
  isLoading,
  address,
  timeslot,
  user,
  dropOff,
  dropOffComment,
  defaultDropOff,
  defaultDropOffComment,
  mealSetting,
  areaId,
  onNext,
  onBack,
  onEditTimeslot,
  onEditDropOff,
  onEditCustomer,
  setDropOff
}) => {
  const [view, setView] = useState(user?.id ? 'delivery' : 'auth')
  const disableSubmit = !user?.phone
  const hasDefaultDropOffDetails = !!defaultDropOff || !!defaultDropOffComment

  useEffect(() => {
    if (view === 'auth' && user?.id) {
      setView('delivery')
    }
  }, [view, user])

  useEffect(() => {
    if (!dropOff) {
      setDropOff(DEFAULT_DROPOFF_DETAILS)
    }
  }, [dropOff])

  return (
    <PanelContent
      onBack={onBack}
      title='Ma livraison'
    >
      <div className={b()} id='delivery'>
        <div className={b('info')}>
          <div className={b('delivery-info')}>
            <button
              className={b('delivery-details', 'delivery-timeslot')}
              onClick={onEditTimeslot}
            >
              <strong>
                <Timeslot
                  start={timeslot?.deliveryStartDate}
                  end={timeslot?.deliveryEndDate}
                  withDate
                />
              </strong>
            </button>
            <div className={b('delivery-address')}>
              {address?.name || address?.address}
            </div>
            {address.isCorner
              ? (
                <div className={b('delivery-location')}>
                  <button
                    className={b('delivery-details')}
                    onClick={onEditDropOff}
                    disabled
                  >
                    <div className={b('delivery-details-container')}>
                      Retrait au corner
                      <br />
                      <span className={b('delivery-comment')}>
                        {hasDefaultDropOffDetails
                          ? defaultDropOffComment || ''
                          : dropOffComment || ''}
                      </span>
                    </div>
                  </button>
                </div>)
              : (
                <div className={b('delivery-location')}>
                  <button
                    className={b('delivery-details')}
                    onClick={onEditDropOff}
                    disabled={hasDefaultDropOffDetails}
                  >
                    <div className={b('delivery-details-container')}>
                      Livraison&nbsp;
                      <DropOffLabel
                        mealSetting={mealSetting}
                        dropOff={defaultDropOff || dropOff}
                        areaId={areaId}
                      />
                      <br />
                      <span className={b('delivery-comment')}>
                        {hasDefaultDropOffDetails
                          ? defaultDropOffComment || ''
                          : dropOffComment || '+ Ajouter des précisions au coursier'}
                      </span>
                    </div>
                  </button>
                  <div className={b('safety')}>
                    <p>Votre coursier vous remettra votre commande en toute sécurité. <a href={href.healthSafetyPage} target='_blank' rel='noopener noreferrer'>En savoir plus.</a></p>
                  </div>
                </div>)}
          </div>
          <div className={b('delivery-info')}>
            <button onClick={onEditCustomer} className={b('delivery-details')}>
              <div className={b('delivery-details-container')}>
                {user?.firstName} {user?.lastName}
                <br />
                {
                  user?.phone ? (
                    formatPhoneNumber(user.phone)
                  ) : (
                    <span className={b('missing-delivery-details')}>Veuillez renseigner un numéro de téléphone</span>
                  )
                }
              </div>
            </button>
          </div>
        </div>
        <div className={b('footer')}>
          <button
            className={b('button-container', { loading: isLoading })}
            onClick={onNext}
            disabled={disableSubmit}
          >
            Passer au paiement
          </button>
        </div>
      </div>
    </PanelContent>
  )
}
DeliveryRecap.propTypes = {
  isLoading: PropTypes.bool,
  address: PropTypes.shape({
    address: PropTypes.string,
    latitude: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number
    ]),
    longitude: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number
    ])
  }),
  timeslot: PropTypes.shape({
    deliveryStartDate: PropTypes.string,
    deliveryEndDate: PropTypes.string
  }),
  user: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    phone: PropTypes.string
  }),
  dropOff: PropTypes.string,
  dropOffComment: PropTypes.string,
  defaultDropOff: PropTypes.string,
  defaultDropOffComment: PropTypes.string,
  mealSetting: PropTypes.string,
  areaId: PropTypes.number,
  onNext: PropTypes.func,
  onBack: PropTypes.func,
  onEditTimeslot: PropTypes.func,
  onEditDropOff: PropTypes.func,
  onEditCustomer: PropTypes.func,
  setDropOff: PropTypes.func
}
export default DeliveryRecap
