import React, { useCallback, useState, useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'

import DayInput from './DayInput'
import DaySelect from './DaySelect'
import DeliveryOverlay from 'components/DeliveryBar/DeliveryOverlay'

import { block } from 'utils/classnames'
import { pushHomepageEvent } from 'utils/googletagmanager'
import { useKeyDown } from 'hooks/dom'

import './DaySelector.sass'

const b = block.with('day-selector')

export const DaySelector = props => {
  const {
    days = [],
    value = '',
    disabled = false,
    updateDeliveryFromDay,
    cartUUID = null
  } = props
  const [isOpen, setIsOpen] = useState(false)

  const selectedDay = useMemo(
    () => days.find(day => day === value) || value || days[0],
    [days, value]
  )

  const handleClose = useCallback(() => setIsOpen(false), [])
  const handleOpen = useCallback(() => setIsOpen(true), [])
  const handleChange = useCallback((selectedDate) => () => {
    if (updateDeliveryFromDay && selectedDate && selectedDate !== value) {
      updateDeliveryFromDay(selectedDate)
    }
    handleClose()
  }, [updateDeliveryFromDay, value, handleClose])

  useEffect(() => {
    selectedDay && pushHomepageEvent('SelectedDate', selectedDay)
  }, [selectedDay])

  useEffect(() => { handleClose() }, [value])

  useKeyDown(isOpen ? handleClose : null, ['Escape'])
  if (days.length === 0 || !cartUUID) {
    return null
  }
  return (
    <div className={b('', { open: isOpen, disabled })}>
      <DeliveryOverlay
        shouldShow={isOpen}
        onClick={handleClose}
        className={b('overlay')}
      />
      <DayInput
        onClick={isOpen ? handleClose : handleOpen}
        value={selectedDay}
        className={b('input')}
        disabled={disabled}
      />
      <DaySelect
        isOpen={isOpen}
        days={days}
        activeDay={selectedDay}
        onClick={handleChange}
        className={b('select')}
      />
    </div>
  )
}
DaySelector.propTypes = {
  days: PropTypes.arrayOf(
    PropTypes.string
  ),
  value: PropTypes.string,
  disabled: PropTypes.bool,
  updateDeliveryFromDay: PropTypes.func
}
export default DaySelector
