import PropTypes from 'prop-types'
import React from 'react'
import Avatar from 'react-avatar'

import { Tags } from 'components/product/tags/Tags'
import { Multiline } from 'components/Common/Multiline'

import { block } from 'utils/classnames'

import './Description.sass'

const b = block.with('product-description')

const defaultChef = { name: 'FoodChéri', id: 27, photo: '27-foodcheri-4.png' }
const URL_CHEF_CLOUDINARY = 'https://d1jlw51le25x83.cloudfront.net/chef'

export const Description = (props) => {
  const {
    description = '',
    chefInfos = null,
    tags = [],
    spiciness = null
  } = props

  if (!description) {
    return null
  }
  const name = (chefInfos && chefInfos.chefName) || defaultChef.name
  const photo = (chefInfos && chefInfos.chefPhoto) || defaultChef.photo
  const id = (chefInfos && chefInfos.idChef) || defaultChef.id

  return (
    <div className={b()}>
      {chefInfos &&
        <div className={b('title')}>
          Cuisiné par <Avatar className={b('avatar')} alt={name} src={`${URL_CHEF_CLOUDINARY}/${id}/${photo}`} round /> {name}
        </div>}
      <div className={b('content')}>
        <Multiline>
          {description}
        </Multiline>
      </div>
      <div className={b('tags')}>
        <Tags
          tags={tags}
          hasPrepTag={false}
          hasTooltip={false}
          spiciness={spiciness}
        />
      </div>
    </div>
  )
}

Description.propTypes = {
  description: PropTypes.string,
  tags: PropTypes.arrayOf(PropTypes.object),
  spiciness: PropTypes.number
}
export default Description
